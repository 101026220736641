import {TillStatus} from 'constants/constants'
const statusOrder = {review: 1, open: 2, closed: 3}

export function reconcilableTills(value: any) {
  return activeTills(value).filter(
    (till: any) => till.status === TillStatus.REVIEW
  )
}

export function activeTills(value: any) {
  return (value || []).filter((till: any) => till.openTime !== null)
}

export function activeTillsSortByStatus(value: any) {
  return activeTills(value || []).sort(
    (transaction_a: any, transaction_b: any) =>
      statusOrder[transaction_a.status as keyof typeof statusOrder] -
      statusOrder[transaction_b.status as keyof typeof statusOrder]
  )
}
