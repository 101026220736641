import {Tabs, Tab} from '@mui/material'
import Grid from '@mui/material/Grid/Grid'
import AtlasDashboard from 'clientDashboard/cashManagement/cashManagerInsights/AtlasDashboard'
import {useState, useMemo} from 'react'

interface DashboardTabsProps {
  sdk: any
  dashboardData: any
}
const DashboardTabs = ({sdk, dashboardData}: DashboardTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue)
  }
  if (!sdk || !dashboardData) return null
  return (
    <>
      {/* Tabs for each dashboard */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='dashboard tabs'
      >
        {dashboardData.map((dashboard: any, index: number) => (
          <Tab
            key={dashboard.dashboardId}
            label={dashboard.dashboardTitle}
            test-id={`tab-${index}`}
          />
        ))}
      </Tabs>

      {/* Display only the selected tab's dashboard */}
      {dashboardData.map((dashboard: any, index: number) => (
        <Grid
          key={dashboard.dashboardId}
          className={selectedTab === index ? 'block' : 'hidden'}
        >
          <Grid item key={index} test-id={`full-atlas-${index}`}>
            <AtlasDashboard data={dashboard} sdk={sdk} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default DashboardTabs
