import {Chip, ChipProps} from '@mui/material'
import React from 'react'

type StyledChipProps = ChipProps

const StyledChip: React.FC<StyledChipProps> = (props) => {
  return (
    <Chip
      {...props}
    />
  )
}

export default StyledChip
