import {Box, Grid} from '@mui/material'
import {
  collapsedWidth,
  drawerWidth
} from 'ui/organisms/navigationDrawer/NavigationDrawer'
import NotFound from 'ui/organisms/NotFound'
import {colors, space} from 'config/theme'
import {Route, Switch} from 'react-router-dom'

import NavigationDrawer from 'ui/organisms/navigationDrawer/NavigationDrawer'
import TopBar from 'clientDashboard/TopBar'
import ClientRoutes from './ClientRoutes'

type ClientInterfaceProps = {
  signOut: () => void
  isValidBusiness: boolean
  isAuthenticated: boolean
  isMobileScreen: boolean
}

function ClientInterface({
  signOut,
  isValidBusiness,
  isAuthenticated,
  isMobileScreen
}: ClientInterfaceProps) {
  const width = isMobileScreen ? collapsedWidth : drawerWidth

  return (
    <Box sx={{display: 'flex', height: '97%'}}>
      <Box sx={{width: `${width}px`, height: '100%'}}>
        {isValidBusiness && isAuthenticated && (
          <NavigationDrawer isMobileScreen={isMobileScreen} />
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
          padding: space.MEDIUM,
          width: `calc(90% - ${width}px)`
        }}
      >
        <Box sx={{height: '100%'}}>
          {isValidBusiness && isAuthenticated && <TopBar signOut={signOut} />}
          <Box
            sx={{
              height: 'auto',
              minHeight: '95%',
              backgroundColor: 'white',
              borderRadius: '12px',
              border: `1px solid ${colors.grayBorder}`
            }}
          >
            <Grid
              style={{
                padding: space.LARGE
              }}
            >
              <Switch>
                <Route path='/:businessId'>
                  <ClientRoutes
                    signOut={signOut}
                    isAuthenticated={isAuthenticated}
                  />
                </Route>
                <Route path={'*'} component={NotFound} />
              </Switch>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ClientInterface
