import {useLazyQuery, useMutation} from '@apollo/client'
import {Grid} from '@mui/material'
import DurationPicker from 'clientDashboard/cashManagement/DurationPicker'
import InsightsLocationPicker from 'clientDashboard/cashManagement/InsightsLocationPicker'
import {GET_INSIGHT} from 'graphql/queries/insights.queries'
import {useEffect, useState} from 'react'
import {
  generateRangeFromDuration,
  getDurationTypeByDurationId
} from 'utils/date.utils'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectCurrentStore} from 'clientDashboard/employee.selectors'
import {GENERATE_ATLAS_AUTH_TOKEN} from 'graphql/mutations/cash-management.mutations'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import DashboardTabs from 'clientDashboard/cashManagement/cashManagerInsights/DashboardTabs'

function InsightsDashboard() {
  const currentStore = useAppSelector(selectCurrentStore)
  const [baseStore, setBaseStore] = useState<any>(currentStore)
  const [duration, setDuration] = useState('today')
  const [dashboardData, setDashboardData] = useState<any>([])
  const [token, setToken] = useState<string | null>(null)
  const [sdk, setSdk] = useState<ChartsEmbedSDK | null>(null)
  const [dashboardBaseUrl, setDashboardBaseUrl] = useState<string | null>(null)

  const [getInsights, {data, loading, error}] = useLazyQuery(GET_INSIGHT, {
    onCompleted: (data) => {
      setDashboardData(data.getInsights)
      if (data.getInsights.length > 0)
        setDashboardBaseUrl(data.getInsights[0].dashboardBaseUrl)
    },
    onError: (error) => {
      console.log('insights error', error)
    }
  })
  const [generateAtlasAuthToken] = useMutation(GENERATE_ATLAS_AUTH_TOKEN, {
    onCompleted: (data) => {
      setToken(data.generateAtlasAuthToken.token)
    },
    onError: (error) => {
      console.log('generateAtlasAuthToken error', error)
    }
  })
  async function login() {
    return token as string
  }

  useEffect(() => {
    if (!token) return
    if (!dashboardBaseUrl) return
    const sdk = new ChartsEmbedSDK({
      baseUrl: dashboardBaseUrl,
      getUserToken: async function () {
        return await login()
      }
    })
    setSdk(sdk)
  }, [token, dashboardBaseUrl])

  useEffect(() => {
    const durationType = getDurationTypeByDurationId(duration)
    const range = generateRangeFromDuration(duration)
    let variables = {
      insightTypes: 'cash-management,sales',
      durationType: durationType,
      storeNumbers: baseStore?.storeNumber,
      locationIds: baseStore?.id,
      duration: range.join(',')
    }
    getInsights({
      variables: variables
    })
    generateAtlasAuthToken()
  }, [baseStore.storeNumber, duration])

  return (
    <Grid
      key='main-grid'
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        gap: '24px',
        flex: '1 0 0',
        backgroundColor: '#F8F8F8'
      }}
    >
      <Grid
        key='filter-grid'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          flex: '1 0 0',
          paddingLeft: '24px',
          paddingTop: '24px'
        }}
      >
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <InsightsLocationPicker
            label={'Location'}
            store={baseStore}
            setStore={setBaseStore}
          />
        </Grid>
        <Grid
          item
          style={{
            width: '200px'
          }}
        >
          <DurationPicker duration={duration} setDuration={setDuration} />
        </Grid>
      </Grid>
      <Grid
        key={'dashboard-grid'}
        item
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px'
        }}
      >
        {sdk && dashboardData && (
          <DashboardTabs sdk={sdk} dashboardData={dashboardData} />
        )}
      </Grid>
    </Grid>
  )
}
export default InsightsDashboard
