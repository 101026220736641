import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import ItemQuantityCounterButton from 'consumerAgent/ItemQuantityCounterButton'
import StyledButton from 'ui/atoms/StyledButton'
import theme, {colors, space} from 'config/theme'
import {useEffect, useMemo, useState} from 'react'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'
import {convertToDollars, formatCurrency} from 'utils/common'

export type ProductDataType = {
  id: string
  sku: string
  description: string
  unitPrice: number
  unitDiscount?: number
  logoUrl: string
  quantity?: number
  skuType?: string | null
  frequency?: string | null
  promotions?: {
    promotionType: string
    promotionValue: number
    promotionName: string
    active: boolean
    businessId: string
    canBeCombined: boolean
    startDate: string
    endDate: string
    priority: number
    subscriptionType: string
    description?: string
  }[]
}

type ConsumerAgentItemCardProps = {
  productData: ProductDataType
}

const subscriptionFrequency = ['1 week', '1 month']

const ConsumerAgentItemCard = ({productData}: ConsumerAgentItemCardProps) => {
  const {cartItems, setCartItems: setCart, customer} = useConsumerAgent()

  const [showingAddedToCart, setShowingAddedToCart] = useState(false)

  useEffect(() => {
    if (showingAddedToCart) {
      const timeout = setTimeout(() => {
        setShowingAddedToCart(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [showingAddedToCart])

  const updateCart = (quantity: number, isSubscription = false) => {
    const newCart = cartItems?.length ? [...cartItems] : []
    const productIndex = newCart.findIndex(
      (item: any) => item.sku === productData.sku
    )

    if (productIndex !== -1) {
      newCart[productIndex].quantity = quantity
      if (quantity <= 0) newCart.splice(productIndex, 1)
    } else if (quantity > 0) {
      newCart.push({
        ...productData,
        quantity,
        skuType: isSubscription ? 'subscription' : null,
        frequency: isSubscription ? subscriptionFrequency[0] : null
      })
    }
    setCart(newCart)
  }

  const handleAddToCart = (isSubscription: boolean = false) => {
    updateCart(
      (cartItems?.find((item: any) => item.sku === productData.sku)?.quantity ??
        0) + 1,
      isSubscription
    )
    setShowingAddedToCart(true)
  }

  const isItemInCart = useMemo(() => {
    return cartItems?.some(
      (item: any) => item.sku === productData.sku && item.quantity > 0
    )
  }, [cartItems, productData.sku])

  const isSubscription = useMemo(() => {
    return cartItems?.some(
      (item: any) =>
        item.sku === productData.sku && item.skuType === 'subscription'
    )
  }, [cartItems, productData.sku])

  return (
    <Card
      style={{
        maxWidth: '100%',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        display: 'block',
        padding: space.XS,
        border: '1px solid',
        borderColor: colors.grayBorder,
        borderRadius: '16px',
        boxShadow: 'none',
        overflow: 'visible'
      }}
    >
      <CardHeader
        style={{alignItems: 'flex-start'}}
        avatar={
          <img
            src={productData.logoUrl}
            onError={(e) => {
              e.currentTarget.src =
                'https://static.thenounproject.com/png/4974686-200.png'
            }}
            alt='Product'
            style={{maxWidth: '80px', maxHeight: '80px'}}
          />
        }
        title={
          <Typography variant='body2' fontSize='16px'>
            {productData.description}
          </Typography>
        }
        subheader={
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: space.SMALL
            }}
          >
            <Grid
              item
              gap={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
              minHeight='48px'
            >
              <Grid item>
                <Chip
                  label='Free shipping'
                  size='small'
                  style={{
                    backgroundColor: colors.primary[10],
                    color: 'black',
                    fontSize: '12px'
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontSize='20px'
                >
                  {formatCurrency(
                    convertToDollars(
                      productData.unitPrice - (productData.unitDiscount ?? 0)
                    )
                  )}
                </Typography>
                {(productData.unitDiscount ?? 0) > 0 && (
                  <Typography variant='body1' color='text.secondary'>
                    <del>
                      {formatCurrency(convertToDollars(productData.unitPrice))}
                    </del>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      />

      <CardContent
        style={{
          marginTop: `-${space.MEDIUM}`,
          marginBottom: `-${space.MEDIUM}`
        }}
      >
        {isItemInCart ? (
          showingAddedToCart ? (
            <Grid
              item
              style={{
                backgroundColor: colors.primary[10],
                color: colors.primary[50],
                padding: '12px',
                borderRadius: '8px',
                textAlign: 'center',
                fontSize: '16px'
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: 'circle-check',
                  family: 'classic',
                  style: 'solid'
                })}
                style={{
                  fontSize: '16px',
                  marginRight: space.XS,
                  color: colors.primary[40]
                }}
              />
              Added to Cart
            </Grid>
          ) : (
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
              gap={4}
            >
              <Grid item xs={8}>
                {isSubscription && (
                  <FormControl fullWidth>
                    <InputLabel id='subscription-frequency'>
                      Frequency
                    </InputLabel>

                    <Select
                      size='medium'
                      label={'Frequency'}
                      labelId='subscription-frequency'
                      value={
                        cartItems?.find(
                          (item: any) => item.sku === productData.sku
                        )?.frequency ?? subscriptionFrequency[0]
                      }
                      onChange={(e: any) => {
                        // update the frequency field for the item in the cartItems
                        const newCart = cartItems?.map((item: any) => {
                          if (item.sku === productData.sku) {
                            return {
                              ...item,
                              frequency: e.target.value
                            }
                          }
                          return item
                        })
                        setCart(newCart)
                      }}
                    >
                      {subscriptionFrequency.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {frequency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={4}>
                <ItemQuantityCounterButton
                  quantity={
                    cartItems?.find((item: any) => item.sku === productData.sku)
                      ?.quantity ?? 0
                  }
                  changeQuantity={(quantity: number) => {
                    updateCart(quantity, isSubscription)
                  }}
                />
              </Grid>
            </Grid>
          )
        ) : (
          <Grid
            gap={2}
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <StyledButton
              onClick={() => {
                handleAddToCart()
              }}
              style={{
                backgroundColor: theme.palette.primary.dark,
                width: '100%'
              }}
            >
              Add to Cart
            </StyledButton>

            {productData.skuType === 'subscription' && !!customer && (
              <StyledButton
                onClick={() => {
                  // subscription is true
                  handleAddToCart(true)
                }}
                style={{
                  width: '100%'
                }}
              >
                Subscribe
              </StyledButton>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default ConsumerAgentItemCard
