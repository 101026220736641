export enum Permission {
  // location
  READ_LOCATION = 'READ_LOCATION',
  CREATE_LOCATION = 'CREATE_LOCATION',
  FULL_ACCESS_LOCATION = 'FULL_ACCESS_LOCATION',

  // roles
  ASSIGN_ROLE = 'ASSIGN_ROLE',

  // cash management
  READ_CASH_MANAGEMENT_SUMMARY = 'READ_CASH_MANAGEMENT_SUMMARY',
  CREATE_BANK_DEPOSIT = 'CREATE_BANK_DEPOSIT',
  READ_BANK_DEPOSIT = 'READ_BANK_DEPOSIT',
  CREATE_CASH_TRANSACTION = 'CREATE_CASH_TRANSACTION',
  CREATE_SAFE_CASH_TRANSACTION = 'CREATE_SAFE_CASH_TRANSACTION',
  READ_CASH_MANAGEMENT_TRANSACTION = 'READ_CASH_MANAGEMENT_TRANSACTION',
  READ_TILL_TOTAL_CASH_COLUMN = 'READ_TILL_TOTAL_CASH_COLUMN',
  ALLOW_SENDING_TILL_REFUND_SALE_EVENT = 'ALLOW_SENDING_TILL_REFUND_SALE_EVENT',

  // peripheral hub
  MANAGE_ZONES = 'MANAGE_ZONES',
  MANAGE_PH_NOTIFICATION_CONFIG = 'MANAGE_PH_NOTIFICATION_CONFIG',
  READ_PERIPHERAL_HUB_INSTALLATION = 'READ_PERIPHERAL_HUB_INSTALLATION',
  MANAGE_PERIPHERAL_HUB_VERSIONS = 'MANAGE_PERIPHERAL_HUB_VERSIONS',

  // orders
  READ_ALL_ORDERS = 'READ_ALL_ORDERS',
  READ_SELF_ORDERS = 'READ_SELF_ORDERS',

  // customers
  CREATE_AND_UPDATE_CUSTOMER = 'CREATE_AND_UPDATE_CUSTOMER',

  // dashboard
  MANAGE_PERIPHERAL_HUB = 'MANAGE_PERIPHERAL_HUB',
  MANAGE_LOCATION_SETTINGS = 'MANAGE_LOCATION_SETTINGS',
  MANAGE_CASH_MANAGEMENT = 'MANAGE_CASH_MANAGEMENT',
  MANAGE_RULES = 'MANAGE_RULES',
  MANAGE_PROMOTIONS = 'MANAGE_PROMOTIONS',
  MANAGE_ORDERS = 'MANAGE_ORDERS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_EMPLOYEE = 'MANAGE_EMPLOYEE',
  MANAGE_OAUTH_APPLICATIONS = 'MANAGE_OAUTH_APPLICATIONS',
  MANAGE_INVENTORY = 'MANAGE_INVENTORY',
  MANAGE_CUSTOMERS = 'MANAGE_CUSTOMERS',
  MANAGE_PAYMENTS_CONFIGURATIONS = 'MANAGE_PAYMENTS_CONFIGURATIONS',
  MANAGE_BUSINESS_SETTINGS = 'MANAGE_BUSINESS_SETTINGS',
  INVENTORY_DELETE = 'INVENTORY_DELETE',
  MANAGE_OMS_CONFIGURATIONS = 'MANAGE_OMS_CONFIGURATIONS',
  MANAGE_INSIGHTS = 'MANAGE_INSIGHTS',
  MANAGE_CASH_MANAGEMENT_INSIGHTS = 'MANAGE_CASH_MANAGEMENT_INSIGHTS',

  READ_LOCATION_GROUP = 'READ_LOCATION_GROUP',
  CREATE_LOCATION_GROUP = 'CREATE_LOCATION_GROUP',
  UPDATE_LOCATION_GROUP = 'UPDATE_LOCATION_GROUP'
}
