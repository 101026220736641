import {MenuItem, MenuItemProps} from '@mui/material'
import {ReactNode} from 'react'

export interface StyledMenuItemProps extends MenuItemProps {
  children: ReactNode
}

export function StyledMenuItem({children, ...other}: StyledMenuItemProps) {
  return (
    <MenuItem
      {...other}
    >
      {children}
    </MenuItem>
  )
}
