import {Button, ButtonProps} from '@mui/material'
import {ForwardRefRenderFunction} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import {colors} from 'config/theme'

type StyledButton = ButtonProps & {showLoader?: boolean}

const StyledButton: ForwardRefRenderFunction<
  HTMLInputElement,
  StyledButton
> = ({children, showLoader = false, ...btnProps}: StyledButton) => {
  return (
    <Button {...btnProps}>
      {showLoader ? (
        <div className={'flex justify-center items-center'}>
          <CircularProgress
            size={20}
            style={{color: colors.grayFont}}
            className={'mr-2'}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </Button>
  )
}
export default StyledButton
