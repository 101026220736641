import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import common_en from 'config/locales/en/commons.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      commons: common_en
    }
  },
  fallbackLng: 'en',
  lng: 'en',
  ns: ['commons'],
  defaultNS: 'commons',
  debug: false
})

export default i18n
