import {useLazyQuery, useSubscription} from '@apollo/client'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import StyledTable from 'ui/molecules/StyledTable'
import theme from 'config/theme'
import {OrderType} from 'graphql/generatedTypes/graphql'
import {FETCH_ORDERS} from 'graphql/queries/order.queries'
import _ from 'lodash'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import React from 'react'
import Barcode from 'react-barcode'
import {
  centsToDollar,
  convertToLocalTimeString,
  formatCurrency
} from 'utils/common'

import {TRANSACTION_EVENT_SUBSCRIPTION} from 'graphql/subscriptions/transaction.subscription'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {
  selectAppliedLocationGroups,
  selectBusinessId,
  selectSelectedLocationGroupIds,
  selectSelectedLocationId
} from 'clientDashboard/employee.selectors'
import {
  selectFeatureFlags,
  selectStoreGroupClusters
} from 'clientDashboard/business.selectors'
import {useTranslation} from 'react-i18next'

interface TransactionsProps {
  customerId?: string
}

function Transactions({customerId}: TransactionsProps) {
  const [data, setData] = useState<OrderType[]>([])
  const businessId = useAppSelector(selectBusinessId)
  const locationId = useAppSelector(selectSelectedLocationId)
  const selectedLocationGroupIds = useAppSelector(
    selectSelectedLocationGroupIds
  )
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const appliedLocationGroups = useAppSelector(selectAppliedLocationGroups)
  const {t} = useTranslation()
  const storeGroupClustersNames = storeGroupClusters.map(
    (storeGroupCluster) => storeGroupCluster.name
  )
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })

  const [getOrders, {data: _ordersData, loading: loadingOrders}] = useLazyQuery(
    FETCH_ORDERS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const featureFlags = useAppSelector(selectFeatureFlags)

  const fetchAllOrders = () => {
    getOrders({
      variables: {
        businessId: businessId as string,
        locationId: locationId as string,
        customerId: customerId as string,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...selectedLocationGroupIds
      }
    })
  }

  useEffect(() => {
    fetchAllOrders()
  }, [pagination, appliedLocationGroups])

  useEffect(() => {
    if (_ordersData) {
      setData(_ordersData.listOrders.orders as OrderType[])
    }
  }, [_ordersData, pagination])

  const {data: _subscriptionData} = useSubscription(
    TRANSACTION_EVENT_SUBSCRIPTION,
    {
      shouldResubscribe: true
    }
  )

  // It might happen that the latest order which we got through subscription
  // is not in the selected location group. In that case, we should not show it.
  const isNewOrderInSelectedLocationGroup = (order: OrderType) => {
    let orderLocationId = order?.location?.id
    // if feature flag is off, then we don't need to check for location grouping
    if (featureFlags.locationGrouping === false) {
      return orderLocationId === locationId
    }
    let orderStoreGroups = order.location?.storeGroups || []
    let {locationIds, storeGroupIds} = selectedLocationGroupIds
    if (locationIds && locationIds.length > 0 && orderLocationId) {
      if (!locationIds.includes(orderLocationId)) {
        return false
      }
    }
    if (
      storeGroupIds &&
      storeGroupIds.length > 0 &&
      orderStoreGroups.length > 0
    ) {
      let orderStoreGroupIds = orderStoreGroups.map((sg) => sg.id)
      let intersection = orderStoreGroupIds.filter((sgId) =>
        storeGroupIds?.includes(sgId)
      )
      if (intersection.length === 0) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (_subscriptionData) {
      let newOrder = _subscriptionData.orderUpdateEventsDashboard
        ?.order as OrderType

      if (isNewOrderInSelectedLocationGroup(newOrder)) {
        // check if the new order is already in the list
        let orderIndex = data.findIndex((order) => order.id === newOrder.id)
        if (orderIndex !== -1) {
          // if it is already in the list, then update it
          setData((prevData) => {
            let newData = [...prevData]
            newData[orderIndex] = newOrder
            return newData
          })
        } else {
          // if it is not in the list, then add it to the top of the list
          setData((prevData) => {
            const newData = [newOrder, ...prevData]
            if (newData.length > pagination.pageSize) {
              newData.pop()
            }
            return newData
          })
        }
      }
    }
  }, [_subscriptionData])

  const Receipt = (items: any, row: any) => {
    let subtotal = 0
    let discount = 0
    let tax = 0
    items.forEach((item: any) => {
      subtotal += item.quantity * item.unitPrice
      discount += item.totalDiscount
      tax += item.taxAmount
    })

    return (
      <>
        <Paper elevation={3}>
          <div
            style={{
              marginBottom: '20px',
              backgroundColor: theme.palette.primary.light,
              padding: '10px'
            }}
          >
            <Typography variant='h6' align='center' gutterBottom>
              {row.location.name}
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              {row.location.address.street}, {row.location.address.city},{' '}
            </Typography>
          </div>
          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(items || []).map((item: any, index: number) => (
                  <TableRow key={item.sku}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      {formatCurrency(
                        centsToDollar(item.unitPrice),
                        row.currency
                      )}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(
                        centsToDollar(item.totalDiscount || 0),
                        row.currency
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      {formatCurrency(centsToDollar(item.total), row.currency)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Subtotal</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(subtotal), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Discount</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(discount), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Tax</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(centsToDollar(tax), row.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell align='right'>
                    {formatCurrency(
                      centsToDollar(row.totalAmount),
                      row.currency
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Footer */}
          <Typography variant='h6' align='center' gutterBottom>
            <Barcode
              value={row.orderNumber}
              width={1}
              height={50}
              textPosition={'center'}
            />
          </Typography>
        </Paper>
      </>
    )
  }

  const dynamicStoreGroupColumns = useMemo<MRT_ColumnDef<OrderType>[]>(() => {
    if (!storeGroupClusters || storeGroupClusters.length === 0) return []
    return storeGroupClusters.map((storeGroupCluster) => {
      // Define your dynamic columns based on storeGroupCluster
      return {
        accessorFn: (row) => {
          const storeGroup = row.location?.storeGroups?.find(
            (storeGroup) =>
              storeGroup.storeGroupCluster.name === storeGroupCluster.name
          )
          return storeGroup ? storeGroup.name : null
        },
        header: storeGroupCluster.name, // Adjust based on your actual data structure
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>{cell.getValue() || ''}</Typography>
          )
        }
      }
    })
  }, [storeGroupClusters])

  const columns = useMemo<MRT_ColumnDef<OrderType>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: t('growthSaleTransactions.tableColumns.Date'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'orderNumber',
        header: t('growthSaleTransactions.tableColumns.TransactionId'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'employee.name',
        header: t('growthSaleTransactions.tableColumns.Employee'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },

      {
        accessorKey: 'register',
        header: t('growthSaleTransactions.tableColumns.RegisterId'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'totalAmount',
        header: t('growthSaleTransactions.tableColumns.TransactionAmount'),
        Cell: ({cell, row}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(
                centsToDollar(cell.getValue()),
                row.original.currency
              )}
            </Typography>
          )
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      },
      {
        accessorKey: 'paymentStatus',
        header: t('growthSaleTransactions.tableColumns.PaymentStatus'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {_.capitalize(cell.getValue())}
            </Typography>
          )
        },

        enableSorting: true
      },
      {
        accessorKey: 'orderType',
        header: t('growthSaleTransactions.tableColumns.OrderCategory'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {_.toUpper(cell.getValue())}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'fulfillmentStatus',
        header: t('growthSaleTransactions.tableColumns.FulfillmentStatus'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {_.toUpper(cell.getValue())}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'customer',
        header: t('growthSaleTransactions.tableColumns.Customer'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue()
                ? cell.getValue()?.firstName + ' ' + cell.getValue()?.lastName
                : '-'}
            </Typography>
          )
        }
      },
      ...(featureFlags.locationGrouping ? dynamicStoreGroupColumns : [])
    ],
    [dynamicStoreGroupColumns]
  )

  return (
    <StyledTable
      columns={columns}
      data={data}
      manualPagination={true}
      onPaginationChange={setPagination}
      state={{
        pagination,
        showSkeletons: loadingOrders,
        showProgressBars: loadingOrders
      }}
      rowCount={_ordersData?.listOrders.totalPageCount || 0}
      renderDetailPanel={({row}) => {
        if (loadingOrders) return ''
        return (
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                alignContent: 'stretch',
                gap: 50
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {Receipt(row.original.items, row.original)}
                </Grid>
                <Grid item xs={4}>
                  {row.original.subscriptionItems &&
                    row.original.subscriptionItems.length > 0 &&
                    Receipt(row.original.subscriptionItems, row.original)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }}
      initialState={{
        columnPinning: {
          left: ['mrt-row-expand']
        },
        columnVisibility: {
          'employee.name': false,
          register: false,
          // hide the dynamic columns by default
          ...Object.fromEntries(
            storeGroupClustersNames.map((name) => [name, false])
          )
        }
      }}
    />
  )
}

export default Transactions
