import 'react-notifications-component/dist/theme.css'
import React from 'react'
import {Provider as ReduxStoreProvider} from 'react-redux'
import {store} from 'clientDashboard/clientDashboard.store'

import {AuthMethod, LocalStorageKeys} from 'constants/constants'
import Login from 'clientDashboard/Login'
import PasswordSettings from 'clientDashboard/settings/PasswordSettings'
import useLocalStorage from 'hooks/useLocalStorage'
import {Route, Switch} from 'react-router-dom'
import {isNonProdAppEnv} from 'utils/common.utils'

import ClientOktaRouteHandler from 'clientDashboard/ClientOktaRouteHandler'
import ClientToteRouteHandler from 'clientDashboard/ClientToteRouteHandler'

import NotFound from 'ui/organisms/NotFound'
import ExternalRedirect from 'ui/molecules/ExternalRedirect'

const PlaygroundRouter = React.lazy(() => import('ui/playground/PlaygroundRouter'))

function ClientRouteHandler() {
  const [authMethod] = useLocalStorage(
    LocalStorageKeys.AUTH_METHOD,
    AuthMethod.TOTE
  )

  const isOktaAuth = authMethod === AuthMethod.OKTA

  return (
    <ReduxStoreProvider store={store}>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/redirect' component={ExternalRedirect} />
        <Route exact path='/set-password' component={PasswordSettings} />
        {
          isNonProdAppEnv() && <Route path='/code-playground' component={PlaygroundRouter} />
        }
        <Route>
          {isOktaAuth ? <ClientOktaRouteHandler /> : <ClientToteRouteHandler />}
        </Route>
        <Route path={'*'} component={NotFound} />
      </Switch>
    </ReduxStoreProvider>
  )
}

export default ClientRouteHandler
