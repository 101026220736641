import {graphql} from 'graphql/generatedTypes'

export const ADD_CUSTOMER = graphql(`
  mutation addCustomer($customer: CustomerInput!) {
    addCustomer(customer: $customer)
  }
`)

export const UPDATE_CUSTOMER = graphql(`
  mutation updateCustomer($customerId: String!, $customer: CustomerInput!) {
    updateCustomer(customer: $customer, customerId: $customerId)
  }
`)

export const VALIDATE_CUSTOMER_SIGNUP_TOKEN = graphql(`
  mutation validateCustomerSignupToken($token: String!, $checkUsage: Boolean) {
    validateCustomerSignupToken(token: $token, checkUsage: $checkUsage) {
      flow

      cart {
        customer {
          id
          firstName
          lastName
          email

          addresses {
            street
            city
            state
            zipCode
            country
          }
        }
        deliveryAddress {
          street
          city
          state
          zipCode
          country
        }
        subscriptionItems {
          frequency
          status
          description
          sku
          total
          quantity
        }
        id
        location {
          id
          name
          business {
            id
            name
          }
        }
      }
    }
  }
`)

export const SIGNUP_CUSTOMER = graphql(`
  mutation signupCustomer($customer: CustomerInput!, $token: String!) {
    signupCustomer(customer: $customer, token: $token) {
      customer {
        id
        email
        firstName
        lastName
      }
      token
    }
  }
`)

export const SIGNIN_CUSTOMER = graphql(`
  mutation customerSignin(
    $email: String!
    $password: String!
    $token: String!
  ) {
    customerSignin(email: $email, password: $password, token: $token) {
      customer {
        id
        email
        firstName
        lastName
      }
      token
    }
  }
`)

export const APPROVE_ITEM_SUBSCRIPTION = graphql(`
  mutation approveItemSubscription(
    $cartId: String!
    $sku: String!
    $token: String!
  ) {
    approveItemSubscription(cartId: $cartId, sku: $sku, token: $token)
  }
`)

export const GENERATE_GENIE_CHAT_SESSION_ID_FOR_CONSUMER_AGENT = graphql(`
  mutation generateGenieChatSessionIdConsumerAgent {
    generateGenieChatSessionIdConsumerAgent
  }
`)

export const DELETE_CHAT_HISTORY_FOR_CONSUMER_AGENT = graphql(`
  mutation deleteChatHistoryForConsumerAgent($chatSessionId: String!) {
    deleteChatHistoryForConsumerAgent(chatSessionId: $chatSessionId)
  }
`)

export const ADD_CUSTOMER_ADDRESS = graphql(`
  mutation addCustomerAddress(
    $customerId: String!
    $token: String!
    $address: CustomerAddressInput!
  ) {
    addCustomerAddress(
      customerId: $customerId
      token: $token
      address: $address
    ) {
      addresses {
        street
        city
        state
        zipCode
        country
      }
      id
    }
  }
`)

export const UPDATE_CART_ADDRESS = graphql(`
  mutation updateCartAddress(
    $cartId: String!
    $token: String!
    $address: AddressInput!
  ) {
    updateCartAddress(cartId: $cartId, token: $token, address: $address) {
      id
    }
  }
`)
