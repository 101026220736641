import {useLazyQuery, useMutation} from '@apollo/client'
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import PermissionWrapper from 'clientDashboard/settings/PermissionWrapper'
import theme, {space} from 'config/theme'
import {Permission} from 'constants/permissions'
import {
  ASSIGN_CURRENT_VERSION,
  DEVICE_HUB_VERSION_METADATA
} from 'graphql/queries/peripheral-hub.queries'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import StyledButton from 'ui/atoms/StyledButton'

import env from 'constants/env'

const PeripheralHubInstallation = () => {
  const {t} = useTranslation()
  const {businessId, locationId, locationName} = useAppSelector(
    selectBusinessAndLocation
  )

  const [currentVersion, setCurrentVersion] = useState<string | null>(null)
  const [hash, setHash] = useState<string | null>(null)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [releaseNotes, setReleaseNotes] = useState<
    Array<{version: string; text: string}>
  >([])
  const [availableVersions, setAvailableVersions] = useState<Array<string>>([])
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null)
  const [showGenerateButton, setShowGenerateButton] = useState<boolean>(true)

  const [
    getDeviceHubVersionMetadata,
    {loading: loadingVersionMetadata, error: errorVersionMetadata}
  ] = useLazyQuery(DEVICE_HUB_VERSION_METADATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const metadata = data.deviceHubVersionMetadata
      setCurrentVersion(metadata.currentVersion || 'N/A')

      setReleaseNotes(
        (metadata.readme as Array<{version: string; text: string}>) || []
      )

      setAvailableVersions(
        (metadata.versions || []).filter(
          (v: string) => v !== metadata.currentVersion
        )
      )

      setShowGenerateButton(!!metadata.currentVersion)

      if (metadata.hash && metadata.url) {
        setHash(metadata.hash)
        setDownloadUrl(metadata.url)
        setShowGenerateButton(false)
      } else {
        setHash(null)
        setDownloadUrl(null)
        setShowGenerateButton(!!metadata.currentVersion)
      }
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const [
    assignCurrentVersion,
    {loading: loadingAssignCurrentVersion, error: errorAssignCurrentVersion}
  ] = useMutation(ASSIGN_CURRENT_VERSION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      fetchDeviceHubVersionMetadata()
      setSelectedVersion(null)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  useEffect(() => {
    fetchDeviceHubVersionMetadata(false)
  }, [])

  const fetchDeviceHubVersionMetadata = (generateUrl: boolean = false) => {
    getDeviceHubVersionMetadata({
      variables: {
        businessId: businessId,
        locationId: locationId,
        generateUrl: generateUrl
      }
    })
  }

  const handleGenerateDownloadUrl = () => {
    fetchDeviceHubVersionMetadata(true)
  }

  const handleAssignVersion = () => {
    if (selectedVersion) {
      assignCurrentVersion({
        variables: {
          businessId: businessId,
          locationId: locationId,
          version: selectedVersion,
          current: true
        }
      })
    }
  }

  return (
    <Box
      sx={{
        padding: space.LARGE,
        borderRadius: '12px',
        backgroundColor: theme.palette.background.paper
      }}
    >
      {/* Downloader Section */}
      <Typography
        variant='h2'
        sx={{marginBottom: space.MEDIUM, fontWeight: 'bold', color: '#555'}}
      >
        Installation
      </Typography>
      <PermissionWrapper
        permissions={[Permission.READ_PERIPHERAL_HUB_INSTALLATION]}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{marginBottom: space.MEDIUM}}>
              <Typography variant='h3' color='text.secondary'>
                Current Version
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                {currentVersion || 'No version available'}
              </Typography>
            </Box>

            <Box sx={{marginBottom: space.MEDIUM}}>
              <Typography variant='h3' color='text.secondary'>
                Location Name
              </Typography>
              <Typography variant='subtitle1' color='text.secondary'>
                {locationName || 'N/A'}
              </Typography>
            </Box>

            {hash && (
              <Box sx={{marginBottom: space.MEDIUM}}>
                <Typography variant='h3' color='text.secondary'>
                  Hash
                </Typography>
                <Typography variant='subtitle1' color='text.secondary'>
                  {hash}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Ensure the hash value matches to verify the integrity.
                </Typography>
              </Box>
            )}

            {showGenerateButton && (
              <StyledButton
                variant='contained'
                size='large'
                onClick={handleGenerateDownloadUrl}
                disabled={loadingVersionMetadata || !currentVersion}
              >
                Generate Download URL
              </StyledButton>
            )}

            {!showGenerateButton && downloadUrl && (
              <StyledButton
                variant='contained'
                size='large'
                onClick={() => window.open(downloadUrl, '_blank')}
              >
                Download Installer
              </StyledButton>
            )}

            {errorVersionMetadata && (
              <Alert severity='error' sx={{marginTop: space.MEDIUM}}>
                {t('An error occurred while fetching version metadata.')}
              </Alert>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant='h3' gutterBottom color='text.secondary'>
                Release Notes
              </Typography>
              {releaseNotes.length > 0 ? (
                releaseNotes
                  .filter((note) => note.version === currentVersion)
                  .map((note) => (
                    <Box key={note.version} sx={{marginBottom: '12px'}}>
                      <Typography variant='h4'>
                        Version {note.version}
                      </Typography>
                      <Typography variant='subtitle1' color='text.secondary'>
                        {note.text || 'No details available'}
                      </Typography>
                    </Box>
                  ))
              ) : (
                <Typography variant='body2' color='text.secondary'>
                  No release notes available.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </PermissionWrapper>

      {/* Version Assignment Section */}
      {availableVersions.length > 0 && (
        <>
          <Divider sx={{marginY: space.LARGE}} />
          <Typography
            variant='h2'
            sx={{
              marginBottom: space.MEDIUM,
              fontWeight: 'bold',
              color: '#555'
            }}
          >
            Version Assignment
          </Typography>

          <PermissionWrapper
            permissions={[Permission.MANAGE_PERIPHERAL_HUB_VERSIONS]}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant='outlined'
                  sx={{marginBottom: space.MEDIUM}}
                >
                  <InputLabel id='version-select-label'>
                    Select Version
                  </InputLabel>
                  <Select
                    labelId='version-select-label'
                    id='version-select'
                    value={selectedVersion || ''}
                    onChange={(e) => setSelectedVersion(e.target.value)}
                    label='Select Version'
                  >
                    {availableVersions.map((version) => (
                      <MenuItem key={version} value={version}>
                        {version}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <StyledButton
                  variant='contained'
                  size='large'
                  onClick={handleAssignVersion}
                  disabled={loadingAssignCurrentVersion || !selectedVersion}
                >
                  Assign Version
                </StyledButton>

                {errorAssignCurrentVersion && (
                  <Alert severity='error' sx={{marginTop: space.MEDIUM}}>
                    {t('An error occurred while assigning the version.')}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </PermissionWrapper>
        </>
      )}

      <Divider sx={{marginY: space.LARGE}} />

      <Box sx={{marginTop: '24px'}}>
        <Typography
          variant='h2'
          sx={{
            marginBottom: space.MEDIUM,
            fontWeight: 'bold',
            color: '#555'
          }}
        >
          Instructions
        </Typography>
        <Typography variant='subtitle1' gutterBottom color='text.secondary'>
          URL: {env.REACT_APP_EVENT_HOOK_URL}
        </Typography>
        <Typography variant='subtitle1' gutterBottom color='text.secondary'>
          For detailed installation instructions,{' '}
          <a
            href={env.REACT_APP_DEVICE_HUB_WIKI_URL}
            target='_blank'
            rel='noreferrer'
            style={{textDecoration: 'none'}}
          >
            click here.
          </a>
        </Typography>
      </Box>
    </Box>
  )
}

export default PeripheralHubInstallation
