/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /** Date (isoformat) */
  Date: {input: any; output: any}
  /** Date with time (isoformat) */
  DateTime: {input: any; output: any}
  /** Decimal (fixed-point) */
  Decimal: {input: any; output: any}
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: {input: any; output: any}
  Upload: {input: any; output: any}
  /** Represents NULL values */
  Void: {input: any; output: any}
}

export type ActivityLogType = {
  __typename?: 'ActivityLogType'
  activity?: Maybe<Array<CustomerActivityLogType>>
  eventType?: Maybe<Scalars['String']['output']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  lat?: InputMaybe<Scalars['String']['input']>
  long?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type AddressType = {
  __typename?: 'AddressType'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  lat?: Maybe<Scalars['String']['output']>
  long?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type AdminUserType = {
  __typename?: 'AdminUserType'
  email: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type AgeAttributesInput = {
  customerAgeIdentifier?: InputMaybe<Scalars['String']['input']>
  customerDob?: InputMaybe<Scalars['String']['input']>
}

export type AgeAttributesType = {
  __typename?: 'AgeAttributesType'
  customerAgeIdentifier?: Maybe<Scalars['String']['output']>
  customerDob?: Maybe<Scalars['String']['output']>
}

export type ApiResponse = {
  __typename?: 'ApiResponse'
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type AssignSegmentType = {
  __typename?: 'AssignSegmentType'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type AtlasAuthType = {
  __typename?: 'AtlasAuthType'
  success: Scalars['Boolean']['output']
  token?: Maybe<Scalars['String']['output']>
}

export type AuthMethodType = {
  __typename?: 'AuthMethodType'
  authMethod: Scalars['String']['output']
  businessId: Scalars['String']['output']
  businessName: Scalars['String']['output']
  okta?: Maybe<OktaConfigType>
}

export type AverageTransactionValueType = {
  __typename?: 'AverageTransactionValueType'
  averageTransactionValue?: Maybe<Scalars['Float']['output']>
  percentageChange?: Maybe<Scalars['String']['output']>
}

export type AverageVariancePercentageType = {
  __typename?: 'AverageVariancePercentageType'
  averageVariancePercentage?: Maybe<Scalars['Float']['output']>
  percentageChange?: Maybe<Scalars['String']['output']>
}

export type BankDepositType = {
  __typename?: 'BankDepositType'
  amount: Scalars['Int']['output']
  createdAt?: Maybe<Scalars['String']['output']>
  depositNumber?: Maybe<Scalars['String']['output']>
  depositReceipt?: Maybe<Scalars['String']['output']>
  employee?: Maybe<EmployeeType>
  id: Scalars['ID']['output']
  location?: Maybe<LocationType>
  status?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type BopisOrderEventType = {
  __typename?: 'BopisOrderEventType'
  eventType: Scalars['String']['output']
  order?: Maybe<OrderType>
}

export type BusinessColorsType = {
  __typename?: 'BusinessColorsType'
  primary?: Maybe<Scalars['String']['output']>
  secondary?: Maybe<Scalars['String']['output']>
}

export type BusinessConfigsType = {
  __typename?: 'BusinessConfigsType'
  locationAutosuggestApiKey?: Maybe<Scalars['String']['output']>
  stripePublishableKey?: Maybe<Scalars['String']['output']>
  stripeTerminalLocationId?: Maybe<Scalars['String']['output']>
}

export type BusinessConfigurationsType = {
  __typename?: 'BusinessConfigurationsType'
  colors?: Maybe<BusinessColorsType>
  deliveryTimeSlots?: Maybe<Array<DeliveryTimeSlotType>>
  emailReceiptEnabled?: Maybe<Scalars['Boolean']['output']>
  templates?: Maybe<TemplatesType>
}

export type BusinessDetailsInput = {
  address: AddressInput
  authMethod: Scalars['String']['input']
  brand?: InputMaybe<Scalars['String']['input']>
  contactDetails?: InputMaybe<ContactDetailsInput>
  employeeDetails?: InputMaybe<EmployeeInput>
  featureFlags?: InputMaybe<Scalars['JSON']['input']>
  locationConfiguration?: InputMaybe<BusinessLocationConfigurationInput>
  logo?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  okta?: InputMaybe<OktaInput>
  storeGroupClusters?: InputMaybe<Array<StoreGroupClusterInput>>
}

export type BusinessDetailsType = {
  __typename?: 'BusinessDetailsType'
  address?: Maybe<AddressType>
  authMethod: Scalars['String']['output']
  brand?: Maybe<Scalars['String']['output']>
  contactDetails?: Maybe<ContactDetailsType>
  employeeDetails?: Maybe<EmployeeType>
  featureFlags?: Maybe<Scalars['JSON']['output']>
  id: Scalars['String']['output']
  locationConfiguration?: Maybe<BusinessLocationConfigurationType>
  logo?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  okta?: Maybe<OktaType>
  storeGroupClusters?: Maybe<Array<StoreGroupClusterType>>
}

export type BusinessDetailsUpdateInput = {
  address?: InputMaybe<AddressInput>
  authMethod?: InputMaybe<Scalars['String']['input']>
  brand?: InputMaybe<Scalars['String']['input']>
  contactDetails?: InputMaybe<ContactDetailsInput>
  featureFlags?: InputMaybe<Scalars['JSON']['input']>
  logo?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  okta?: InputMaybe<OktaInput>
  storeGroupClusters?: InputMaybe<Array<StoreGroupClusterInput>>
}

export type BusinessLocationConfigurationInput = {
  location: Scalars['String']['input']
  storeNumber: Scalars['String']['input']
}

export type BusinessLocationConfigurationType = {
  __typename?: 'BusinessLocationConfigurationType'
  location: Scalars['String']['output']
  storeNumber: Scalars['String']['output']
}

export type BusinessType = {
  __typename?: 'BusinessType'
  address: Scalars['JSON']['output']
  authMethod: Scalars['String']['output']
  brand: Scalars['String']['output']
  businessType?: Maybe<Scalars['String']['output']>
  configurations?: Maybe<BusinessConfigurationsType>
  contactEmail: Scalars['String']['output']
  contactPhone: Scalars['String']['output']
  currency?: Maybe<Scalars['String']['output']>
  featureFlags: Scalars['JSON']['output']
  id: Scalars['String']['output']
  logo: Scalars['String']['output']
  name: Scalars['String']['output']
  okta: OktaConfigType
  paymentConfigs?: Maybe<PaymentConfigsType>
  storeGroupClusters?: Maybe<Array<StoreGroupClusterType>>
  supportEmail: Scalars['String']['output']
  supportPhone: Scalars['String']['output']
  toteSuperAdmin: Scalars['Boolean']['output']
}

export type CampaignInput = {
  benefits: Array<Scalars['String']['input']>
  business: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  promotions: Array<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type CampaignType = {
  __typename?: 'CampaignType'
  benefits: Array<Scalars['String']['output']>
  business: BusinessType
  description: Scalars['String']['output']
  endDate: Scalars['DateTime']['output']
  name: Scalars['String']['output']
  promotions: Array<PromotionType>
  startDate: Scalars['DateTime']['output']
  status: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type CardType = {
  __typename?: 'CardType'
  brand?: Maybe<Scalars['String']['output']>
  fingerprint?: Maybe<Scalars['String']['output']>
  last4?: Maybe<Scalars['String']['output']>
}

export type CartType = {
  __typename?: 'CartType'
  cartPromotion?: Maybe<PromotionType>
  cartTransferId?: Maybe<Scalars['String']['output']>
  currency: Scalars['String']['output']
  currencyMultiplier: Scalars['Int']['output']
  customer?: Maybe<CustomerType>
  customerAgeVerification?: Maybe<AgeAttributesType>
  deliveryAddress?: Maybe<AddressType>
  deliveryTimeSlot?: Maybe<DeliveryTimeSlotType>
  discounts?: Maybe<Array<DiscountType>>
  employee: EmployeeType
  id: Scalars['String']['output']
  isBopisOrder?: Maybe<Scalars['Boolean']['output']>
  items: Array<ItemType>
  location: LocationType
  omsOrderId?: Maybe<Scalars['String']['output']>
  returnedItems: Array<ReturnItemType>
  subTotal?: Maybe<Scalars['Int']['output']>
  subscriptionItems: Array<SubscriptionItemType>
  totalAmount?: Maybe<Scalars['Int']['output']>
  totalDiscount?: Maybe<Scalars['Int']['output']>
  totalReturnAmount?: Maybe<Scalars['Int']['output']>
  totalTax?: Maybe<Scalars['Int']['output']>
}

export type CashEventResponse = {
  __typename?: 'CashEventResponse'
  cashEvent?: Maybe<Scalars['String']['output']>
}

export type CashManagementLocationConfigType = {
  __typename?: 'CashManagementLocationConfigType'
  storeFloatAmount?: Maybe<Scalars['Int']['output']>
  tillAmountUpperLimit?: Maybe<Scalars['Int']['output']>
}

export type CashSalesStatsType = {
  __typename?: 'CashSalesStatsType'
  cashSales?: Maybe<Scalars['Float']['output']>
  percentageChange?: Maybe<Scalars['String']['output']>
}

export type CashTransactionAggregrationType = {
  __typename?: 'CashTransactionAggregrationType'
  averageTransactionValueStats?: Maybe<AverageTransactionValueType>
  averageVariancePercentageStats?: Maybe<AverageVariancePercentageType>
  cashSalesStats?: Maybe<CashSalesStatsType>
  cashVarianceStats?: Maybe<CashVarianceType>
  totalActualCashStats?: Maybe<TotalActualCashType>
  totalExpectedCashStats?: Maybe<TotalExpectedCashType>
  totalVarianceStats?: Maybe<TotalVarianceStatsType>
}

export type CashTransactionFailureType = {
  __typename?: 'CashTransactionFailureType'
  createdAt?: Maybe<Scalars['String']['output']>
  error?: Maybe<Scalars['String']['output']>
  eventId?: Maybe<Scalars['String']['output']>
  eventType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  location?: Maybe<LocationType>
  payload?: Maybe<Scalars['JSON']['output']>
}

export type CashTransactionInputType = {
  amount: Scalars['Int']['input']
  description?: InputMaybe<Scalars['String']['input']>
  eventType: Scalars['String']['input']
  id: Scalars['ID']['input']
  locationId: Scalars['String']['input']
  register: Scalars['String']['input']
  till: Scalars['String']['input']
  transactionType: Scalars['String']['input']
}

export type CashTransactionType = {
  __typename?: 'CashTransactionType'
  amount: Scalars['Float']['output']
  closeTime?: Maybe<Scalars['String']['output']>
  dateCreated?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  employee?: Maybe<EmployeeType>
  employeeCode?: Maybe<Scalars['String']['output']>
  eventId?: Maybe<Scalars['String']['output']>
  eventTime?: Maybe<Scalars['String']['output']>
  eventType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isSafeTransaction?: Maybe<Scalars['Boolean']['output']>
  location?: Maybe<LocationType>
  openTime?: Maybe<Scalars['String']['output']>
  register?: Maybe<Scalars['String']['output']>
  safeType?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
  storeNumber?: Maybe<Scalars['String']['output']>
  till?: Maybe<Scalars['String']['output']>
  transactionId?: Maybe<Scalars['String']['output']>
  transactionTime?: Maybe<Scalars['String']['output']>
  transactionType?: Maybe<Scalars['String']['output']>
  transferTo?: Maybe<Scalars['String']['output']>
  transferToTill?: Maybe<Scalars['String']['output']>
}

export type CashVarianceType = {
  __typename?: 'CashVarianceType'
  cashVariance?: Maybe<Scalars['Float']['output']>
  percentageChange?: Maybe<Scalars['String']['output']>
}

export type ChartAxisType = {
  __typename?: 'ChartAxisType'
  label?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  timeType?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type ChartConfigType = {
  __typename?: 'ChartConfigType'
  category: Scalars['String']['output']
  chartBaseUrl?: Maybe<Scalars['String']['output']>
  chartId: Scalars['String']['output']
  chartName: Scalars['String']['output']
  enableChart: Scalars['Boolean']['output']
  filter?: Maybe<Scalars['JSON']['output']>
  xAxis: ChartAxisType
  yAxis: ChartAxisType
}

export type ConfigurationsInput = {
  allowedValues?: InputMaybe<Array<Scalars['String']['input']>>
  name: Scalars['String']['input']
  value: ValueTypeInput
  valueType: Scalars['String']['input']
}

export type ConfigurationsType = {
  __typename?: 'ConfigurationsType'
  allowedValues?: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  value: ValueType
  valueType: Scalars['String']['output']
}

export type ConnectionToken = {
  __typename?: 'ConnectionToken'
  connectionToken?: Maybe<Scalars['String']['output']>
  error?: Maybe<Scalars['String']['output']>
}

export type ContactDetailsInput = {
  contactEmail?: InputMaybe<Scalars['String']['input']>
  contactPhone?: InputMaybe<Scalars['String']['input']>
  supportEmail?: InputMaybe<Scalars['String']['input']>
  supportPhone?: InputMaybe<Scalars['String']['input']>
}

export type ContactDetailsType = {
  __typename?: 'ContactDetailsType'
  contactEmail?: Maybe<Scalars['String']['output']>
  contactPhone?: Maybe<Scalars['String']['output']>
  supportEmail?: Maybe<Scalars['String']['output']>
  supportPhone?: Maybe<Scalars['String']['output']>
}

export type CreateStoreGroupInput = {
  childLocationIds: Array<Scalars['String']['input']>
  childStoreGroupIds: Array<Scalars['String']['input']>
  name: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  storeGroupClusterId: Scalars['String']['input']
}

export type CustomerActivityLogType = {
  __typename?: 'CustomerActivityLogType'
  activityType: Scalars['String']['output']
  business: BusinessType
  createdAt: Scalars['DateTime']['output']
  customer: CustomerType
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  items?: Maybe<Array<ItemType>>
  location?: Maybe<LocationType>
  metaData?: Maybe<Scalars['JSON']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type CustomerAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type CustomerAddressType = {
  __typename?: 'CustomerAddressType'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type CustomerFuilfillmentDetails = {
  __typename?: 'CustomerFuilfillmentDetails'
  email?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

export type CustomerInput = {
  address?: InputMaybe<CustomerAddressInput>
  business: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  gender?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  password?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  preferences?: InputMaybe<Array<InputMaybe<PreferenceInput>>>
  signupToken?: InputMaybe<Scalars['String']['input']>
}

export type CustomerLoginType = {
  __typename?: 'CustomerLoginType'
  customer: CustomerType
  token: Scalars['String']['output']
}

export type CustomerLoyaltyType = {
  __typename?: 'CustomerLoyaltyType'
  customerId?: Maybe<Scalars['String']['output']>
  firstPurchaseDate?: Maybe<Scalars['DateTime']['output']>
  topCategory?: Maybe<Scalars['String']['output']>
  topSpendAmount?: Maybe<Scalars['Int']['output']>
  totalPurchase?: Maybe<Scalars['Int']['output']>
}

export type CustomerType = {
  __typename?: 'CustomerType'
  address?: Maybe<CustomerAddressType>
  addresses?: Maybe<Array<CustomerAddressType>>
  anniversary?: Maybe<Scalars['Date']['output']>
  avatar?: Maybe<Scalars['String']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  birthday?: Maybe<Scalars['Date']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  discount: Array<Scalars['JSON']['output']>
  email?: Maybe<Scalars['String']['output']>
  emailVerified?: Maybe<Scalars['Boolean']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  maritalStatus?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  phoneCountryCode?: Maybe<Scalars['String']['output']>
  preferences: Array<Maybe<PreferenceType>>
  sessionToken?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DeliveryTimeSlotInput = {
  endTime: TimeInput
  startTime: TimeInput
}

export type DeliveryTimeSlotType = {
  __typename?: 'DeliveryTimeSlotType'
  endTime: TimeType
  startTime: TimeType
}

export type DenominationInput = {
  count?: InputMaybe<Scalars['Int']['input']>
  denomination?: InputMaybe<Scalars['Int']['input']>
}

export type Device = {
  __typename?: 'Device'
  deviceId: Scalars['String']['output']
  deviceName: Scalars['String']['output']
  deviceType: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata?: Maybe<Scalars['JSON']['output']>
  port: Scalars['Int']['output']
  register?: Maybe<Register>
  status: Scalars['String']['output']
  zone?: Maybe<Zone>
}

export type DeviceActivityType = {
  __typename?: 'DeviceActivityType'
  action: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  time: Scalars['String']['output']
  user: Scalars['String']['output']
}

export type DeviceDataResponseType = {
  __typename?: 'DeviceDataResponseType'
  action: Scalars['String']['output']
  deviceName: Scalars['String']['output']
  deviceType: Scalars['String']['output']
  id: Scalars['String']['output']
  issueDescription: Scalars['String']['output']
  lastActive: Scalars['String']['output']
  registerName: Scalars['String']['output']
  serialNumber: Scalars['String']['output']
  severity: Scalars['String']['output']
  zoneName?: Maybe<Scalars['String']['output']>
}

export type DeviceHubReadmeType = {
  __typename?: 'DeviceHubReadmeType'
  text?: Maybe<Scalars['String']['output']>
  version?: Maybe<Scalars['String']['output']>
}

export type DeviceHubVersionInfoType = {
  __typename?: 'DeviceHubVersionInfoType'
  currentVersion?: Maybe<Scalars['String']['output']>
  hash?: Maybe<Scalars['String']['output']>
  readme?: Maybe<Array<DeviceHubReadmeType>>
  url?: Maybe<Scalars['String']['output']>
  versions?: Maybe<Array<Scalars['String']['output']>>
}

export type DeviceInfoType = {
  __typename?: 'DeviceInfoType'
  deviceType: Scalars['String']['output']
  id: Scalars['String']['output']
  metadata: Scalars['JSON']['output']
  name: Scalars['String']['output']
  numberOfErrors: Scalars['JSON']['output']
  status: Scalars['String']['output']
  timeUsed: Scalars['JSON']['output']
}

export type DevicesDiscoveryQueryResponseType = {
  __typename?: 'DevicesDiscoveryQueryResponseType'
  devices?: Maybe<Array<DevicesDiscoveryType>>
  locationId?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type DevicesDiscoveryType = {
  __typename?: 'DevicesDiscoveryType'
  deviceId?: Maybe<Scalars['String']['output']>
  deviceLastActive?: Maybe<Scalars['String']['output']>
  deviceName?: Maybe<Scalars['String']['output']>
  devicePort?: Maybe<Scalars['Int']['output']>
  deviceStatus?: Maybe<Scalars['String']['output']>
  deviceType?: Maybe<Scalars['String']['output']>
  registerId?: Maybe<Scalars['String']['output']>
  registerIps?: Maybe<Array<Scalars['String']['output']>>
  registerName?: Maybe<Scalars['String']['output']>
  zoneDescription?: Maybe<Scalars['String']['output']>
  zoneId?: Maybe<Scalars['String']['output']>
  zoneName?: Maybe<Scalars['String']['output']>
}

export type DevicesType = {
  __typename?: 'DevicesType'
  dateModified?: Maybe<Scalars['String']['output']>
  deviceId?: Maybe<Scalars['String']['output']>
  deviceName?: Maybe<Scalars['String']['output']>
  deviceType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  machineName?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type DiscountInput = {
  discountAmount?: InputMaybe<Scalars['Int']['input']>
  discountType: Scalars['String']['input']
  discountTypeId?: InputMaybe<Scalars['String']['input']>
  discountValue: Scalars['Float']['input']
  discountValueType: Scalars['String']['input']
  name: Scalars['String']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type DiscountType = {
  __typename?: 'DiscountType'
  discountAmount: Scalars['Int']['output']
  discountType: Scalars['String']['output']
  discountTypeId: Scalars['String']['output']
  discountValue: Scalars['Float']['output']
  discountValueType: Scalars['String']['output']
  name: Scalars['String']['output']
  reason?: Maybe<Scalars['String']['output']>
}

export type DiscoveryHubComponentQueryResponseType = {
  __typename?: 'DiscoveryHubComponentQueryResponseType'
  devices?: Maybe<Array<DevicesDiscoveryType>>
  locationId?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  registers?: Maybe<Array<RegistersDiscoveryType>>
  success: Scalars['Boolean']['output']
  zones?: Maybe<Array<ZonesDiscoveryType>>
}

export type EmployeeInput = {
  address?: InputMaybe<AddressInput>
  assignedRegister?: InputMaybe<Scalars['String']['input']>
  assignedStoreGroups?: InputMaybe<Array<Scalars['String']['input']>>
  business?: InputMaybe<Scalars['String']['input']>
  defaultLocation?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  employeeCode: Scalars['String']['input']
  locations?: InputMaybe<Array<Scalars['String']['input']>>
  name: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type EmployeeListType = {
  __typename?: 'EmployeeListType'
  employees: Array<EmployeeType>
  totalCount: Scalars['Int']['output']
}

export type EmployeeType = {
  __typename?: 'EmployeeType'
  assignedRegister?: Maybe<Scalars['String']['output']>
  assignedStoreGroups?: Maybe<Array<StoreGroupType>>
  business?: Maybe<BusinessType>
  defaultLocation?: Maybe<LocationType>
  email?: Maybe<Scalars['String']['output']>
  employeeCode: Scalars['String']['output']
  fullAddress?: Maybe<AddressType>
  id: Scalars['String']['output']
  locations?: Maybe<Array<LocationType>>
  name: Scalars['String']['output']
  peripheralHubNotifications?: Maybe<PeripheralHubNotificationsType>
  phoneNumber?: Maybe<Scalars['String']['output']>
  role?: Maybe<RoleType>
  status?: Maybe<Scalars['String']['output']>
  toteSuperAdmin?: Maybe<Scalars['Boolean']['output']>
}

export type EmployeeValidatorOutput = {
  __typename?: 'EmployeeValidatorOutput'
  data: Scalars['JSON']['output']
  employee?: Maybe<EmployeeType>
}

export type ErrorNotificationType = {
  __typename?: 'ErrorNotificationType'
  action: Scalars['String']['output']
  activityId: Scalars['String']['output']
  deviceName: Scalars['String']['output']
  deviceType: Scalars['String']['output']
  id: Scalars['String']['output']
  issueDescription: Scalars['String']['output']
  lastActive: Scalars['String']['output']
  registerName: Scalars['String']['output']
  serialNumber: Scalars['String']['output']
  severity: Scalars['String']['output']
  timeNotified: Scalars['String']['output']
  timeResolved: Scalars['String']['output']
  zoneName?: Maybe<Scalars['String']['output']>
}

export type FileType = {
  __typename?: 'FileType'
  fileContent: Scalars['String']['output']
  fileName: Scalars['String']['output']
}

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse'
  success: Scalars['Boolean']['output']
}

export type FulfillmentDetails = {
  __typename?: 'FulfillmentDetails'
  customerDetails?: Maybe<CustomerFuilfillmentDetails>
  pickupTime?: Maybe<Scalars['DateTime']['output']>
}

export type GenieAnalyticsQueryType = {
  __typename?: 'GenieAnalyticsQueryType'
  data?: Maybe<Scalars['JSON']['output']>
  html?: Maybe<Scalars['String']['output']>
}

export type GenieChatQueryType = {
  __typename?: 'GenieChatQueryType'
  products?: Maybe<Array<ProductType>>
  promotions?: Maybe<Array<PromotionType>>
  resultType?: Maybe<Scalars['String']['output']>
}

export type GenieProductListType = {
  __typename?: 'GenieProductListType'
  answer?: Maybe<Scalars['String']['output']>
  chatSessionId?: Maybe<Scalars['String']['output']>
  followups?: Maybe<Array<Scalars['String']['output']>>
  products: Array<ProductType>
  promotions?: Maybe<Array<PromotionType>>
  query?: Maybe<Scalars['String']['output']>
  resultType?: Maybe<Scalars['String']['output']>
}

export type GenieQueryType = {
  __typename?: 'GenieQueryType'
  answer?: Maybe<Scalars['String']['output']>
  chatSessionId?: Maybe<Scalars['String']['output']>
  followups?: Maybe<Array<Scalars['String']['output']>>
  genieAnalyticsQuery?: Maybe<GenieAnalyticsQueryType>
  genieChatQuery?: Maybe<GenieChatQueryType>
  messageId: Scalars['String']['output']
  query?: Maybe<Scalars['String']['output']>
  queryType: Scalars['String']['output']
  status?: Maybe<Scalars['String']['output']>
}

export type InsightChartsType = {
  __typename?: 'InsightChartsType'
  category: Scalars['String']['output']
  charts: Array<ChartConfigType>
  durationType: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type InsightType = {
  __typename?: 'InsightType'
  charts: Array<ChartConfigType>
  dashboardBaseUrl: Scalars['String']['output']
  dashboardDurationType: Scalars['String']['output']
  dashboardHeight: Scalars['Int']['output']
  dashboardId: Scalars['String']['output']
  dashboardSubtitle: Scalars['String']['output']
  dashboardTitle: Scalars['String']['output']
  dashboardWidth: Scalars['Int']['output']
  enableDashboard: Scalars['Boolean']['output']
  type: Scalars['String']['output']
}

export type InventoryDetailsType = {
  __typename?: 'InventoryDetailsType'
  ageVerificationRequired?: Maybe<Scalars['Boolean']['output']>
  businessId: Scalars['String']['output']
  categoryName?: Maybe<Scalars['String']['output']>
  className?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  locationId: Scalars['String']['output']
  logoUrl?: Maybe<Scalars['String']['output']>
  pricingDetails: InventoryPricingType
  recommendedProducts?: Maybe<Array<InventoryDetailsType>>
  requiredMinimumAge?: Maybe<Scalars['String']['output']>
  sendEmail?: Maybe<Scalars['Boolean']['output']>
  sku: Scalars['String']['output']
  skuType?: Maybe<Scalars['String']['output']>
  subclassName?: Maybe<Scalars['String']['output']>
  taxDetails: InvetoryTaxesType
  taxable?: Maybe<Scalars['String']['output']>
  unit?: Maybe<Scalars['String']['output']>
  unitCount?: Maybe<Scalars['String']['output']>
  upc?: Maybe<Scalars['String']['output']>
}

export type InventoryInput = {
  ageVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>
  categoryName?: InputMaybe<Scalars['String']['input']>
  className?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  pricingDetails: InventoryPricingInput
  recommendedProducts?: InputMaybe<Array<Scalars['String']['input']>>
  requiredMinimumAge?: InputMaybe<Scalars['String']['input']>
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>
  sku: Scalars['String']['input']
  skuType?: InputMaybe<Scalars['String']['input']>
  subclassName?: InputMaybe<Scalars['String']['input']>
  taxDetails: InvetoryTaxesInput
  taxable?: InputMaybe<Scalars['String']['input']>
  unit?: InputMaybe<Scalars['String']['input']>
  unitCount?: InputMaybe<Scalars['String']['input']>
  upc?: InputMaybe<Scalars['String']['input']>
}

export type InventoryListType = {
  __typename?: 'InventoryListType'
  inventories: Array<InventoryDetailsType>
  pageIndex: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  totalPageCount: Scalars['Int']['output']
}

export type InventoryPricingInput = {
  currency: Scalars['String']['input']
  currencyMultiplier: Scalars['String']['input']
  discount?: InputMaybe<Scalars['String']['input']>
  unitPrice: Scalars['String']['input']
}

export type InventoryPricingType = {
  __typename?: 'InventoryPricingType'
  currency: Scalars['String']['output']
  currencyMultiplier: Scalars['String']['output']
  discount?: Maybe<Scalars['String']['output']>
  total: Scalars['String']['output']
  unitPrice: Scalars['String']['output']
}

export type InvetoryTaxesInput = {
  taxGroup?: InputMaybe<Scalars['String']['input']>
  taxRate?: InputMaybe<Scalars['String']['input']>
}

export type InvetoryTaxesType = {
  __typename?: 'InvetoryTaxesType'
  taxAmount?: Maybe<Scalars['String']['output']>
  taxGroup?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['String']['output']>
}

export type ItemInput = {
  ageVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>
  aisleLocation?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  isDeliveryItem?: InputMaybe<Scalars['Boolean']['input']>
  isReturnable?: InputMaybe<Scalars['Boolean']['input']>
  itemConditionOptions?: InputMaybe<Array<Scalars['String']['input']>>
  itemType?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  originalItemSku?: InputMaybe<Scalars['String']['input']>
  promotions?: InputMaybe<Array<PromotionInput>>
  quantity?: InputMaybe<Scalars['Int']['input']>
  quantityRefunded?: InputMaybe<Scalars['Int']['input']>
  quantitySubstituted?: InputMaybe<Scalars['Int']['input']>
  referenceProductId?: InputMaybe<Scalars['String']['input']>
  refundReason?: InputMaybe<Scalars['String']['input']>
  requiredMinimumAge?: InputMaybe<Scalars['Int']['input']>
  returnReasonOptions?: InputMaybe<Array<Scalars['String']['input']>>
  sku: Scalars['String']['input']
  skuType?: InputMaybe<Scalars['String']['input']>
  sortPromotionsPriority?: InputMaybe<Scalars['Boolean']['input']>
  substitutionReason?: InputMaybe<Scalars['String']['input']>
  taxAmount?: InputMaybe<Scalars['Int']['input']>
  taxGroup?: InputMaybe<Scalars['String']['input']>
  taxRate?: InputMaybe<Scalars['Decimal']['input']>
  total?: InputMaybe<Scalars['Int']['input']>
  totalDiscount?: InputMaybe<Scalars['Int']['input']>
  totalTaxAmount?: InputMaybe<Scalars['Int']['input']>
  unit?: InputMaybe<Scalars['String']['input']>
  unitDiscount?: InputMaybe<Scalars['Int']['input']>
  unitPrice: Scalars['Int']['input']
  unitSubTotal?: InputMaybe<Scalars['Int']['input']>
  unitTaxAmount?: InputMaybe<Scalars['Int']['input']>
  upc?: InputMaybe<Scalars['String']['input']>
}

export type ItemType = {
  __typename?: 'ItemType'
  ageVerificationRequired?: Maybe<Scalars['Boolean']['output']>
  aisleLocation?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  discounts?: Maybe<Array<DiscountType>>
  isDeliveryItem?: Maybe<Scalars['Boolean']['output']>
  isReturnable?: Maybe<Scalars['Boolean']['output']>
  itemConditionOptions?: Maybe<Array<Scalars['String']['output']>>
  itemType?: Maybe<Scalars['String']['output']>
  location?: Maybe<LocationType>
  logoUrl?: Maybe<Scalars['String']['output']>
  originalItemSku?: Maybe<Scalars['String']['output']>
  promotions?: Maybe<Array<PromotionType>>
  promotionsApplied?: Maybe<Array<PromotionType>>
  quantity: Scalars['Int']['output']
  quantityRefunded?: Maybe<Scalars['Int']['output']>
  quantitySubstituted?: Maybe<Scalars['Int']['output']>
  referenceProductId?: Maybe<Scalars['String']['output']>
  refundReason?: Maybe<Scalars['String']['output']>
  requiredMinimumAge?: Maybe<Scalars['Int']['output']>
  returnReasonOptions?: Maybe<Array<Scalars['String']['output']>>
  sku: Scalars['String']['output']
  skuType?: Maybe<Scalars['String']['output']>
  subTotal?: Maybe<Scalars['Int']['output']>
  substitutionReason?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['Int']['output']>
  taxGroup?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Float']['output']>
  total?: Maybe<Scalars['Int']['output']>
  totalDiscount?: Maybe<Scalars['Int']['output']>
  totalTaxAmount?: Maybe<Scalars['Int']['output']>
  unit?: Maybe<Scalars['String']['output']>
  unitDiscount?: Maybe<Scalars['Int']['output']>
  unitPrice?: Maybe<Scalars['Int']['output']>
  unitSubTotal?: Maybe<Scalars['Int']['output']>
  unitTaxAmount?: Maybe<Scalars['Int']['output']>
  upc?: Maybe<Scalars['String']['output']>
}

export type LocationConfigInput = {
  locationId: Scalars['String']['input']
  stripe?: InputMaybe<StripeLocationConfigInput>
}

export type LocationConfigType = {
  __typename?: 'LocationConfigType'
  cashManagement?: Maybe<CashManagementLocationConfigType>
  stripe?: Maybe<StripeLocationConfigType>
}

export type LocationInput = {
  address: AddressInput
  amenities?: InputMaybe<Array<Scalars['String']['input']>>
  brand: Scalars['String']['input']
  business: Scalars['String']['input']
  configuration?: InputMaybe<Scalars['JSON']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  notificationEmails?: InputMaybe<Array<Scalars['String']['input']>>
  operatingHours: Array<OperatingHourInput>
  phoneNumbers: PhoneNumberInput
  segments?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  storeGroups?: InputMaybe<Array<Scalars['String']['input']>>
  storeNumber: Scalars['String']['input']
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  timeZone: Scalars['String']['input']
}

export type LocationType = {
  __typename?: 'LocationType'
  address: AddressType
  amenities: Array<Scalars['String']['output']>
  brand: Scalars['String']['output']
  business: BusinessType
  configuration: Scalars['JSON']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  locationConfig?: Maybe<LocationConfigType>
  name: Scalars['String']['output']
  notificationEmails?: Maybe<Array<Scalars['String']['output']>>
  operatingHours: Array<OperatingHourType>
  phoneNumbers: PhoneNumberType
  segments: Array<SegmentType>
  status: Scalars['String']['output']
  storeGroups?: Maybe<Array<StoreGroupType>>
  storeNumber: Scalars['String']['output']
  tags: Array<Scalars['String']['output']>
  timeZone: Scalars['String']['output']
}

export type LoginResponseType = {
  __typename?: 'LoginResponseType'
  businessId: Scalars['String']['output']
  mfaEnabled: Scalars['Boolean']['output']
  toteAccessToken?: Maybe<Scalars['String']['output']>
}

export type LoginUser = {
  __typename?: 'LoginUser'
  businessConfigs?: Maybe<BusinessConfigsType>
  location?: Maybe<LocationType>
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  token?: Maybe<Scalars['String']['output']>
  user?: Maybe<EmployeeType>
}

export type LoyaltySignupEventType = {
  __typename?: 'LoyaltySignupEventType'
  campaign?: Maybe<CampaignType>
  eligible: Scalars['Boolean']['output']
  url: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  activateLocation: LocationType
  addCustomer?: Maybe<Scalars['Void']['output']>
  addCustomerAddress: CustomerType
  addCustomerForConsumerAgent?: Maybe<Scalars['Void']['output']>
  addLocation: LocationType
  addPromotionToSku: ApiResponse
  addSafeCashTransactionEvent: CashEventResponse
  addSecret?: Maybe<Scalars['Void']['output']>
  addTransactionEvent: CashEventResponse
  addZoneData?: Maybe<Scalars['Void']['output']>
  applyDiscount: CartType
  approveItemSubscription?: Maybe<Scalars['Void']['output']>
  assignBulkSegments: AssignSegmentType
  assignCurrentVersion?: Maybe<Scalars['Void']['output']>
  assignRole?: Maybe<Scalars['Void']['output']>
  assignSegment: AssignSegmentType
  bulkTransactionEvent: CashEventResponse
  cartTransferPos: CartType
  cleanupTestLabData?: Maybe<Scalars['Void']['output']>
  copyNfcCartToMpos: Scalars['Boolean']['output']
  createBankDeposit: BankDepositType
  createBusiness: BusinessDetailsType
  createCampaign: CampaignType
  createCart: CartType
  createCartGuest: CartType
  createCartTransferId: CartType
  createConnectionToken: ConnectionToken
  createEmployee: EmployeeType
  createInventory: InventoryDetailsType
  createNote: NoteType
  createPaymentIntent: Scalars['JSON']['output']
  createPromotion: PromotionType
  createRole?: Maybe<Scalars['Void']['output']>
  createRule: PromoRuleType
  createStoreGroup: StoreGroupType
  createStoreTransaction: StoreTransactionType
  customerSignin: CustomerLoginType
  deactivateLocation: LocationType
  deleteChatHistory?: Maybe<Scalars['Void']['output']>
  deleteChatHistoryDashboard?: Maybe<Scalars['Void']['output']>
  deleteChatHistoryForConsumerAgent?: Maybe<Scalars['Void']['output']>
  deleteInventory?: Maybe<Scalars['Void']['output']>
  deleteRole?: Maybe<Scalars['Void']['output']>
  deleteSecret?: Maybe<Scalars['Void']['output']>
  deleteSegment: AssignSegmentType
  deleteZone?: Maybe<Scalars['Void']['output']>
  downloadDepositReceipt: FileType
  generateAtlasAuthToken: AtlasAuthType
  generateCustomerSignUpUrl: Scalars['String']['output']
  generateGenieChatSessionId: Scalars['String']['output']
  generateGenieChatSessionIdConsumerAgent: Scalars['String']['output']
  generateGenieChatSessionIdDashboard: Scalars['String']['output']
  generateOauthApplications: OAuthApplicationsType
  locationConfig?: Maybe<Scalars['Void']['output']>
  loginCustomer: CustomerLoginType
  loginGuest: LoginUser
  loginUser: LoginUser
  loginUserForDashboard: LoginResponseType
  orderPaymentStatus: OrderType
  orderPaymentStatusConsumerAgent: OrderType
  orderRefundStatus: OrderType
  placeOrder: OrderType
  placeOrderConsumerAgent: OrderType
  renameStoreGroupCluster: StoreGroupClusterType
  resendOtp?: Maybe<Scalars['Void']['output']>
  resendOtpForPasswordReset?: Maybe<Scalars['Void']['output']>
  resetOrderEmailReceiptTemplate: Scalars['String']['output']
  resetPasswordWithOtp?: Maybe<Scalars['Void']['output']>
  resolveErrorNotification?: Maybe<Scalars['Void']['output']>
  returnOrder: OrderType
  sendEmail: Scalars['Boolean']['output']
  sendOrderReceiptEmail?: Maybe<Scalars['Void']['output']>
  sendOtpForPasswordReset?: Maybe<Scalars['Void']['output']>
  sendPasswordSetLink?: Maybe<Scalars['Void']['output']>
  serviceConfig?: Maybe<Scalars['Void']['output']>
  signupCustomer: CustomerLoginType
  toggleEmailReceiptEnabled: Scalars['Boolean']['output']
  updateBankDeposit: BankDepositType
  updateBopisOrder: OrderType
  updateBusiness: BusinessDetailsType
  updateBusinessColors: BusinessColorsType
  updateCampaign: CampaignType
  updateCart: CartType
  updateCartAddress: CartType
  updateCartItems: CartType
  updateCustomer?: Maybe<Scalars['Void']['output']>
  updateEmployee: EmployeeType
  updateInventory: InventoryDetailsType
  updateInventoryUnitCount: ApiResponse
  updateLocation: LocationType
  updateOrderEmailReceiptTemplate: Scalars['String']['output']
  updatePeripheralHubNotificationConfig?: Maybe<Scalars['Void']['output']>
  updatePluginSettings: PluginSettingsType
  updatePredefinedChatQuery: PredefinedQueryType
  updateRole?: Maybe<Scalars['Void']['output']>
  updateStoreGroup: StoreGroupType
  updateZone?: Maybe<Scalars['Void']['output']>
  validateCustomerSignupToken: ValidateCustomerSignupTokenType
  verifyAdminOktaTokenAndGenerateSessionToken: Scalars['String']['output']
  verifyCart: Scalars['Boolean']['output']
  verifyOktaTokenAndGenerateSessionToken: Scalars['String']['output']
  verifyOtpAndGenerateSessionToken: LoginResponseType
  verifyServiceConfig: Scalars['Boolean']['output']
}

export type MutationActivateLocationArgs = {
  locationId: Scalars['String']['input']
}

export type MutationAddCustomerArgs = {
  customer: CustomerInput
}

export type MutationAddCustomerAddressArgs = {
  address: CustomerAddressInput
  customerId: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationAddCustomerForConsumerAgentArgs = {
  customer: CustomerInput
}

export type MutationAddLocationArgs = {
  location: LocationInput
}

export type MutationAddPromotionToSkuArgs = {
  businessId: Scalars['String']['input']
  promotionName: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export type MutationAddSafeCashTransactionEventArgs = {
  amount: Scalars['Float']['input']
  description: Scalars['String']['input']
  eventId: Scalars['String']['input']
  eventType: Scalars['String']['input']
  fromSafe: Scalars['String']['input']
  locationId: Scalars['String']['input']
  toSafe: Scalars['String']['input']
}

export type MutationAddSecretArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
  secretName: Scalars['String']['input']
  secretValue: Scalars['String']['input']
}

export type MutationAddTransactionEventArgs = {
  amount: Scalars['Float']['input']
  businessDate?: InputMaybe<Scalars['String']['input']>
  cashVariance?: InputMaybe<Scalars['Float']['input']>
  denominationBreakdown?: InputMaybe<Array<DenominationInput>>
  description: Scalars['String']['input']
  employeeCode?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['String']['input']
  eventType: Scalars['String']['input']
  locationId: Scalars['String']['input']
  otherDenominationCurrency?: InputMaybe<Scalars['Int']['input']>
  reasonCode?: InputMaybe<Scalars['String']['input']>
  reasonDescription?: InputMaybe<Scalars['String']['input']>
  register: Scalars['String']['input']
  till: Scalars['String']['input']
  transactionId?: InputMaybe<Scalars['String']['input']>
  transactionType?: InputMaybe<Scalars['String']['input']>
}

export type MutationAddZoneDataArgs = {
  businessId: Scalars['String']['input']
  description: Scalars['String']['input']
  devices: Array<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  name: Scalars['String']['input']
  registers: Array<Scalars['String']['input']>
}

export type MutationApplyDiscountArgs = {
  cartId: Scalars['String']['input']
  discount: DiscountInput
  employeeId?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type MutationApproveItemSubscriptionArgs = {
  cartId: Scalars['String']['input']
  sku: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationAssignBulkSegmentsArgs = {
  segmentId: Scalars['String']['input']
}

export type MutationAssignCurrentVersionArgs = {
  businessId: Scalars['String']['input']
  current?: Scalars['Boolean']['input']
  locationId: Scalars['String']['input']
  version: Scalars['String']['input']
}

export type MutationAssignRoleArgs = {
  employeeId: Scalars['String']['input']
  roleId?: InputMaybe<Scalars['String']['input']>
}

export type MutationAssignSegmentArgs = {
  locationId: Scalars['String']['input']
  segmentId: Scalars['String']['input']
}

export type MutationBulkTransactionEventArgs = {
  transactions: Array<CashTransactionInputType>
}

export type MutationCartTransferPosArgs = {
  cartTransferId?: InputMaybe<Scalars['String']['input']>
}

export type MutationCleanupTestLabDataArgs = {
  namePrefix: Scalars['String']['input']
}

export type MutationCopyNfcCartToMposArgs = {
  locationId: Scalars['String']['input']
  mposCartId: Scalars['String']['input']
  nfcCartId: Scalars['String']['input']
}

export type MutationCreateBankDepositArgs = {
  amount: Scalars['Int']['input']
  employeeId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
}

export type MutationCreateBusinessArgs = {
  business: BusinessDetailsInput
}

export type MutationCreateCampaignArgs = {
  campaign: CampaignInput
}

export type MutationCreateCartArgs = {
  customerAgeVerification?: InputMaybe<AgeAttributesInput>
  customerId?: InputMaybe<Scalars['String']['input']>
  deliveryAddress?: InputMaybe<AddressInput>
  deliveryTimeSlot?: InputMaybe<DeliveryTimeSlotInput>
  employeeId: Scalars['String']['input']
  isBopisOrder?: InputMaybe<Scalars['Boolean']['input']>
  items?: InputMaybe<Array<ItemInput>>
  locationId: Scalars['String']['input']
  omsOrderId?: InputMaybe<Scalars['String']['input']>
  promotionId?: InputMaybe<Scalars['String']['input']>
  registerNumber?: InputMaybe<Scalars['String']['input']>
  returnedItems?: InputMaybe<Array<ReturnItemInput>>
  subscriptionItems?: InputMaybe<Array<SubscriptionItemInput>>
  transactionNumber?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateCartGuestArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>
  items?: InputMaybe<Array<ItemInput>>
  locationId: Scalars['String']['input']
}

export type MutationCreateCartTransferIdArgs = {
  cartId: Scalars['String']['input']
  cartTransferIdPrefix?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateConnectionTokenArgs = {
  cartId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateEmployeeArgs = {
  businessId: Scalars['String']['input']
  employee: EmployeeInput
}

export type MutationCreateInventoryArgs = {
  inventory: InventoryInput
  locationId: Scalars['String']['input']
}

export type MutationCreateNoteArgs = {
  businessId: Scalars['String']['input']
  customerId: Scalars['String']['input']
  employeeId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  message: Scalars['String']['input']
}

export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input']
  businessId: Scalars['String']['input']
  currency?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreatePromotionArgs = {
  active: Scalars['Boolean']['input']
  businessId: Scalars['String']['input']
  canBeCombined: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  endDate: Scalars['DateTime']['input']
  priority: Scalars['Int']['input']
  promotionName: Scalars['String']['input']
  promotionSchema?: InputMaybe<Scalars['String']['input']>
  promotionType: Scalars['String']['input']
  promotionValue: Scalars['Int']['input']
  rules: Array<Scalars['String']['input']>
  startDate: Scalars['DateTime']['input']
  subscriptionType?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCreateRoleArgs = {
  businessId: Scalars['String']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  permissions: Array<Scalars['String']['input']>
}

export type MutationCreateRuleArgs = {
  applyTo?: InputMaybe<Array<Scalars['String']['input']>>
  applyToProductGroup?: InputMaybe<Array<Scalars['String']['input']>>
  businessId: Scalars['String']['input']
  excludeFrom?: InputMaybe<Array<Scalars['String']['input']>>
  excludeFromProductGroup?: InputMaybe<Array<Scalars['String']['input']>>
  ruleName: Scalars['String']['input']
  ruleType: Scalars['String']['input']
  ruleValue?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateStoreGroupArgs = {
  input: CreateStoreGroupInput
}

export type MutationCreateStoreTransactionArgs = {
  amount: Scalars['Int']['input']
  denominationBreakdown?: InputMaybe<Array<DenominationInput>>
  employeeId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  otherDenominationCurrency?: InputMaybe<Scalars['Int']['input']>
  reasonCode: Scalars['String']['input']
  reasonDescription?: InputMaybe<Scalars['String']['input']>
  safeType: Scalars['String']['input']
}

export type MutationCustomerSigninArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationDeactivateLocationArgs = {
  locationId: Scalars['String']['input']
}

export type MutationDeleteChatHistoryArgs = {
  chatSessionId: Scalars['String']['input']
}

export type MutationDeleteChatHistoryDashboardArgs = {
  chatSessionId: Scalars['String']['input']
}

export type MutationDeleteChatHistoryForConsumerAgentArgs = {
  chatSessionId: Scalars['String']['input']
}

export type MutationDeleteInventoryArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteRoleArgs = {
  businessId: Scalars['String']['input']
  id: Scalars['String']['input']
}

export type MutationDeleteSecretArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
  secretName: Scalars['String']['input']
}

export type MutationDeleteSegmentArgs = {
  locationId: Scalars['String']['input']
  segmentId: Scalars['String']['input']
}

export type MutationDeleteZoneArgs = {
  zoneId: Scalars['String']['input']
}

export type MutationDownloadDepositReceiptArgs = {
  id: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type MutationGenerateCustomerSignUpUrlArgs = {
  cartId: Scalars['String']['input']
  flow: Scalars['String']['input']
}

export type MutationGenerateOauthApplicationsArgs = {
  appName: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type MutationLocationConfigArgs = {
  input: LocationConfigInput
}

export type MutationLoginCustomerArgs = {
  businessId: Scalars['String']['input']
  email: Scalars['String']['input']
  locationId: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationLoginGuestArgs = {
  locationId: Scalars['ID']['input']
}

export type MutationLoginUserArgs = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type MutationLoginUserForDashboardArgs = {
  businessId: Scalars['String']['input']
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationOrderPaymentStatusArgs = {
  orderId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type MutationOrderPaymentStatusConsumerAgentArgs = {
  orderId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type MutationOrderRefundStatusArgs = {
  orderId: Scalars['String']['input']
  refundId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput
  requestContext?: InputMaybe<RequestContext>
}

export type MutationPlaceOrderConsumerAgentArgs = {
  input: PlaceOrderInput
  requestContext?: InputMaybe<RequestContext>
}

export type MutationRenameStoreGroupClusterArgs = {
  newClusterName: Scalars['String']['input']
  storeGroupClusterId: Scalars['String']['input']
}

export type MutationResendOtpArgs = {
  businessId: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type MutationResendOtpForPasswordResetArgs = {
  token: Scalars['String']['input']
}

export type MutationResetPasswordWithOtpArgs = {
  otp: Scalars['String']['input']
  password: Scalars['String']['input']
  pin: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationResolveErrorNotificationArgs = {
  activityId: Scalars['String']['input']
}

export type MutationReturnOrderArgs = {
  input: ReturnOrderInput
  requestContext?: InputMaybe<RequestContext>
}

export type MutationSendEmailArgs = {
  email: Scalars['String']['input']
  htmlBody: Scalars['String']['input']
  pdf: Scalars['Upload']['input']
  subject: Scalars['String']['input']
  textBody: Scalars['String']['input']
}

export type MutationSendOrderReceiptEmailArgs = {
  customerEmail?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['String']['input']
}

export type MutationSendOtpForPasswordResetArgs = {
  token: Scalars['String']['input']
}

export type MutationSendPasswordSetLinkArgs = {
  businessId: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type MutationServiceConfigArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
  input: ServiceConfigInput
}

export type MutationSignupCustomerArgs = {
  customer: CustomerInput
  token: Scalars['String']['input']
}

export type MutationToggleEmailReceiptEnabledArgs = {
  emailReceiptEnabled: Scalars['Boolean']['input']
}

export type MutationUpdateBankDepositArgs = {
  depositNumber: Scalars['String']['input']
  depositReceipt?: InputMaybe<Scalars['Upload']['input']>
  employeeId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type MutationUpdateBopisOrderArgs = {
  cartId?: InputMaybe<Scalars['String']['input']>
  fulfillmentStatus: Scalars['String']['input']
  orderId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type MutationUpdateBusinessArgs = {
  business: BusinessDetailsUpdateInput
  businessId: Scalars['String']['input']
}

export type MutationUpdateBusinessColorsArgs = {
  businessId: Scalars['String']['input']
  primary: Scalars['String']['input']
  secondary: Scalars['String']['input']
}

export type MutationUpdateCampaignArgs = {
  businessId: Scalars['String']['input']
  campaign: CampaignInput
  campaignId: Scalars['String']['input']
}

export type MutationUpdateCartArgs = {
  cartId: Scalars['String']['input']
  customerAgeVerification?: InputMaybe<AgeAttributesInput>
  customerId?: InputMaybe<Scalars['String']['input']>
  deliveryAddress?: InputMaybe<AddressInput>
  deliveryTimeSlot?: InputMaybe<DeliveryTimeSlotInput>
  items?: InputMaybe<Array<ItemInput>>
  promotionId?: InputMaybe<Scalars['String']['input']>
  returnedItems?: InputMaybe<Array<ReturnItemInput>>
  subscriptionItems?: InputMaybe<Array<SubscriptionItemInput>>
}

export type MutationUpdateCartAddressArgs = {
  address: AddressInput
  cartId: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationUpdateCartItemsArgs = {
  cartId: Scalars['String']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
  deliveryAddress?: InputMaybe<AddressInput>
  items?: InputMaybe<Array<ItemInput>>
  promotionId?: InputMaybe<Scalars['String']['input']>
  returnedItems?: InputMaybe<Array<ReturnItemInput>>
  subscriptionItems?: InputMaybe<Array<SubscriptionItemInput>>
}

export type MutationUpdateCustomerArgs = {
  customer: CustomerInput
  customerId: Scalars['String']['input']
}

export type MutationUpdateEmployeeArgs = {
  businessId: Scalars['String']['input']
  employee: EmployeeInput
  employeeId: Scalars['String']['input']
}

export type MutationUpdateInventoryArgs = {
  inventory: InventoryInput
  locationId: Scalars['String']['input']
}

export type MutationUpdateInventoryUnitCountArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>
  operationType: Scalars['String']['input']
  quantityChange: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type MutationUpdateLocationArgs = {
  location: LocationInput
  locationId: Scalars['String']['input']
}

export type MutationUpdateOrderEmailReceiptTemplateArgs = {
  orderReceipt: Scalars['String']['input']
}

export type MutationUpdatePeripheralHubNotificationConfigArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
  notificationConfiguration: PeripheralHubNotificationsInput
}

export type MutationUpdatePluginSettingsArgs = {
  pluginId: Scalars['String']['input']
  settings: PluginSettingsInput
}

export type MutationUpdatePredefinedChatQueryArgs = {
  answer?: InputMaybe<Scalars['String']['input']>
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  query: Scalars['String']['input']
  queryId: Scalars['String']['input']
  skus?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MutationUpdateRoleArgs = {
  businessId: Scalars['String']['input']
  description: Scalars['String']['input']
  id: Scalars['String']['input']
  isActive: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  permissions: Array<Scalars['String']['input']>
}

export type MutationUpdateStoreGroupArgs = {
  input: UpdateStoreGroupInput
}

export type MutationUpdateZoneArgs = {
  businessId: Scalars['String']['input']
  description: Scalars['String']['input']
  devices: Array<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  name: Scalars['String']['input']
  registers: Array<Scalars['String']['input']>
  zoneId: Scalars['String']['input']
}

export type MutationValidateCustomerSignupTokenArgs = {
  checkUsage?: InputMaybe<Scalars['Boolean']['input']>
  token: Scalars['String']['input']
}

export type MutationVerifyAdminOktaTokenAndGenerateSessionTokenArgs = {
  accessToken: Scalars['String']['input']
  idToken: Scalars['String']['input']
  oktaClientId: Scalars['String']['input']
  oktaIssuer: Scalars['String']['input']
}

export type MutationVerifyCartArgs = {
  cartId: Scalars['ID']['input']
  locationId: Scalars['ID']['input']
}

export type MutationVerifyOktaTokenAndGenerateSessionTokenArgs = {
  accessToken: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
  idToken: Scalars['String']['input']
  oktaClientId: Scalars['String']['input']
  oktaIssuer: Scalars['String']['input']
}

export type MutationVerifyOtpAndGenerateSessionTokenArgs = {
  businessId: Scalars['String']['input']
  email: Scalars['String']['input']
  otp: Scalars['String']['input']
}

export type MutationVerifyServiceConfigArgs = {
  input: ServiceConfigInput
}

export type NoteType = {
  __typename?: 'NoteType'
  createdAt: Scalars['DateTime']['output']
  customer: CustomerType
  employee: EmployeeType
  id: Scalars['String']['output']
  message: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OAuthApplicationsQueryResponseType = {
  __typename?: 'OAuthApplicationsQueryResponseType'
  applications?: Maybe<Array<OAuthApplicationsType>>
  locationId?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type OAuthApplicationsType = {
  __typename?: 'OAuthApplicationsType'
  appName?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['String']['output']>
  clientSecret?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type OktaConfigType = {
  __typename?: 'OktaConfigType'
  clientId?: Maybe<Scalars['String']['output']>
  issuer?: Maybe<Scalars['String']['output']>
}

export type OktaInput = {
  oktaClientId?: InputMaybe<Scalars['String']['input']>
  oktaIssuer?: InputMaybe<Scalars['String']['input']>
}

export type OktaType = {
  __typename?: 'OktaType'
  oktaClientId?: Maybe<Scalars['String']['output']>
  oktaIssuer?: Maybe<Scalars['String']['output']>
}

export type OperatingHourInput = {
  day?: InputMaybe<Scalars['String']['input']>
  fromHour?: InputMaybe<Scalars['String']['input']>
  toHour?: InputMaybe<Scalars['String']['input']>
}

export type OperatingHourType = {
  __typename?: 'OperatingHourType'
  day?: Maybe<Scalars['String']['output']>
  fromHour?: Maybe<Scalars['String']['output']>
  toHour?: Maybe<Scalars['String']['output']>
}

export type OrderPaymentType = {
  __typename?: 'OrderPaymentType'
  amount?: Maybe<Scalars['Int']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  orderId: Scalars['String']['output']
  paymentGateway?: Maybe<Scalars['String']['output']>
  paymentGatewayReferenceId?: Maybe<Scalars['String']['output']>
  paymentMethod?: Maybe<Scalars['String']['output']>
  paymentMethodDetails?: Maybe<PaymentMethodDetailsType>
  status: Scalars['String']['output']
}

export type OrderRefundSplitType = {
  __typename?: 'OrderRefundSplitType'
  error?: Maybe<Scalars['String']['output']>
  orderId?: Maybe<Scalars['String']['output']>
  refundSplit?: Maybe<Array<RefundSplitType>>
  refundStrategy?: Maybe<Scalars['String']['output']>
  totalRefundAmount?: Maybe<Scalars['Int']['output']>
}

export type OrderRefundType = {
  __typename?: 'OrderRefundType'
  amount: Scalars['Int']['output']
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  orderId: Scalars['String']['output']
  paymentGateway?: Maybe<Scalars['String']['output']>
  paymentGatewayReferenceId?: Maybe<Scalars['String']['output']>
  paymentGatewayRefundReferenceId?: Maybe<Scalars['String']['output']>
  referencePaymentId?: Maybe<Scalars['String']['output']>
  refundMethod: Scalars['String']['output']
  status: Scalars['String']['output']
}

export type OrderType = {
  __typename?: 'OrderType'
  amountPaid?: Maybe<Scalars['Int']['output']>
  amountRefunded?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  currency: Scalars['String']['output']
  customer?: Maybe<CustomerType>
  employee?: Maybe<EmployeeType>
  fulfillmentDetails?: Maybe<FulfillmentDetails>
  fulfillmentStatus?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isBopisOrder?: Maybe<Scalars['Boolean']['output']>
  items?: Maybe<Array<ItemType>>
  location?: Maybe<LocationType>
  omsOrder?: Maybe<Scalars['JSON']['output']>
  omsOrderId?: Maybe<Scalars['String']['output']>
  omsProvider?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  orderType?: Maybe<Scalars['String']['output']>
  paymentStatus?: Maybe<Scalars['String']['output']>
  payments?: Maybe<Array<OrderPaymentType>>
  refundStatus?: Maybe<Scalars['String']['output']>
  refunds?: Maybe<Array<OrderRefundType>>
  register?: Maybe<Scalars['String']['output']>
  remainingItems?: Maybe<Array<ItemType>>
  returnedItems?: Maybe<Array<ReturnItemType>>
  subscriptionItems?: Maybe<Array<SubscriptionItemType>>
  totalAmount: Scalars['Int']['output']
}

export type OrderUpdateEventType = {
  __typename?: 'OrderUpdateEventType'
  eventType: Scalars['String']['output']
  order?: Maybe<OrderType>
}

export type OrdersType = {
  __typename?: 'OrdersType'
  hasNextPage: Scalars['Boolean']['output']
  orders: Array<OrderType>
  totalPageCount: Scalars['Int']['output']
}

export type PaginatedBankDepositType = {
  __typename?: 'PaginatedBankDepositType'
  nodes: Array<BankDepositType>
  totalPageCount: Scalars['Int']['output']
}

export type PaginatedCashTransactionType = {
  __typename?: 'PaginatedCashTransactionType'
  cashBalance?: Maybe<Scalars['Float']['output']>
  nodes: Array<CashTransactionType>
  totalPageCount: Scalars['Int']['output']
}

export type PaginatedDeviceType = {
  __typename?: 'PaginatedDeviceType'
  nodes: Array<DevicesType>
  totalCount: Scalars['Int']['output']
}

export type PaymentConfigsType = {
  __typename?: 'PaymentConfigsType'
  paymentGateway?: Maybe<Scalars['String']['output']>
  paymentMethods?: Maybe<Array<Scalars['String']['output']>>
}

export type PaymentMethodDetailsType = {
  __typename?: 'PaymentMethodDetailsType'
  card?: Maybe<CardType>
  type?: Maybe<Scalars['String']['output']>
}

export type PeripheralHubNotificationMethodsInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>
  text?: InputMaybe<Scalars['Boolean']['input']>
}

export type PeripheralHubNotificationMethodsType = {
  __typename?: 'PeripheralHubNotificationMethodsType'
  email?: Maybe<Scalars['Boolean']['output']>
  text?: Maybe<Scalars['Boolean']['output']>
}

export type PeripheralHubNotificationsInput = {
  alert?: InputMaybe<Scalars['String']['input']>
  device?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  issue?: InputMaybe<Scalars['String']['input']>
  methods: PeripheralHubNotificationMethodsInput
  severity?: InputMaybe<Scalars['String']['input']>
}

export type PeripheralHubNotificationsType = {
  __typename?: 'PeripheralHubNotificationsType'
  alert?: Maybe<Scalars['String']['output']>
  device?: Maybe<Scalars['String']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  issue?: Maybe<Scalars['String']['output']>
  methods: PeripheralHubNotificationMethodsType
  severity?: Maybe<Scalars['String']['output']>
}

export type PermissionDataType = {
  __typename?: 'PermissionDataType'
  category?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type PhoneNumberInput = {
  contactPhone?: InputMaybe<Scalars['String']['input']>
  supportPhone?: InputMaybe<Scalars['String']['input']>
}

export type PhoneNumberType = {
  __typename?: 'PhoneNumberType'
  contactPhone?: Maybe<Scalars['String']['output']>
  supportPhone?: Maybe<Scalars['String']['output']>
}

export type PlaceOrderInput = {
  amount: Scalars['Int']['input']
  cartId: Scalars['String']['input']
  currency: Scalars['String']['input']
  nonce: Scalars['String']['input']
  paymentGateway?: InputMaybe<Scalars['String']['input']>
  paymentGatewayReferenceId?: InputMaybe<Scalars['String']['input']>
  paymentMethod: Scalars['String']['input']
}

export type PluginSettingsInput = {
  configurations: Array<ConfigurationsInput>
  name: Scalars['String']['input']
  pluginId: Scalars['String']['input']
  status: Scalars['Boolean']['input']
}

export type PluginSettingsType = {
  __typename?: 'PluginSettingsType'
  configurations: Array<ConfigurationsType>
  name: Scalars['String']['output']
  pluginId: Scalars['String']['output']
  status: Scalars['Boolean']['output']
}

export type PredefinedQueryType = {
  __typename?: 'PredefinedQueryType'
  answer?: Maybe<Scalars['String']['output']>
  business: BusinessType
  location: LocationType
  products?: Maybe<Array<ProductType>>
  query: Scalars['String']['output']
  queryId: Scalars['String']['output']
}

export type PreferenceInput = {
  key?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Array<Scalars['String']['input']>>
}

export type PreferenceType = {
  __typename?: 'PreferenceType'
  key?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  value?: Maybe<Array<Scalars['String']['output']>>
}

export type ProductList = {
  __typename?: 'ProductList'
  page: Scalars['Int']['output']
  products: Array<ProductType>
}

export type ProductType = {
  __typename?: 'ProductType'
  ageVerificationRequired?: Maybe<Scalars['Boolean']['output']>
  aisleLocation?: Maybe<Scalars['String']['output']>
  aisleSubzone?: Maybe<Scalars['String']['output']>
  aisleZone?: Maybe<Scalars['String']['output']>
  business?: Maybe<BusinessType>
  currencyMultiplier?: Maybe<Scalars['Float']['output']>
  description?: Maybe<Scalars['String']['output']>
  discount?: Maybe<Scalars['Int']['output']>
  frequency?: Maybe<Scalars['String']['output']>
  held?: Maybe<Scalars['Int']['output']>
  incoming?: Maybe<Scalars['Int']['output']>
  isReturnable?: Maybe<Scalars['Boolean']['output']>
  itemConditionOptions?: Maybe<Array<Scalars['String']['output']>>
  location?: Maybe<LocationType>
  logoUrl?: Maybe<Scalars['String']['output']>
  promotions?: Maybe<Array<PromotionType>>
  relatedScore?: Maybe<Scalars['Float']['output']>
  requiredMinimumAge?: Maybe<Scalars['Int']['output']>
  returnReasonOptions?: Maybe<Array<Scalars['String']['output']>>
  sendEmail?: Maybe<Scalars['Boolean']['output']>
  sku: Scalars['String']['output']
  skuType?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['Int']['output']>
  taxGroup?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Float']['output']>
  total?: Maybe<Scalars['Int']['output']>
  unit?: Maybe<Scalars['String']['output']>
  unitCount?: Maybe<Scalars['Int']['output']>
  unitDiscount?: Maybe<Scalars['Int']['output']>
  unitPrice?: Maybe<Scalars['Int']['output']>
  unitSubTotal?: Maybe<Scalars['Int']['output']>
  unitTaxAmount?: Maybe<Scalars['Int']['output']>
  upc?: Maybe<Scalars['String']['output']>
}

export type PromoRuleType = {
  __typename?: 'PromoRuleType'
  applyTo?: Maybe<Array<Scalars['String']['output']>>
  applyToProductGroup?: Maybe<Array<Scalars['String']['output']>>
  businessId: Scalars['String']['output']
  excludeFrom?: Maybe<Array<Scalars['String']['output']>>
  excludeFromProductGroup?: Maybe<Array<Scalars['String']['output']>>
  ruleName: Scalars['String']['output']
  ruleType: Scalars['String']['output']
  ruleValue?: Maybe<Scalars['String']['output']>
}

export type PromotionInput = {
  active: Scalars['Boolean']['input']
  businessId: Scalars['String']['input']
  canBeCombined: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  endDate: Scalars['DateTime']['input']
  priority: Scalars['Int']['input']
  promotionName: Scalars['String']['input']
  promotionType: Scalars['String']['input']
  promotionValue: Scalars['Int']['input']
  rules?: InputMaybe<Array<Scalars['String']['input']>>
  startDate: Scalars['DateTime']['input']
  subscriptionType?: InputMaybe<Scalars['Boolean']['input']>
}

export type PromotionType = {
  __typename?: 'PromotionType'
  active: Scalars['Boolean']['output']
  businessId: Scalars['String']['output']
  canBeCombined: Scalars['Boolean']['output']
  description?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  priority: Scalars['Int']['output']
  promotionName: Scalars['String']['output']
  promotionSchema: Scalars['String']['output']
  promotionType: Scalars['String']['output']
  promotionValue: Scalars['Int']['output']
  rules?: Maybe<Array<PromoRuleType>>
  skus?: Maybe<Array<Scalars['String']['output']>>
  startDate: Scalars['DateTime']['output']
  subscriptionType: Scalars['Boolean']['output']
}

export type Query = {
  __typename?: 'Query'
  activityLogs?: Maybe<Scalars['Void']['output']>
  aggregrateCashTransactions: CashTransactionAggregrationType
  allRules: Array<PromoRuleType>
  applyPromotion: SampleItem
  applyPromotions: Scalars['Int']['output']
  business: BusinessType
  businessInternal: Array<BusinessType>
  cart: CartType
  cartGuest: CartType
  cashTransactionFailures: TransactionFailureType
  cashTransactions: PaginatedCashTransactionType
  childStoreGroups: Array<StoreGroupType>
  customer: CustomerType
  customerLookup: CustomerType
  customerLoyalty: CustomerLoyaltyType
  deviceHubVersionMetadata: DeviceHubVersionInfoType
  devices: PaginatedDeviceType
  discoverHubComponents: DiscoveryHubComponentQueryResponseType
  fetchCustomer: CustomerType
  fetchDevices: Array<Device>
  fetchGenieMessage: GenieQueryType
  fetchGenieMessageConsumerAgent: GenieQueryType
  fetchGenieMessageDashboard: GenieQueryType
  fetchLocationInventory: InventoryListType
  fetchLocationsBySegments: Array<LocationType>
  fetchRegisterList: Array<RegisterType>
  fetchRegisters: Array<RegisterExtended>
  fetchSegmentsSuggestions: Array<SegmentType>
  fetchTills: Array<TillType>
  fetchZones: Array<ZoneExtended>
  genieQuery: GenieQueryType
  genieQueryConsumerAgent: GenieQueryType
  genieQueryDashboard: GenieQueryType
  getActivityLogs: Array<CustomerActivityLogType>
  getAdminUser: AdminUserType
  getAllEmployees: EmployeeListType
  getAllLocations: Array<LocationType>
  getAllNotes: Array<NoteType>
  getAllPermissions: Array<PermissionDataType>
  getAllPluginSettings: Array<PluginSettingsType>
  getAllPromotions: Array<PromotionType>
  getAllRoles: Array<RoleType>
  getAuthMethod: Array<AuthMethodType>
  getBankDeposits: PaginatedBankDepositType
  getBusiness: BusinessType
  getBusinesses: Array<BusinessDetailsType>
  getDeviceActivities: Array<DeviceActivityType>
  getDeviceData: Array<DeviceDataResponseType>
  getDeviceInfo: DeviceInfoType
  getDevices: DevicesDiscoveryQueryResponseType
  getEmployee: EmployeeType
  getErrorNotifications: Array<ErrorNotificationType>
  getInsightCharts: InsightChartsType
  getInsights: Array<InsightType>
  getInventoryByCommand: GenieProductListType
  getInventoryBySkuAndDiscount: ProductType
  getLocationByStoreNumber: LocationType
  getOauthApplications: OAuthApplicationsQueryResponseType
  getOktaConfigFromIssuer: Array<AuthMethodType>
  getPeripheralHubNotificationConfig: PeripheralHubNotificationsType
  getPluginSettings: PluginSettingsType
  getProductGroupList: Array<Scalars['String']['output']>
  getProductSkuList: Array<Scalars['String']['output']>
  getPromotionSchemaTypes: Array<Scalars['String']['output']>
  getPromotionTypes: Array<Scalars['String']['output']>
  getRegisters: RegistersDiscoveryQueryResponseType
  getRule: PromoRuleType
  getRuleTypes: Array<Scalars['String']['output']>
  getSignupCampaign?: Maybe<CampaignType>
  getTill: TillOutputType
  getTillStatus: Scalars['String']['output']
  getTotalsForPromotions: SampleItem
  getZones: ZonesDiscoveryQueryResponseType
  listCustomers: Array<CustomerType>
  listOrders: OrdersType
  listProduct: Array<ProductType>
  listSecrets: Array<SecretType>
  location: LocationType
  locationInternal: Array<LocationType>
  meta: LocationType
  order: OrderType
  orderProductRecommendation?: Maybe<Scalars['Void']['output']>
  orderRefundSplit: OrderRefundSplitType
  orders: OrdersType
  predefinedChatQueryConsumerAgent: PredefinedQueryType
  product: ProductType
  productCountsBySku: ProductList
  promotionsInternal: Array<PromotionType>
  serviceConfig: ServiceConfigType
  serviceConfigProviders: Array<ServiceConfigProvidersType>
  serviceConfigsByCategory: Array<ServiceConfigType>
  storeGroupClusterStats: Array<StoreGroupClusterStatsType>
  storeGroups: Array<Array<StoreGroupType>>
  storeGroupsByClusterId: Array<StoreGroupType>
  testClientValidation: Scalars['String']['output']
  tillTransactions: TillTransactionResponseType
  tillTransactionsSummary: TransactionSummaryType
  uploadFile: FileUploadResponse
  validateEmployeeInput: EmployeeValidatorOutput
  validatePasswordSetToken?: Maybe<Scalars['Void']['output']>
}

export type QueryActivityLogsArgs = {
  cartId: Scalars['ID']['input']
  customerId: Scalars['String']['input']
}

export type QueryAggregrateCashTransactionsArgs = {
  employeeCodes?: InputMaybe<Array<Scalars['String']['input']>>
  eventType?: InputMaybe<Array<Scalars['String']['input']>>
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  storeNumbers?: InputMaybe<Array<Scalars['String']['input']>>
  transactionTimeRange?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryAllRulesArgs = {
  businessId: Scalars['String']['input']
}

export type QueryApplyPromotionArgs = {
  businessId: Scalars['String']['input']
  promotionName: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type QueryApplyPromotionsArgs = {
  businessId: Scalars['String']['input']
  cartId: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type QueryBusinessArgs = {
  requestContext?: InputMaybe<RequestContext>
}

export type QueryBusinessInternalArgs = {
  businessIds?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCartArgs = {
  cartId: Scalars['ID']['input']
}

export type QueryCartGuestArgs = {
  cartId: Scalars['String']['input']
}

export type QueryCashTransactionFailuresArgs = {
  dateRange: Array<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  pageIndex?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
}

export type QueryCashTransactionsArgs = {
  dateRange: Array<Scalars['String']['input']>
  employeeCode?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  pageIndex?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
  transactionType?: InputMaybe<Scalars['String']['input']>
}

export type QueryChildStoreGroupsArgs = {
  storeGroupId: Scalars['String']['input']
}

export type QueryCustomerArgs = {
  barcode: Scalars['ID']['input']
}

export type QueryCustomerLookupArgs = {
  query: Scalars['String']['input']
}

export type QueryCustomerLoyaltyArgs = {
  businessId: Scalars['String']['input']
  customerId: Scalars['ID']['input']
  locationId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type QueryDeviceHubVersionMetadataArgs = {
  businessId: Scalars['String']['input']
  generateUrl: Scalars['Boolean']['input']
  locationId: Scalars['String']['input']
}

export type QueryDevicesArgs = {
  machine?: InputMaybe<Scalars['String']['input']>
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
}

export type QueryDiscoverHubComponentsArgs = {
  locationId: Scalars['String']['input']
}

export type QueryFetchDevicesArgs = {
  businessId: Scalars['String']['input']
  deviceId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
}

export type QueryFetchGenieMessageArgs = {
  messageId: Scalars['String']['input']
}

export type QueryFetchGenieMessageConsumerAgentArgs = {
  messageId: Scalars['String']['input']
}

export type QueryFetchGenieMessageDashboardArgs = {
  messageId: Scalars['String']['input']
}

export type QueryFetchLocationInventoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: Scalars['Int']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  unitCount?: InputMaybe<Scalars['String']['input']>
  unitPrice?: InputMaybe<Scalars['String']['input']>
}

export type QueryFetchLocationsBySegmentsArgs = {
  page?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
  segments: Scalars['String']['input']
}

export type QueryFetchRegisterListArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type QueryFetchRegistersArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  registerId?: InputMaybe<Scalars['String']['input']>
}

export type QueryFetchTillsArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type QueryFetchZonesArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  zoneId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGenieQueryArgs = {
  chatSessionId: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
  query: Scalars['String']['input']
  queryType?: InputMaybe<Scalars['String']['input']>
}

export type QueryGenieQueryConsumerAgentArgs = {
  chatSessionId: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
  query: Scalars['String']['input']
  queryType?: InputMaybe<Scalars['String']['input']>
}

export type QueryGenieQueryDashboardArgs = {
  chatSessionId: Scalars['String']['input']
  query: Scalars['String']['input']
  queryType?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetActivityLogsArgs = {
  cartId: Scalars['ID']['input']
  customerId: Scalars['String']['input']
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetAdminUserArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetAllEmployeesArgs = {
  businessId: Scalars['String']['input']
  email?: InputMaybe<Scalars['String']['input']>
  employeeCode?: InputMaybe<Scalars['String']['input']>
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  storeGroupIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryGetAllLocationsArgs = {
  businessId: Scalars['String']['input']
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  storeGroupIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryGetAllNotesArgs = {
  businessId: Scalars['String']['input']
  customerId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type QueryGetAllPromotionsArgs = {
  businessId: Scalars['String']['input']
}

export type QueryGetAllRolesArgs = {
  businessId: Scalars['String']['input']
}

export type QueryGetAuthMethodArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  emailId: Scalars['String']['input']
  token?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetBankDepositsArgs = {
  dateRange: Array<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  pageIndex?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
}

export type QueryGetBusinessArgs = {
  businessId: Scalars['String']['input']
}

export type QueryGetDeviceActivitiesArgs = {
  deviceId: Scalars['String']['input']
}

export type QueryGetDeviceDataArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type QueryGetDeviceInfoArgs = {
  deviceId: Scalars['String']['input']
}

export type QueryGetDevicesArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>
  deviceType?: InputMaybe<Scalars['String']['input']>
  registerId?: InputMaybe<Scalars['String']['input']>
  zoneId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetEmployeeArgs = {
  employeeId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetErrorNotificationsArgs = {
  businessId: Scalars['String']['input']
  live?: InputMaybe<Scalars['Boolean']['input']>
  locationId: Scalars['String']['input']
}

export type QueryGetInsightChartsArgs = {
  chartCategory: Scalars['String']['input']
  duration?: InputMaybe<Scalars['String']['input']>
  durationType: Scalars['String']['input']
  employeeCodes?: InputMaybe<Scalars['String']['input']>
  eventTypes?: InputMaybe<Scalars['String']['input']>
  insightType: Scalars['String']['input']
  locationIds?: InputMaybe<Scalars['String']['input']>
  storeNumbers?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetInsightsArgs = {
  duration?: InputMaybe<Scalars['String']['input']>
  durationType: Scalars['String']['input']
  employeeCodes?: InputMaybe<Scalars['String']['input']>
  eventTypes?: InputMaybe<Scalars['String']['input']>
  insightTypes: Scalars['String']['input']
  locationIds?: InputMaybe<Scalars['String']['input']>
  storeNumbers?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetInventoryByCommandArgs = {
  chatSessionId?: InputMaybe<Scalars['String']['input']>
  command: Scalars['String']['input']
  genieVersion?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
}

export type QueryGetInventoryBySkuAndDiscountArgs = {
  sku: Scalars['String']['input']
}

export type QueryGetLocationByStoreNumberArgs = {
  businessId: Scalars['String']['input']
  storeNumber: Scalars['String']['input']
}

export type QueryGetOauthApplicationsArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetOktaConfigFromIssuerArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  issuer: Scalars['String']['input']
}

export type QueryGetPeripheralHubNotificationConfigArgs = {
  employeeId: Scalars['String']['input']
}

export type QueryGetPluginSettingsArgs = {
  pluginId: Scalars['String']['input']
}

export type QueryGetRegistersArgs = {
  locationId: Scalars['String']['input']
  registerId?: InputMaybe<Scalars['String']['input']>
  registerName?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetRuleArgs = {
  businessId: Scalars['String']['input']
  ruleName: Scalars['String']['input']
}

export type QueryGetSignupCampaignArgs = {
  businessId: Scalars['String']['input']
}

export type QueryGetTillArgs = {
  tillFilter: TillFilterInputType
}

export type QueryGetTillStatusArgs = {
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type QueryGetTotalsForPromotionsArgs = {
  businessId: Scalars['String']['input']
  promotionNames: Array<Scalars['String']['input']>
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type QueryGetZonesArgs = {
  locationId: Scalars['String']['input']
  zoneId?: InputMaybe<Scalars['String']['input']>
  zoneName?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCustomersArgs = {
  businessId: Scalars['String']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
}

export type QueryListOrdersArgs = {
  businessId: Scalars['String']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
  employeeId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  pageIndex?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
  requestContext?: InputMaybe<RequestContext>
  storeGroups?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryListProductArgs = {
  cartId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  searchStr: Scalars['String']['input']
}

export type QueryLocationArgs = {
  requestContext?: InputMaybe<RequestContext>
}

export type QueryLocationInternalArgs = {
  businessIds?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOrderArgs = {
  orderId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type QueryOrderProductRecommendationArgs = {
  cartId: Scalars['ID']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
}

export type QueryOrderRefundSplitArgs = {
  orderId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
  totalRefundAmount: Scalars['Int']['input']
}

export type QueryOrdersArgs = {
  fulfillmentStatus?: InputMaybe<Scalars['String']['input']>
  isBopisOrder?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  requestContext?: InputMaybe<RequestContext>
  sortOrder?: InputMaybe<Scalars['String']['input']>
}

export type QueryPredefinedChatQueryConsumerAgentArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
  queryId: Scalars['String']['input']
}

export type QueryProductArgs = {
  cartId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  sku: Scalars['ID']['input']
}

export type QueryProductCountsBySkuArgs = {
  page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPromotionsInternalArgs = {
  businessId: Scalars['String']['input']
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>
  skus?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryServiceConfigArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  serviceConfigType: Scalars['String']['input']
}

export type QueryServiceConfigProvidersArgs = {
  category: Scalars['String']['input']
}

export type QueryServiceConfigsByCategoryArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  category: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type QueryStoreGroupsByClusterIdArgs = {
  fetchOrphanedStoreGroups?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  storeGroupClusterId: Scalars['String']['input']
}

export type QueryTillTransactionsArgs = {
  date: Scalars['String']['input']
  locationId: Scalars['String']['input']
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
}

export type QueryTillTransactionsSummaryArgs = {
  date: Scalars['String']['input']
  locationId: Scalars['String']['input']
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
}

export type QueryUploadFileArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>
}

export type QueryValidateEmployeeInputArgs = {
  employee: EmployeeInput
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type QueryValidatePasswordSetTokenArgs = {
  token: Scalars['String']['input']
}

export type RecommendationResponse = {
  __typename?: 'RecommendationResponse'
  cartId?: Maybe<Scalars['String']['output']>
  eventType?: Maybe<Scalars['String']['output']>
  payload?: Maybe<SubscriptionPayload>
}

export type RefundSplitType = {
  __typename?: 'RefundSplitType'
  amountAvailable?: Maybe<Scalars['Int']['output']>
  amountRefunded?: Maybe<Scalars['Int']['output']>
  currency?: Maybe<Scalars['String']['output']>
  currencyMultiplier?: Maybe<Scalars['Int']['output']>
  paymentGateway?: Maybe<Scalars['String']['output']>
  paymentGatewayReferenceId?: Maybe<Scalars['String']['output']>
  paymentMethodDetails?: Maybe<PaymentMethodDetailsType>
  recommendedRefundAmount?: Maybe<Scalars['Int']['output']>
  referencePaymentId?: Maybe<Scalars['String']['output']>
  refundMethod?: Maybe<Scalars['String']['output']>
  totalAmount?: Maybe<Scalars['Int']['output']>
}

export type Register = {
  __typename?: 'Register'
  id: Scalars['String']['output']
  ips: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type RegisterExtended = {
  __typename?: 'RegisterExtended'
  devices: Array<Device>
  id: Scalars['String']['output']
  ips: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  zone?: Maybe<Zone>
}

export type RegisterTillBlockerType = {
  __typename?: 'RegisterTillBlockerType'
  code: Scalars['String']['output']
  data: Scalars['JSON']['output']
  description: Scalars['String']['output']
}

export type RegisterTillType = {
  __typename?: 'RegisterTillType'
  blockers?: Maybe<Array<RegisterTillBlockerType>>
  currentAmount?: Maybe<Scalars['Int']['output']>
  isBlocked?: Maybe<Scalars['Boolean']['output']>
  register?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  storeNumber?: Maybe<Scalars['String']['output']>
  till?: Maybe<Scalars['String']['output']>
  warnings?: Maybe<Array<RegisterTillWarningType>>
}

export type RegisterTillWarningType = {
  __typename?: 'RegisterTillWarningType'
  code: Scalars['String']['output']
  data: Scalars['JSON']['output']
  description: Scalars['String']['output']
}

export type RegisterType = {
  __typename?: 'RegisterType'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type RegistersDiscoveryQueryResponseType = {
  __typename?: 'RegistersDiscoveryQueryResponseType'
  locationId?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  registers?: Maybe<Array<RegistersDiscoveryType>>
  success: Scalars['Boolean']['output']
}

export type RegistersDiscoveryType = {
  __typename?: 'RegistersDiscoveryType'
  deviceIds?: Maybe<Array<Scalars['String']['output']>>
  registerId?: Maybe<Scalars['String']['output']>
  registerIps?: Maybe<Array<Scalars['String']['output']>>
  registerName?: Maybe<Scalars['String']['output']>
  zoneId?: Maybe<Scalars['String']['output']>
}

export type RequestContext = {
  businessId?: InputMaybe<Scalars['String']['input']>
  employeeId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type ReturnItemInput = {
  ageVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>
  aisleLocation?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  isDeliveryItem?: InputMaybe<Scalars['Boolean']['input']>
  isReturnable?: InputMaybe<Scalars['Boolean']['input']>
  itemCondition?: InputMaybe<Scalars['String']['input']>
  itemConditionOptions?: InputMaybe<Array<Scalars['String']['input']>>
  itemType?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['String']['input']
  originalItemSku?: InputMaybe<Scalars['String']['input']>
  promotions?: InputMaybe<Array<PromotionInput>>
  quantity?: InputMaybe<Scalars['Int']['input']>
  quantityRefunded?: InputMaybe<Scalars['Int']['input']>
  quantitySubstituted?: InputMaybe<Scalars['Int']['input']>
  referenceProductId?: InputMaybe<Scalars['String']['input']>
  refundReason?: InputMaybe<Scalars['String']['input']>
  requiredMinimumAge?: InputMaybe<Scalars['Int']['input']>
  returnReason?: InputMaybe<Scalars['String']['input']>
  returnReasonOptions?: InputMaybe<Array<Scalars['String']['input']>>
  sku: Scalars['String']['input']
  skuType?: InputMaybe<Scalars['String']['input']>
  sortPromotionsPriority?: InputMaybe<Scalars['Boolean']['input']>
  substitutionReason?: InputMaybe<Scalars['String']['input']>
  taxAmount?: InputMaybe<Scalars['Int']['input']>
  taxGroup?: InputMaybe<Scalars['String']['input']>
  taxRate?: InputMaybe<Scalars['Decimal']['input']>
  total?: InputMaybe<Scalars['Int']['input']>
  totalDiscount?: InputMaybe<Scalars['Int']['input']>
  totalTaxAmount?: InputMaybe<Scalars['Int']['input']>
  unit?: InputMaybe<Scalars['String']['input']>
  unitDiscount?: InputMaybe<Scalars['Int']['input']>
  unitPrice: Scalars['Int']['input']
  unitSubTotal?: InputMaybe<Scalars['Int']['input']>
  unitTaxAmount?: InputMaybe<Scalars['Int']['input']>
  upc?: InputMaybe<Scalars['String']['input']>
}

export type ReturnItemType = {
  __typename?: 'ReturnItemType'
  ageVerificationRequired?: Maybe<Scalars['Boolean']['output']>
  aisleLocation?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  discounts?: Maybe<Array<DiscountType>>
  isDeliveryItem?: Maybe<Scalars['Boolean']['output']>
  isReturnable?: Maybe<Scalars['Boolean']['output']>
  itemCondition?: Maybe<Scalars['String']['output']>
  itemConditionOptions?: Maybe<Array<Scalars['String']['output']>>
  itemType?: Maybe<Scalars['String']['output']>
  location?: Maybe<LocationType>
  logoUrl?: Maybe<Scalars['String']['output']>
  orderId: Scalars['String']['output']
  originalItemSku?: Maybe<Scalars['String']['output']>
  promotions?: Maybe<Array<PromotionType>>
  promotionsApplied?: Maybe<Array<PromotionType>>
  quantity: Scalars['Int']['output']
  quantityRefunded?: Maybe<Scalars['Int']['output']>
  quantitySubstituted?: Maybe<Scalars['Int']['output']>
  referenceProductId?: Maybe<Scalars['String']['output']>
  refundReason?: Maybe<Scalars['String']['output']>
  requiredMinimumAge?: Maybe<Scalars['Int']['output']>
  returnReason?: Maybe<Scalars['String']['output']>
  returnReasonOptions?: Maybe<Array<Scalars['String']['output']>>
  sku: Scalars['String']['output']
  skuType?: Maybe<Scalars['String']['output']>
  subTotal?: Maybe<Scalars['Int']['output']>
  substitutionReason?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['Int']['output']>
  taxGroup?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Float']['output']>
  total?: Maybe<Scalars['Int']['output']>
  totalDiscount?: Maybe<Scalars['Int']['output']>
  totalTaxAmount?: Maybe<Scalars['Int']['output']>
  unit?: Maybe<Scalars['String']['output']>
  unitDiscount?: Maybe<Scalars['Int']['output']>
  unitPrice?: Maybe<Scalars['Int']['output']>
  unitSubTotal?: Maybe<Scalars['Int']['output']>
  unitTaxAmount?: Maybe<Scalars['Int']['output']>
  upc?: Maybe<Scalars['String']['output']>
}

export type ReturnOrderInput = {
  amount: Scalars['Int']['input']
  cartId: Scalars['String']['input']
  currency?: InputMaybe<Scalars['String']['input']>
  nonce: Scalars['String']['input']
  orderId: Scalars['String']['input']
  paymentGateway?: InputMaybe<Scalars['String']['input']>
  paymentGatewayReferenceId?: InputMaybe<Scalars['String']['input']>
  referencePaymentId?: InputMaybe<Scalars['String']['input']>
  refundMethod: Scalars['String']['input']
}

export type RoleType = {
  __typename?: 'RoleType'
  createdAt: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  permissions: Array<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
}

export type SampleItem = {
  __typename?: 'SampleItem'
  promotionAmount: Scalars['Int']['output']
  totalAmount: Scalars['Int']['output']
}

export type SecretType = {
  __typename?: 'SecretType'
  secretName: Scalars['String']['output']
  secretValue?: Maybe<Scalars['String']['output']>
}

export type SegmentType = {
  __typename?: 'SegmentType'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ServiceConfigInput = {
  businessId?: InputMaybe<Scalars['String']['input']>
  category: Scalars['String']['input']
  configs?: InputMaybe<Scalars['JSON']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type ServiceConfigProviderMetadataType = {
  __typename?: 'ServiceConfigProviderMetadataType'
  shopifyMetadata?: Maybe<ShopifyMetadataType>
}

export type ServiceConfigProvidersType = {
  __typename?: 'ServiceConfigProvidersType'
  metadata?: Maybe<ServiceConfigProviderMetadataType>
  provider: Scalars['String']['output']
}

export type ServiceConfigType = {
  __typename?: 'ServiceConfigType'
  businessId?: Maybe<Scalars['String']['output']>
  category?: Maybe<Scalars['String']['output']>
  configs?: Maybe<Scalars['JSON']['output']>
  locationId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ShopifyMetadataType = {
  __typename?: 'ShopifyMetadataType'
  availableApiVersions?: Maybe<Array<Scalars['String']['output']>>
  webhookTopicsToSubscribe?: Maybe<Array<Scalars['String']['output']>>
}

export type StoreGroupClusterInput = {
  hierarchyLevel: Scalars['Int']['input']
  id?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type StoreGroupClusterStatsType = {
  __typename?: 'StoreGroupClusterStatsType'
  activeCount?: Maybe<Scalars['Int']['output']>
  draftCount?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['String']['output']>
  storeGroupClusterName: Scalars['String']['output']
}

export type StoreGroupClusterType = {
  __typename?: 'StoreGroupClusterType'
  hierarchyLevel: Scalars['Int']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type StoreGroupType = {
  __typename?: 'StoreGroupType'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  parentStoreGroup?: Maybe<StoreGroupType>
  status: Scalars['String']['output']
  storeGroupCluster: StoreGroupClusterType
}

export type StoreTransactionType = {
  __typename?: 'StoreTransactionType'
  amount: Scalars['Int']['output']
  dateCreated?: Maybe<Scalars['String']['output']>
  dateModified?: Maybe<Scalars['String']['output']>
  eventType: Scalars['String']['output']
  id: Scalars['ID']['output']
  location?: Maybe<LocationType>
  reasonCode?: Maybe<Scalars['String']['output']>
}

export type StripeLocationConfigInput = {
  displayName: Scalars['String']['input']
  stripeTerminalLocationId?: InputMaybe<Scalars['String']['input']>
}

export type StripeLocationConfigType = {
  __typename?: 'StripeLocationConfigType'
  displayName?: Maybe<Scalars['String']['output']>
  stripeTerminalLocationId?: Maybe<Scalars['String']['output']>
}

export type Subscription = {
  __typename?: 'Subscription'
  activityEvents: ActivityLogType
  apiEvents: RecommendationResponse
  bopisOrderEvents: BopisOrderEventType
  customerCartSubscriptionEvents: ValidateCustomerSignupTokenType
  genieQueryEvent: GenieQueryType
  genieQueryEventConsumerAgent: GenieQueryType
  genieQueryEventDashboard: GenieQueryType
  orderUpdateEvents: OrderUpdateEventType
  orderUpdateEventsConsumerAgent: OrderUpdateEventType
  orderUpdateEventsDashboard: OrderUpdateEventType
  webCashEvent: CashEventResponse
}

export type SubscriptionActivityEventsArgs = {
  cartId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type SubscriptionApiEventsArgs = {
  cartId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}

export type SubscriptionBopisOrderEventsArgs = {
  requestContext?: InputMaybe<RequestContext>
}

export type SubscriptionCustomerCartSubscriptionEventsArgs = {
  token: Scalars['String']['input']
}

export type SubscriptionGenieQueryEventArgs = {
  chatSessionId?: InputMaybe<Scalars['String']['input']>
  lastReadTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionGenieQueryEventConsumerAgentArgs = {
  chatSessionId?: InputMaybe<Scalars['String']['input']>
  lastReadTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionGenieQueryEventDashboardArgs = {
  chatSessionId?: InputMaybe<Scalars['String']['input']>
  lastReadTimestamp?: InputMaybe<Scalars['DateTime']['input']>
}

export type SubscriptionOrderUpdateEventsArgs = {
  cartId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type SubscriptionOrderUpdateEventsConsumerAgentArgs = {
  cartId: Scalars['String']['input']
  requestContext?: InputMaybe<RequestContext>
}

export type SubscriptionWebCashEventArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['String']['input']
}

export type SubscriptionItemInput = {
  ageVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>
  aisleLocation?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  frequency: Scalars['String']['input']
  isDeliveryItem?: InputMaybe<Scalars['Boolean']['input']>
  isReturnable?: InputMaybe<Scalars['Boolean']['input']>
  itemConditionOptions?: InputMaybe<Array<Scalars['String']['input']>>
  itemType?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  originalItemSku?: InputMaybe<Scalars['String']['input']>
  promotions?: InputMaybe<Array<PromotionInput>>
  quantity?: InputMaybe<Scalars['Int']['input']>
  quantityRefunded?: InputMaybe<Scalars['Int']['input']>
  quantitySubstituted?: InputMaybe<Scalars['Int']['input']>
  referenceProductId?: InputMaybe<Scalars['String']['input']>
  refundReason?: InputMaybe<Scalars['String']['input']>
  requiredMinimumAge?: InputMaybe<Scalars['Int']['input']>
  returnReasonOptions?: InputMaybe<Array<Scalars['String']['input']>>
  sku: Scalars['String']['input']
  skuType?: InputMaybe<Scalars['String']['input']>
  sortPromotionsPriority?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  substitutionReason?: InputMaybe<Scalars['String']['input']>
  taxAmount?: InputMaybe<Scalars['Int']['input']>
  taxGroup?: InputMaybe<Scalars['String']['input']>
  taxRate?: InputMaybe<Scalars['Decimal']['input']>
  total?: InputMaybe<Scalars['Int']['input']>
  totalDiscount?: InputMaybe<Scalars['Int']['input']>
  totalTaxAmount?: InputMaybe<Scalars['Int']['input']>
  unit?: InputMaybe<Scalars['String']['input']>
  unitDiscount?: InputMaybe<Scalars['Int']['input']>
  unitPrice: Scalars['Int']['input']
  unitSubTotal?: InputMaybe<Scalars['Int']['input']>
  unitTaxAmount?: InputMaybe<Scalars['Int']['input']>
  upc?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionItemType = {
  __typename?: 'SubscriptionItemType'
  ageVerificationRequired?: Maybe<Scalars['Boolean']['output']>
  aisleLocation?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  discounts?: Maybe<Array<DiscountType>>
  frequency: Scalars['String']['output']
  isDeliveryItem?: Maybe<Scalars['Boolean']['output']>
  isReturnable?: Maybe<Scalars['Boolean']['output']>
  itemConditionOptions?: Maybe<Array<Scalars['String']['output']>>
  itemType?: Maybe<Scalars['String']['output']>
  location?: Maybe<LocationType>
  logoUrl?: Maybe<Scalars['String']['output']>
  originalItemSku?: Maybe<Scalars['String']['output']>
  promotions?: Maybe<Array<PromotionType>>
  promotionsApplied?: Maybe<Array<PromotionType>>
  quantity: Scalars['Int']['output']
  quantityRefunded?: Maybe<Scalars['Int']['output']>
  quantitySubstituted?: Maybe<Scalars['Int']['output']>
  referenceProductId?: Maybe<Scalars['String']['output']>
  refundReason?: Maybe<Scalars['String']['output']>
  requiredMinimumAge?: Maybe<Scalars['Int']['output']>
  returnReasonOptions?: Maybe<Array<Scalars['String']['output']>>
  sku: Scalars['String']['output']
  skuType?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  subTotal?: Maybe<Scalars['Int']['output']>
  substitutionReason?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['Int']['output']>
  taxGroup?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Float']['output']>
  total?: Maybe<Scalars['Int']['output']>
  totalDiscount?: Maybe<Scalars['Int']['output']>
  totalTaxAmount?: Maybe<Scalars['Int']['output']>
  unit?: Maybe<Scalars['String']['output']>
  unitDiscount?: Maybe<Scalars['Int']['output']>
  unitPrice?: Maybe<Scalars['Int']['output']>
  unitSubTotal?: Maybe<Scalars['Int']['output']>
  unitTaxAmount?: Maybe<Scalars['Int']['output']>
  upc?: Maybe<Scalars['String']['output']>
}

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload'
  cart?: Maybe<CartType>
  customer?: Maybe<CustomerType>
  flow?: Maybe<Scalars['String']['output']>
  loyaltySignupCampaign?: Maybe<LoyaltySignupEventType>
  recommendProducts?: Maybe<Array<ProductType>>
  subscribedProduct?: Maybe<SubscriptionItemType>
}

export type TemplatesType = {
  __typename?: 'TemplatesType'
  orderReceipt?: Maybe<Scalars['String']['output']>
}

export type TillFilterInputType = {
  register: Scalars['String']['input']
  storeNumber: Scalars['String']['input']
  till: Scalars['String']['input']
}

export type TillOutputType = {
  __typename?: 'TillOutputType'
  tills: Array<RegisterTillType>
}

export type TillTransactionResponseType = {
  __typename?: 'TillTransactionResponseType'
  activeTill: Scalars['Int']['output']
  nodes: Array<TillTransactionType>
  registerNetCash: Scalars['Int']['output']
  registerTotalCash: Scalars['Int']['output']
  totalPageCount: Scalars['Int']['output']
}

export type TillTransactionType = {
  __typename?: 'TillTransactionType'
  closeTime?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  defaultStartingFloat?: Maybe<Scalars['Int']['output']>
  employee?: Maybe<EmployeeType>
  employeeCode?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  location?: Maybe<LocationType>
  netCash?: Maybe<Scalars['Int']['output']>
  openTime?: Maybe<Scalars['String']['output']>
  register?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  till?: Maybe<Scalars['String']['output']>
  tillAmountUpperLimit?: Maybe<Scalars['Int']['output']>
  tillAmountUpperLimitWarning?: Maybe<Scalars['Int']['output']>
  totalCash?: Maybe<Scalars['Int']['output']>
}

export type TillType = {
  __typename?: 'TillType'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type TimeInput = {
  hours: Scalars['Int']['input']
  minutes: Scalars['Int']['input']
}

export type TimeType = {
  __typename?: 'TimeType'
  hours: Scalars['Int']['output']
  minutes: Scalars['Int']['output']
}

export type TotalActualCashType = {
  __typename?: 'TotalActualCashType'
  percentageChange?: Maybe<Scalars['String']['output']>
  totalActualCash?: Maybe<Scalars['Float']['output']>
}

export type TotalExpectedCashType = {
  __typename?: 'TotalExpectedCashType'
  percentageChange?: Maybe<Scalars['String']['output']>
  totalExpectedCash?: Maybe<Scalars['Float']['output']>
}

export type TotalVarianceStatsType = {
  __typename?: 'TotalVarianceStatsType'
  percentageChange?: Maybe<Scalars['String']['output']>
  totalVariance?: Maybe<Scalars['Float']['output']>
}

export type TransactionFailureType = {
  __typename?: 'TransactionFailureType'
  nodes: Array<CashTransactionFailureType>
  totalPageCount: Scalars['Int']['output']
}

export type TransactionSummaryType = {
  __typename?: 'TransactionSummaryType'
  cash?: Maybe<Scalars['Float']['output']>
  cashAdjustment?: Maybe<Scalars['Float']['output']>
  cashDrop?: Maybe<Scalars['Float']['output']>
  cashPickup?: Maybe<Scalars['Float']['output']>
  endingDate?: Maybe<Scalars['String']['output']>
  open?: Maybe<Scalars['Float']['output']>
  paidIn?: Maybe<Scalars['Float']['output']>
  paidOut?: Maybe<Scalars['Float']['output']>
  reconcile?: Maybe<Scalars['Float']['output']>
  refund?: Maybe<Scalars['Float']['output']>
  registerTotalCash?: Maybe<Scalars['Int']['output']>
  reserveTotalCash?: Maybe<Scalars['Int']['output']>
  safeTotalCash?: Maybe<Scalars['Int']['output']>
  sale?: Maybe<Scalars['Float']['output']>
  startingDate?: Maybe<Scalars['String']['output']>
  storeTotalCash?: Maybe<Scalars['Int']['output']>
  tillCashDrop?: Maybe<Scalars['Int']['output']>
  tillCashPickup?: Maybe<Scalars['Int']['output']>
  tillClose?: Maybe<Scalars['Float']['output']>
  tillFloat?: Maybe<Scalars['Float']['output']>
  tillOpen?: Maybe<Scalars['Int']['output']>
  tillReconcile?: Maybe<Scalars['Int']['output']>
  transfer?: Maybe<Scalars['Float']['output']>
}

export type UpdateStoreGroupInput = {
  childLocationIds: Array<Scalars['String']['input']>
  childStoreGroupIds: Array<Scalars['String']['input']>
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
}

export type ValidateCustomerSignupTokenType = {
  __typename?: 'ValidateCustomerSignupTokenType'
  cart?: Maybe<CartType>
  flow: Scalars['String']['output']
  location: LocationType
}

export type ValueType = {
  __typename?: 'ValueType'
  booleanValue?: Maybe<Scalars['Boolean']['output']>
  listValue?: Maybe<Array<Scalars['String']['output']>>
  numberValue?: Maybe<Scalars['Float']['output']>
  stringValue?: Maybe<Scalars['String']['output']>
}

export type ValueTypeInput = {
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>
  listValue?: InputMaybe<Array<Scalars['String']['input']>>
  numberValue?: InputMaybe<Scalars['Float']['input']>
  stringValue?: InputMaybe<Scalars['String']['input']>
}

export type Zone = {
  __typename?: 'Zone'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ZoneExtended = {
  __typename?: 'ZoneExtended'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  registers: Array<RegisterExtended>
}

export type ZonesDiscoveryQueryResponseType = {
  __typename?: 'ZonesDiscoveryQueryResponseType'
  locationId?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  zones?: Maybe<Array<ZonesDiscoveryType>>
}

export type ZonesDiscoveryType = {
  __typename?: 'ZonesDiscoveryType'
  deviceIds?: Maybe<Array<Scalars['String']['output']>>
  registerIds?: Maybe<Array<Scalars['String']['output']>>
  zoneDescription?: Maybe<Scalars['String']['output']>
  zoneId?: Maybe<Scalars['String']['output']>
  zoneName?: Maybe<Scalars['String']['output']>
}

export type CreateBusinessMutationVariables = Exact<{
  business: BusinessDetailsInput
}>

export type CreateBusinessMutation = {
  __typename?: 'Mutation'
  createBusiness: {
    __typename?: 'BusinessDetailsType'
    brand?: string | null
    id: string
    logo?: string | null
    name: string
    featureFlags?: any | null
    authMethod: string
    okta?: {
      __typename?: 'OktaType'
      oktaClientId?: string | null
      oktaIssuer?: string | null
    } | null
    contactDetails?: {
      __typename?: 'ContactDetailsType'
      contactPhone?: string | null
      contactEmail?: string | null
      supportPhone?: string | null
      supportEmail?: string | null
    } | null
    address?: {
      __typename?: 'AddressType'
      city?: string | null
      lat?: string | null
      long?: string | null
      state?: string | null
      street?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
  }
}

export type UpdateBusinessMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  business: BusinessDetailsUpdateInput
}>

export type UpdateBusinessMutation = {
  __typename?: 'Mutation'
  updateBusiness: {
    __typename?: 'BusinessDetailsType'
    brand?: string | null
    id: string
    logo?: string | null
    name: string
    featureFlags?: any | null
    authMethod: string
    okta?: {
      __typename?: 'OktaType'
      oktaClientId?: string | null
      oktaIssuer?: string | null
    } | null
    contactDetails?: {
      __typename?: 'ContactDetailsType'
      contactPhone?: string | null
      contactEmail?: string | null
      supportPhone?: string | null
      supportEmail?: string | null
    } | null
    address?: {
      __typename?: 'AddressType'
      city?: string | null
      lat?: string | null
      long?: string | null
      state?: string | null
      street?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
  }
}

export type UpdateBusinessColorsMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  primary: Scalars['String']['input']
  secondary: Scalars['String']['input']
}>

export type UpdateBusinessColorsMutation = {
  __typename?: 'Mutation'
  updateBusinessColors: {
    __typename?: 'BusinessColorsType'
    primary?: string | null
    secondary?: string | null
  }
}

export type UpdateOrderEmailReceiptTemplateMutationVariables = Exact<{
  orderReceipt: Scalars['String']['input']
}>

export type UpdateOrderEmailReceiptTemplateMutation = {
  __typename?: 'Mutation'
  updateOrderEmailReceiptTemplate: string
}

export type ToggleEmailReceiptEnabledMutationVariables = Exact<{
  emailReceiptEnabled: Scalars['Boolean']['input']
}>

export type ToggleEmailReceiptEnabledMutation = {
  __typename?: 'Mutation'
  toggleEmailReceiptEnabled: boolean
}

export type ResetOrderEmailReceiptTemplateMutationVariables = Exact<{
  [key: string]: never
}>

export type ResetOrderEmailReceiptTemplateMutation = {
  __typename?: 'Mutation'
  resetOrderEmailReceiptTemplate: string
}

export type CreateCartMutationVariables = Exact<{
  locationId: Scalars['String']['input']
  employeeId: Scalars['String']['input']
  customerId: Scalars['String']['input']
}>

export type CreateCartMutation = {
  __typename?: 'Mutation'
  createCart: {__typename?: 'CartType'; id: string}
}

export type CreateCartGuestMutationVariables = Exact<{
  locationId: Scalars['String']['input']
}>

export type CreateCartGuestMutation = {
  __typename?: 'Mutation'
  createCartGuest: {__typename?: 'CartType'; id: string}
}

export type UpdateCartItemsMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  items: Array<ItemInput> | ItemInput
  subscriptionItems: Array<SubscriptionItemInput> | SubscriptionItemInput
  returnedItems: Array<ReturnItemInput> | ReturnItemInput
  customerId?: InputMaybe<Scalars['String']['input']>
  promotionId?: InputMaybe<Scalars['String']['input']>
  deliveryAddress?: InputMaybe<AddressInput>
}>

export type UpdateCartItemsMutation = {
  __typename?: 'Mutation'
  updateCartItems: {
    __typename?: 'CartType'
    id: string
    totalDiscount?: number | null
    subTotal?: number | null
    totalAmount?: number | null
    totalTax?: number | null
    items: Array<{
      __typename?: 'ItemType'
      sku: string
      quantity: number
      unitPrice?: number | null
      unitDiscount?: number | null
      description?: string | null
      logoUrl?: string | null
      unitSubTotal?: number | null
      unitTaxAmount?: number | null
      totalTaxAmount?: number | null
      totalDiscount?: number | null
      total?: number | null
    }>
  }
}

export type CopyNfcCartToMposMutationVariables = Exact<{
  mposCartId: Scalars['String']['input']
  nfcCartId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type CopyNfcCartToMposMutation = {
  __typename?: 'Mutation'
  copyNfcCartToMpos: boolean
}

export type VerifyCartMutationVariables = Exact<{
  cartId: Scalars['ID']['input']
  locationId: Scalars['ID']['input']
}>

export type VerifyCartMutation = {__typename?: 'Mutation'; verifyCart: boolean}

export type CashPickupMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Float']['input']
  till: Scalars['String']['input']
  register: Scalars['String']['input']
  eventId: Scalars['String']['input']
}>

export type CashPickupMutation = {
  __typename?: 'Mutation'
  addTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type CashDropMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Float']['input']
  till: Scalars['String']['input']
  register: Scalars['String']['input']
  eventId: Scalars['String']['input']
}>

export type CashDropMutation = {
  __typename?: 'Mutation'
  addTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type CreateTransactionMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Float']['input']
  employeeCode?: InputMaybe<Scalars['String']['input']>
  till: Scalars['String']['input']
  register: Scalars['String']['input']
  businessDate?: InputMaybe<Scalars['String']['input']>
  eventType: Scalars['String']['input']
  description: Scalars['String']['input']
  transactionType?: InputMaybe<Scalars['String']['input']>
  transactionId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['String']['input']
}>

export type CreateTransactionMutation = {
  __typename?: 'Mutation'
  addTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type CreateSafeTransactionMutationVariables = Exact<{
  locationId: Scalars['String']['input']
  fromSafe: Scalars['String']['input']
  toSafe: Scalars['String']['input']
  amount: Scalars['Float']['input']
  eventType: Scalars['String']['input']
  description: Scalars['String']['input']
  eventId: Scalars['String']['input']
}>

export type CreateSafeTransactionMutation = {
  __typename?: 'Mutation'
  addSafeCashTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type CashTillCloseMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Float']['input']
  cashVariance: Scalars['Float']['input']
  till: Scalars['String']['input']
  register: Scalars['String']['input']
  businessDate?: InputMaybe<Scalars['String']['input']>
  reasonCode?: InputMaybe<Scalars['String']['input']>
  denominationBreakdown?: InputMaybe<
    Array<DenominationInput> | DenominationInput
  >
  reasonDescription?: InputMaybe<Scalars['String']['input']>
  otherDenominationCurrency?: InputMaybe<Scalars['Int']['input']>
  eventId: Scalars['String']['input']
}>

export type CashTillCloseMutation = {
  __typename?: 'Mutation'
  addTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type CashDepositMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Int']['input']
}>

export type CashDepositMutation = {
  __typename?: 'Mutation'
  createBankDeposit: {
    __typename?: 'BankDepositType'
    amount: number
    createdAt?: string | null
    updatedAt?: string | null
    status?: string | null
    id: string
  }
}

export type SafeReconcileMutationVariables = Exact<{
  location: Scalars['String']['input']
  amount: Scalars['Int']['input']
  safeType: Scalars['String']['input']
  reasonCode: Scalars['String']['input']
  denominationBreakdown?: InputMaybe<
    Array<DenominationInput> | DenominationInput
  >
  reasonDescription?: InputMaybe<Scalars['String']['input']>
  otherDenominationCurrency?: InputMaybe<Scalars['Int']['input']>
}>

export type SafeReconcileMutation = {
  __typename?: 'Mutation'
  createStoreTransaction: {
    __typename?: 'StoreTransactionType'
    amount: number
    dateCreated?: string | null
    dateModified?: string | null
    id: string
  }
}

export type CashDepositUpdateMutationVariables = Exact<{
  id: Scalars['String']['input']
  location: Scalars['String']['input']
  depositNumber: Scalars['String']['input']
  depositReceipt?: InputMaybe<Scalars['Upload']['input']>
}>

export type CashDepositUpdateMutation = {
  __typename?: 'Mutation'
  updateBankDeposit: {
    __typename?: 'BankDepositType'
    amount: number
    createdAt?: string | null
    updatedAt?: string | null
    status?: string | null
    depositReceipt?: string | null
    id: string
  }
}

export type DownloadFileMutationVariables = Exact<{
  locationId: Scalars['String']['input']
  id: Scalars['String']['input']
}>

export type DownloadFileMutation = {
  __typename?: 'Mutation'
  downloadDepositReceipt: {
    __typename?: 'FileType'
    fileContent: string
    fileName: string
  }
}

export type GenerateAtlasAuthTokenMutationVariables = Exact<{
  [key: string]: never
}>

export type GenerateAtlasAuthTokenMutation = {
  __typename?: 'Mutation'
  generateAtlasAuthToken: {
    __typename?: 'AtlasAuthType'
    success: boolean
    token?: string | null
  }
}

export type BulkCashTransactionMutationVariables = Exact<{
  transactions: Array<CashTransactionInputType> | CashTransactionInputType
}>

export type BulkCashTransactionMutation = {
  __typename?: 'Mutation'
  bulkTransactionEvent: {
    __typename?: 'CashEventResponse'
    cashEvent?: string | null
  }
}

export type AddCustomerForConsumerAgentMutationVariables = Exact<{
  customer: CustomerInput
}>

export type AddCustomerForConsumerAgentMutation = {
  __typename?: 'Mutation'
  addCustomerForConsumerAgent?: any | null
}

export type LoginCustomerMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type LoginCustomerMutation = {
  __typename?: 'Mutation'
  loginCustomer: {
    __typename?: 'CustomerLoginType'
    token: string
    customer: {
      __typename?: 'CustomerType'
      id: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type AddCustomerMutationVariables = Exact<{
  customer: CustomerInput
}>

export type AddCustomerMutation = {
  __typename?: 'Mutation'
  addCustomer?: any | null
}

export type UpdateCustomerMutationVariables = Exact<{
  customerId: Scalars['String']['input']
  customer: CustomerInput
}>

export type UpdateCustomerMutation = {
  __typename?: 'Mutation'
  updateCustomer?: any | null
}

export type ValidateCustomerSignupTokenMutationVariables = Exact<{
  token: Scalars['String']['input']
  checkUsage?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ValidateCustomerSignupTokenMutation = {
  __typename?: 'Mutation'
  validateCustomerSignupToken: {
    __typename?: 'ValidateCustomerSignupTokenType'
    flow: string
    cart?: {
      __typename?: 'CartType'
      id: string
      customer?: {
        __typename?: 'CustomerType'
        id: string
        firstName?: string | null
        lastName?: string | null
        email?: string | null
        addresses?: Array<{
          __typename?: 'CustomerAddressType'
          street?: string | null
          city?: string | null
          state?: string | null
          zipCode?: string | null
          country?: string | null
        }> | null
      } | null
      deliveryAddress?: {
        __typename?: 'AddressType'
        street?: string | null
        city?: string | null
        state?: string | null
        zipCode?: string | null
        country?: string | null
      } | null
      subscriptionItems: Array<{
        __typename?: 'SubscriptionItemType'
        frequency: string
        status?: string | null
        description?: string | null
        sku: string
        total?: number | null
        quantity: number
      }>
      location: {
        __typename?: 'LocationType'
        id: string
        name: string
        business: {__typename?: 'BusinessType'; id: string; name: string}
      }
    } | null
  }
}

export type SignupCustomerMutationVariables = Exact<{
  customer: CustomerInput
  token: Scalars['String']['input']
}>

export type SignupCustomerMutation = {
  __typename?: 'Mutation'
  signupCustomer: {
    __typename?: 'CustomerLoginType'
    token: string
    customer: {
      __typename?: 'CustomerType'
      id: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type CustomerSigninMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}>

export type CustomerSigninMutation = {
  __typename?: 'Mutation'
  customerSignin: {
    __typename?: 'CustomerLoginType'
    token: string
    customer: {
      __typename?: 'CustomerType'
      id: string
      email?: string | null
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type ApproveItemSubscriptionMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  sku: Scalars['String']['input']
  token: Scalars['String']['input']
}>

export type ApproveItemSubscriptionMutation = {
  __typename?: 'Mutation'
  approveItemSubscription?: any | null
}

export type GenerateGenieChatSessionIdConsumerAgentMutationVariables = Exact<{
  [key: string]: never
}>

export type GenerateGenieChatSessionIdConsumerAgentMutation = {
  __typename?: 'Mutation'
  generateGenieChatSessionIdConsumerAgent: string
}

export type DeleteChatHistoryForConsumerAgentMutationVariables = Exact<{
  chatSessionId: Scalars['String']['input']
}>

export type DeleteChatHistoryForConsumerAgentMutation = {
  __typename?: 'Mutation'
  deleteChatHistoryForConsumerAgent?: any | null
}

export type AddCustomerAddressMutationVariables = Exact<{
  customerId: Scalars['String']['input']
  token: Scalars['String']['input']
  address: CustomerAddressInput
}>

export type AddCustomerAddressMutation = {
  __typename?: 'Mutation'
  addCustomerAddress: {
    __typename?: 'CustomerType'
    id: string
    addresses?: Array<{
      __typename?: 'CustomerAddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }> | null
  }
}

export type UpdateCartAddressMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  token: Scalars['String']['input']
  address: AddressInput
}>

export type UpdateCartAddressMutation = {
  __typename?: 'Mutation'
  updateCartAddress: {__typename?: 'CartType'; id: string}
}

export type CreateEmployeeMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  employee: EmployeeInput
}>

export type CreateEmployeeMutation = {
  __typename?: 'Mutation'
  createEmployee: {
    __typename?: 'EmployeeType'
    id: string
    name: string
    email?: string | null
    phoneNumber?: string | null
    employeeCode: string
    status?: string | null
    fullAddress?: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
    role?: {__typename?: 'RoleType'; id: string; name: string} | null
    locations?: Array<{
      __typename?: 'LocationType'
      id: string
      name: string
    }> | null
    defaultLocation?: {
      __typename?: 'LocationType'
      id: string
      name: string
    } | null
  }
}

export type UpdateEmployeeMutationVariables = Exact<{
  employeeId: Scalars['String']['input']
  businessId: Scalars['String']['input']
  employee: EmployeeInput
}>

export type UpdateEmployeeMutation = {
  __typename?: 'Mutation'
  updateEmployee: {
    __typename?: 'EmployeeType'
    id: string
    name: string
    email?: string | null
    phoneNumber?: string | null
    employeeCode: string
    status?: string | null
    fullAddress?: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
    role?: {__typename?: 'RoleType'; id: string; name: string} | null
    locations?: Array<{
      __typename?: 'LocationType'
      id: string
      name: string
    }> | null
    defaultLocation?: {
      __typename?: 'LocationType'
      id: string
      name: string
    } | null
  }
}

export type SendOtpForPasswordResetMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type SendOtpForPasswordResetMutation = {
  __typename?: 'Mutation'
  sendOtpForPasswordReset?: any | null
}

export type ResetPasswordWithOtpMutationVariables = Exact<{
  token: Scalars['String']['input']
  otp: Scalars['String']['input']
  password: Scalars['String']['input']
  pin: Scalars['String']['input']
}>

export type ResetPasswordWithOtpMutation = {
  __typename?: 'Mutation'
  resetPasswordWithOtp?: any | null
}

export type ResendOtpForPasswordResetMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type ResendOtpForPasswordResetMutation = {
  __typename?: 'Mutation'
  resendOtpForPasswordReset?: any | null
}

export type GenerateGenieChatSessionIdMutationVariables = Exact<{
  [key: string]: never
}>

export type GenerateGenieChatSessionIdMutation = {
  __typename?: 'Mutation'
  generateGenieChatSessionId: string
}

export type CreateInventoryMutationVariables = Exact<{
  inventory: InventoryInput
  locationId: Scalars['String']['input']
}>

export type CreateInventoryMutation = {
  __typename?: 'Mutation'
  createInventory: {
    __typename?: 'InventoryDetailsType'
    id: string
    logoUrl?: string | null
    description?: string | null
    sku: string
    unit?: string | null
    unitCount?: string | null
    upc?: string | null
    businessId: string
    locationId: string
    taxable?: string | null
    skuType?: string | null
    className?: string | null
    subclassName?: string | null
    categoryName?: string | null
    pricingDetails: {
      __typename?: 'InventoryPricingType'
      currency: string
      currencyMultiplier: string
      discount?: string | null
      total: string
      unitPrice: string
    }
    taxDetails: {
      __typename?: 'InvetoryTaxesType'
      taxAmount?: string | null
      taxGroup?: string | null
      taxRate?: string | null
    }
  }
}

export type UpdateInventoryMutationVariables = Exact<{
  inventory: InventoryInput
  locationId: Scalars['String']['input']
}>

export type UpdateInventoryMutation = {
  __typename?: 'Mutation'
  updateInventory: {
    __typename?: 'InventoryDetailsType'
    id: string
    logoUrl?: string | null
    description?: string | null
    sku: string
    unit?: string | null
    unitCount?: string | null
    upc?: string | null
    businessId: string
    locationId: string
    taxable?: string | null
    skuType?: string | null
    className?: string | null
    subclassName?: string | null
    categoryName?: string | null
    pricingDetails: {
      __typename?: 'InventoryPricingType'
      currency: string
      currencyMultiplier: string
      discount?: string | null
      total: string
      unitPrice: string
    }
    taxDetails: {
      __typename?: 'InvetoryTaxesType'
      taxAmount?: string | null
      taxGroup?: string | null
      taxRate?: string | null
    }
  }
}

export type DeleteInventoryMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteInventoryMutation = {
  __typename?: 'Mutation'
  deleteInventory?: any | null
}

export type AddLocationMutationVariables = Exact<{
  location: LocationInput
}>

export type AddLocationMutation = {
  __typename?: 'Mutation'
  addLocation: {
    __typename?: 'LocationType'
    id: string
    name: string
    brand: string
    description: string
    storeNumber: string
    timeZone: string
    status: string
    notificationEmails?: Array<string> | null
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
    phoneNumbers: {
      __typename?: 'PhoneNumberType'
      contactPhone?: string | null
      supportPhone?: string | null
    }
    operatingHours: Array<{
      __typename?: 'OperatingHourType'
      day?: string | null
      fromHour?: string | null
      toHour?: string | null
    }>
    locationConfig?: {
      __typename?: 'LocationConfigType'
      stripe?: {
        __typename?: 'StripeLocationConfigType'
        displayName?: string | null
        stripeTerminalLocationId?: string | null
      } | null
    } | null
  }
}

export type UpdateLocationMutationVariables = Exact<{
  locationId: Scalars['String']['input']
  location: LocationInput
}>

export type UpdateLocationMutation = {
  __typename?: 'Mutation'
  updateLocation: {
    __typename?: 'LocationType'
    id: string
    name: string
    brand: string
    description: string
    storeNumber: string
    timeZone: string
    status: string
    notificationEmails?: Array<string> | null
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
    phoneNumbers: {
      __typename?: 'PhoneNumberType'
      contactPhone?: string | null
      supportPhone?: string | null
    }
    operatingHours: Array<{
      __typename?: 'OperatingHourType'
      day?: string | null
      fromHour?: string | null
      toHour?: string | null
    }>
    locationConfig?: {
      __typename?: 'LocationConfigType'
      stripe?: {
        __typename?: 'StripeLocationConfigType'
        displayName?: string | null
        stripeTerminalLocationId?: string | null
      } | null
    } | null
  }
}

export type LocationConfigMutationVariables = Exact<{
  input: LocationConfigInput
}>

export type LocationConfigMutation = {
  __typename?: 'Mutation'
  locationConfig?: any | null
}

export type DeactivateLocationMutationVariables = Exact<{
  locationId: Scalars['String']['input']
}>

export type DeactivateLocationMutation = {
  __typename?: 'Mutation'
  deactivateLocation: {
    __typename?: 'LocationType'
    id: string
    name: string
    brand: string
    description: string
    storeNumber: string
    timeZone: string
    status: string
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
    phoneNumbers: {
      __typename?: 'PhoneNumberType'
      contactPhone?: string | null
      supportPhone?: string | null
    }
    operatingHours: Array<{
      __typename?: 'OperatingHourType'
      day?: string | null
      fromHour?: string | null
      toHour?: string | null
    }>
    locationConfig?: {
      __typename?: 'LocationConfigType'
      stripe?: {
        __typename?: 'StripeLocationConfigType'
        displayName?: string | null
        stripeTerminalLocationId?: string | null
      } | null
    } | null
  }
}

export type ActivateLocationMutationVariables = Exact<{
  locationId: Scalars['String']['input']
}>

export type ActivateLocationMutation = {
  __typename?: 'Mutation'
  activateLocation: {
    __typename?: 'LocationType'
    id: string
    name: string
    brand: string
    description: string
    storeNumber: string
    timeZone: string
    status: string
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
    phoneNumbers: {
      __typename?: 'PhoneNumberType'
      contactPhone?: string | null
      supportPhone?: string | null
    }
    operatingHours: Array<{
      __typename?: 'OperatingHourType'
      day?: string | null
      fromHour?: string | null
      toHour?: string | null
    }>
    locationConfig?: {
      __typename?: 'LocationConfigType'
      stripe?: {
        __typename?: 'StripeLocationConfigType'
        displayName?: string | null
        stripeTerminalLocationId?: string | null
      } | null
    } | null
  }
}

export type VerifyOktaTokenAndGenerateSessionTokenMutationVariables = Exact<{
  businessId?: InputMaybe<Scalars['String']['input']>
  accessToken: Scalars['String']['input']
  idToken: Scalars['String']['input']
  oktaClientId: Scalars['String']['input']
  oktaIssuer: Scalars['String']['input']
}>

export type VerifyOktaTokenAndGenerateSessionTokenMutation = {
  __typename?: 'Mutation'
  verifyOktaTokenAndGenerateSessionToken: string
}

export type LoginUserForDashboardMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type LoginUserForDashboardMutation = {
  __typename?: 'Mutation'
  loginUserForDashboard: {
    __typename?: 'LoginResponseType'
    toteAccessToken?: string | null
    businessId: string
    mfaEnabled: boolean
  }
}

export type LoginGuestMutationVariables = Exact<{
  locationId: Scalars['ID']['input']
}>

export type LoginGuestMutation = {
  __typename?: 'Mutation'
  loginGuest: {
    __typename?: 'LoginUser'
    success: boolean
    token?: string | null
    businessConfigs?: {
      __typename?: 'BusinessConfigsType'
      stripePublishableKey?: string | null
    } | null
    location?: {
      __typename?: 'LocationType'
      id: string
      name: string
      business: {
        __typename?: 'BusinessType'
        id: string
        name: string
        configurations?: {
          __typename?: 'BusinessConfigurationsType'
          colors?: {
            __typename?: 'BusinessColorsType'
            primary?: string | null
            secondary?: string | null
          } | null
        } | null
      }
    } | null
  }
}

export type VerifyOtpAndGenerateSessionTokenMutationVariables = Exact<{
  email: Scalars['String']['input']
  otp: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type VerifyOtpAndGenerateSessionTokenMutation = {
  __typename?: 'Mutation'
  verifyOtpAndGenerateSessionToken: {
    __typename?: 'LoginResponseType'
    toteAccessToken?: string | null
    businessId: string
  }
}

export type ResendOtpMutationVariables = Exact<{
  email: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type ResendOtpMutation = {
  __typename?: 'Mutation'
  resendOtp?: any | null
}

export type VerifyAdminOktaTokenAndGenerateSessionTokenMutationVariables =
  Exact<{
    accessToken: Scalars['String']['input']
    idToken: Scalars['String']['input']
    oktaClientId: Scalars['String']['input']
    oktaIssuer: Scalars['String']['input']
  }>

export type VerifyAdminOktaTokenAndGenerateSessionTokenMutation = {
  __typename?: 'Mutation'
  verifyAdminOktaTokenAndGenerateSessionToken: string
}

export type GenerateOauthApplicationsMutationVariables = Exact<{
  locationId: Scalars['String']['input']
  appName: Scalars['String']['input']
}>

export type GenerateOauthApplicationsMutation = {
  __typename?: 'Mutation'
  generateOauthApplications: {
    __typename?: 'OAuthApplicationsType'
    appName?: string | null
    clientId?: string | null
    clientSecret?: string | null
    status?: string | null
  }
}

export type CreatePaymentIntentMutationVariables = Exact<{
  amount: Scalars['Int']['input']
  businessId: Scalars['String']['input']
}>

export type CreatePaymentIntentMutation = {
  __typename?: 'Mutation'
  createPaymentIntent: any
}

export type PlaceOrderConsumerAgentMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  currency: Scalars['String']['input']
  amount: Scalars['Int']['input']
  paymentMethod: Scalars['String']['input']
  paymentGateway?: InputMaybe<Scalars['String']['input']>
  paymentGatewayReferenceId?: InputMaybe<Scalars['String']['input']>
  nonce: Scalars['String']['input']
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type PlaceOrderConsumerAgentMutation = {
  __typename?: 'Mutation'
  placeOrderConsumerAgent: {
    __typename?: 'OrderType'
    amountPaid?: number | null
    amountRefunded?: number | null
    currency: string
    id: string
    paymentStatus?: string | null
    refundStatus?: string | null
    totalAmount: number
    payments?: Array<{
      __typename?: 'OrderPaymentType'
      id: string
      orderId: string
      status: string
      amount?: number | null
      paymentMethod?: string | null
      paymentGatewayReferenceId?: string | null
      currency?: string | null
      paymentMethodDetails?: {
        __typename?: 'PaymentMethodDetailsType'
        type?: string | null
        card?: {
          __typename?: 'CardType'
          last4?: string | null
          brand?: string | null
          fingerprint?: string | null
        } | null
      } | null
    }> | null
  }
}

export type OrderPaymentStatusConsumerAgentMutationVariables = Exact<{
  orderId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type OrderPaymentStatusConsumerAgentMutation = {
  __typename?: 'Mutation'
  orderPaymentStatusConsumerAgent: {
    __typename?: 'OrderType'
    id: string
    paymentStatus?: string | null
    refundStatus?: string | null
    totalAmount: number
    currency: string
    amountPaid?: number | null
    amountRefunded?: number | null
  }
}

export type AddZoneDataMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  devices: Array<Scalars['String']['input']> | Scalars['String']['input']
  registers: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type AddZoneDataMutation = {
  __typename?: 'Mutation'
  addZoneData?: any | null
}

export type UpdateZoneMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  zoneId: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  devices: Array<Scalars['String']['input']> | Scalars['String']['input']
  registers: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateZoneMutation = {
  __typename?: 'Mutation'
  updateZone?: any | null
}

export type ResolveErrorNotificationMutationVariables = Exact<{
  activityId: Scalars['String']['input']
}>

export type ResolveErrorNotificationMutation = {
  __typename?: 'Mutation'
  resolveErrorNotification?: any | null
}

export type DeleteZoneMutationVariables = Exact<{
  zoneId: Scalars['String']['input']
}>

export type DeleteZoneMutation = {
  __typename?: 'Mutation'
  deleteZone?: any | null
}

export type UpdatePeripheralHubNotificationConfigMutationVariables = Exact<{
  notificationConfiguration: PeripheralHubNotificationsInput
}>

export type UpdatePeripheralHubNotificationConfigMutation = {
  __typename?: 'Mutation'
  updatePeripheralHubNotificationConfig?: any | null
}

export type Create_RuleMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  ruleName: Scalars['String']['input']
  ruleType: Scalars['String']['input']
  ruleValue: Scalars['String']['input']
  applyTo?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  excludeFrom?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  applyToProductGroup?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  excludeFromProductGroup?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type Create_RuleMutation = {
  __typename?: 'Mutation'
  createRule: {
    __typename?: 'PromoRuleType'
    businessId: string
    ruleName: string
    ruleType: string
    ruleValue?: string | null
    applyTo?: Array<string> | null
    excludeFrom?: Array<string> | null
    applyToProductGroup?: Array<string> | null
    excludeFromProductGroup?: Array<string> | null
  }
}

export type CreatePromotionMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  active: Scalars['Boolean']['input']
  canBeCombined: Scalars['Boolean']['input']
  endDate: Scalars['DateTime']['input']
  priority: Scalars['Int']['input']
  promotionName: Scalars['String']['input']
  promotionType: Scalars['String']['input']
  rules: Array<Scalars['String']['input']> | Scalars['String']['input']
  startDate: Scalars['DateTime']['input']
  promotionValue: Scalars['Int']['input']
  description: Scalars['String']['input']
  promotionSchema: Scalars['String']['input']
  subscriptionType: Scalars['Boolean']['input']
}>

export type CreatePromotionMutation = {
  __typename?: 'Mutation'
  createPromotion: {
    __typename?: 'PromotionType'
    businessId: string
    active: boolean
    canBeCombined: boolean
    endDate: any
    priority: number
    promotionName: string
    promotionType: string
    startDate: any
    promotionValue: number
    description?: string | null
    promotionSchema: string
    subscriptionType: boolean
    rules?: Array<{
      __typename?: 'PromoRuleType'
      ruleName: string
      ruleType: string
      ruleValue?: string | null
    }> | null
  }
}

export type AddPromotionToSkuMutationVariables = Exact<{
  promotionName: Scalars['String']['input']
  sku: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type AddPromotionToSkuMutation = {
  __typename?: 'Mutation'
  addPromotionToSku: {
    __typename?: 'ApiResponse'
    success: boolean
    message?: string | null
  }
}

export type CreateRoleMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  permissions: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type CreateRoleMutation = {
  __typename?: 'Mutation'
  createRole?: any | null
}

export type UpdateRoleMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  isActive: Scalars['Boolean']['input']
  permissions: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateRoleMutation = {
  __typename?: 'Mutation'
  updateRole?: any | null
}

export type DeleteRoleMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  id: Scalars['String']['input']
}>

export type DeleteRoleMutation = {
  __typename?: 'Mutation'
  deleteRole?: any | null
}

export type AssignRoleMutationVariables = Exact<{
  employeeId: Scalars['String']['input']
  roleId?: InputMaybe<Scalars['String']['input']>
}>

export type AssignRoleMutation = {
  __typename?: 'Mutation'
  assignRole?: any | null
}

export type AddSecretMutationVariables = Exact<{
  secretName: Scalars['String']['input']
  secretValue: Scalars['String']['input']
}>

export type AddSecretMutation = {
  __typename?: 'Mutation'
  addSecret?: any | null
}

export type DeleteSecretMutationVariables = Exact<{
  secretName: Scalars['String']['input']
}>

export type DeleteSecretMutation = {
  __typename?: 'Mutation'
  deleteSecret?: any | null
}

export type AddUpdateServiceConfigMutationVariables = Exact<{
  input: ServiceConfigInput
}>

export type AddUpdateServiceConfigMutation = {
  __typename?: 'Mutation'
  serviceConfig?: any | null
}

export type VerifyServiceConfigMutationVariables = Exact<{
  input: ServiceConfigInput
}>

export type VerifyServiceConfigMutation = {
  __typename?: 'Mutation'
  verifyServiceConfig: boolean
}

export type RenameStoreGroupClusterMutationVariables = Exact<{
  storeGroupClusterId: Scalars['String']['input']
  newClusterName: Scalars['String']['input']
}>

export type RenameStoreGroupClusterMutation = {
  __typename?: 'Mutation'
  renameStoreGroupCluster: {
    __typename?: 'StoreGroupClusterType'
    name: string
    hierarchyLevel: number
  }
}

export type CreateStoreGroupMutationVariables = Exact<{
  name: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  storeGroupClusterId: Scalars['String']['input']
  childStoreGroupIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input']
  childLocationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input']
}>

export type CreateStoreGroupMutation = {
  __typename?: 'Mutation'
  createStoreGroup: {
    __typename?: 'StoreGroupType'
    id: string
    name: string
    status: string
    storeGroupCluster: {
      __typename?: 'StoreGroupClusterType'
      id: string
      name: string
      hierarchyLevel: number
    }
  }
}

export type UpdateStoreGroupMutationVariables = Exact<{
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  childStoreGroupIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input']
  childLocationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input']
}>

export type UpdateStoreGroupMutation = {
  __typename?: 'Mutation'
  updateStoreGroup: {
    __typename?: 'StoreGroupType'
    id: string
    name: string
    status: string
    storeGroupCluster: {
      __typename?: 'StoreGroupClusterType'
      id: string
      name: string
      hierarchyLevel: number
    }
  }
}

export type GetBusinessesQueryVariables = Exact<{[key: string]: never}>

export type GetBusinessesQuery = {
  __typename?: 'Query'
  getBusinesses: Array<{
    __typename?: 'BusinessDetailsType'
    brand?: string | null
    id: string
    logo?: string | null
    name: string
    featureFlags?: any | null
    authMethod: string
    okta?: {
      __typename?: 'OktaType'
      oktaClientId?: string | null
      oktaIssuer?: string | null
    } | null
    contactDetails?: {
      __typename?: 'ContactDetailsType'
      contactPhone?: string | null
      contactEmail?: string | null
      supportPhone?: string | null
      supportEmail?: string | null
    } | null
    address?: {
      __typename?: 'AddressType'
      city?: string | null
      lat?: string | null
      long?: string | null
      state?: string | null
      street?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
    storeGroupClusters?: Array<{
      __typename?: 'StoreGroupClusterType'
      id: string
      name: string
      hierarchyLevel: number
    }> | null
  }>
}

export type GetAllRolesEmployeesLocationsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  fetchAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetAllRolesEmployeesLocationsQuery = {
  __typename?: 'Query'
  getAllRoles: Array<{
    __typename?: 'RoleType'
    id: string
    name: string
    description: string
    isActive: boolean
    permissions: Array<string>
    createdAt: string
    updatedAt: string
  }>
  getAllEmployees: {
    __typename?: 'EmployeeListType'
    totalCount: number
    employees: Array<{
      __typename?: 'EmployeeType'
      id: string
      name: string
      email?: string | null
      phoneNumber?: string | null
      employeeCode: string
      toteSuperAdmin?: boolean | null
      fullAddress?: {
        __typename?: 'AddressType'
        street?: string | null
        city?: string | null
        state?: string | null
        zipCode?: string | null
        country?: string | null
      } | null
      role?: {
        __typename?: 'RoleType'
        id: string
        name: string
        permissions: Array<string>
        description: string
      } | null
    }>
  }
  getAllLocations: Array<{
    __typename?: 'LocationType'
    id: string
    name: string
    timeZone: string
    status: string
    storeNumber: string
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
  }>
}

export type GetOrderReceiptTemplateConfigQueryVariables = Exact<{
  businessId: Scalars['String']['input']
}>

export type GetOrderReceiptTemplateConfigQuery = {
  __typename?: 'Query'
  getBusiness: {
    __typename?: 'BusinessType'
    id: string
    configurations?: {
      __typename?: 'BusinessConfigurationsType'
      emailReceiptEnabled?: boolean | null
      templates?: {
        __typename?: 'TemplatesType'
        orderReceipt?: string | null
      } | null
    } | null
  }
}

export type Till_TransactionsQueryVariables = Exact<{
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
  date: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type Till_TransactionsQuery = {
  __typename?: 'Query'
  tillTransactions: {
    __typename?: 'TillTransactionResponseType'
    registerTotalCash: number
    registerNetCash: number
    activeTill: number
    nodes: Array<{
      __typename?: 'TillTransactionType'
      id: string
      date?: string | null
      openTime?: string | null
      closeTime?: string | null
      netCash?: number | null
      status?: string | null
      till?: string | null
      totalCash?: number | null
      register?: string | null
      employeeCode?: string | null
      defaultStartingFloat?: number | null
      tillAmountUpperLimit?: number | null
      employee?: {__typename?: 'EmployeeType'; name: string} | null
    }>
  }
}

export type Transaction_SummaryQueryVariables = Exact<{
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
  date: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type Transaction_SummaryQuery = {
  __typename?: 'Query'
  tillTransactionsSummary: {
    __typename?: 'TransactionSummaryType'
    cash?: number | null
    sale?: number | null
    cashAdjustment?: number | null
    cashPickup?: number | null
    paidIn?: number | null
    paidOut?: number | null
    tillFloat?: number | null
    open?: number | null
    tillClose?: number | null
    reconcile?: number | null
    transfer?: number | null
    refund?: number | null
    cashDrop?: number | null
    startingDate?: string | null
    endingDate?: string | null
    registerTotalCash?: number | null
    safeTotalCash?: number | null
    storeTotalCash?: number | null
    reserveTotalCash?: number | null
  }
}

export type TransactionsQueryVariables = Exact<{
  register?: InputMaybe<Scalars['String']['input']>
  till?: InputMaybe<Scalars['String']['input']>
  dateRange: Array<Scalars['String']['input']> | Scalars['String']['input']
  locationId: Scalars['String']['input']
  employeeCode?: InputMaybe<Scalars['String']['input']>
  transactionType?: InputMaybe<Scalars['String']['input']>
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type TransactionsQuery = {
  __typename?: 'Query'
  cashTransactions: {
    __typename?: 'PaginatedCashTransactionType'
    totalPageCount: number
    nodes: Array<{
      __typename?: 'CashTransactionType'
      amount: number
      closeTime?: string | null
      eventTime?: string | null
      dateCreated?: string | null
      description?: string | null
      employeeCode?: string | null
      eventType?: string | null
      id: string
      openTime?: string | null
      register?: string | null
      storeNumber?: string | null
      till?: string | null
      transactionType?: string | null
      transactionId?: string | null
      transferTo?: string | null
      transferToTill?: string | null
      transactionTime?: string | null
      eventId?: string | null
      safeType?: string | null
      isSafeTransaction?: boolean | null
      source?: string | null
      employee?: {__typename?: 'EmployeeType'; name: string} | null
    }>
  }
}

export type Get_StatusQueryVariables = Exact<{
  locationId: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type Get_StatusQuery = {__typename?: 'Query'; getTillStatus: string}

export type Bank_DepositsQueryVariables = Exact<{
  locationId: Scalars['String']['input']
  dateRange: Array<Scalars['String']['input']> | Scalars['String']['input']
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type Bank_DepositsQuery = {
  __typename?: 'Query'
  getBankDeposits: {
    __typename?: 'PaginatedBankDepositType'
    totalPageCount: number
    nodes: Array<{
      __typename?: 'BankDepositType'
      id: string
      amount: number
      createdAt?: string | null
      updatedAt?: string | null
      depositNumber?: string | null
      depositReceipt?: string | null
      status?: string | null
      employee?: {__typename?: 'EmployeeType'; name: string} | null
    }>
  }
}

export type Aggregrate_Cash_TransactionsQueryVariables = Exact<{
  storeNumbers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  locationIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  transactionTimeRange?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  eventType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type Aggregrate_Cash_TransactionsQuery = {
  __typename?: 'Query'
  aggregrateCashTransactions: {
    __typename?: 'CashTransactionAggregrationType'
    cashSalesStats?: {
      __typename?: 'CashSalesStatsType'
      cashSales?: number | null
      percentageChange?: string | null
    } | null
    averageTransactionValueStats?: {
      __typename?: 'AverageTransactionValueType'
      averageTransactionValue?: number | null
      percentageChange?: string | null
    } | null
    cashVarianceStats?: {
      __typename?: 'CashVarianceType'
      cashVariance?: number | null
      percentageChange?: string | null
    } | null
  }
}

export type Variance_ReportQueryVariables = Exact<{
  storeNumbers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  locationIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  transactionTimeRange?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  employeeCodes?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  eventType?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type Variance_ReportQuery = {
  __typename?: 'Query'
  aggregrateCashTransactions: {
    __typename?: 'CashTransactionAggregrationType'
    totalExpectedCashStats?: {
      __typename?: 'TotalExpectedCashType'
      totalExpectedCash?: number | null
      percentageChange?: string | null
    } | null
    totalActualCashStats?: {
      __typename?: 'TotalActualCashType'
      totalActualCash?: number | null
      percentageChange?: string | null
    } | null
    totalVarianceStats?: {
      __typename?: 'TotalVarianceStatsType'
      totalVariance?: number | null
      percentageChange?: string | null
    } | null
    averageVariancePercentageStats?: {
      __typename?: 'AverageVariancePercentageType'
      averageVariancePercentage?: number | null
      percentageChange?: string | null
    } | null
  }
}

export type GetRegisterTillsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type GetRegisterTillsQuery = {
  __typename?: 'Query'
  fetchRegisterList: Array<{
    __typename?: 'RegisterType'
    id: string
    name: string
  }>
  fetchTills: Array<{__typename?: 'TillType'; id: string; name: string}>
}

export type ListCustomersQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
}>

export type ListCustomersQuery = {
  __typename?: 'Query'
  listCustomers: Array<{
    __typename?: 'CustomerType'
    title?: string | null
    id: string
    firstName?: string | null
    lastName?: string | null
    avatar?: string | null
    barcode?: string | null
    email?: string | null
    phone?: string | null
    updatedAt?: any | null
    preferences: Array<{
      __typename?: 'PreferenceType'
      key?: string | null
      title?: string | null
      value?: Array<string> | null
    } | null>
    address?: {
      __typename?: 'CustomerAddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
  }>
}

export type GenieQueryConsumerAgentQueryVariables = Exact<{
  query: Scalars['String']['input']
  chatSessionId: Scalars['String']['input']
  queryType?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}>

export type GenieQueryConsumerAgentQuery = {
  __typename?: 'Query'
  genieQueryConsumerAgent: {
    __typename?: 'GenieQueryType'
    messageId: string
    queryType: string
    answer?: string | null
    query?: string | null
    status?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
  }
}

export type FetchGenieMessageConsumerAgentQueryVariables = Exact<{
  messageId: Scalars['String']['input']
}>

export type FetchGenieMessageConsumerAgentQuery = {
  __typename?: 'Query'
  fetchGenieMessageConsumerAgent: {
    __typename?: 'GenieQueryType'
    messageId: string
    queryType: string
    answer?: string | null
    query?: string | null
    status?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
    genieChatQuery?: {
      __typename?: 'GenieChatQueryType'
      products?: Array<{
        __typename?: 'ProductType'
        skuType?: string | null
        sku: string
        unitPrice?: number | null
        unitDiscount?: number | null
        unitTaxAmount?: number | null
        description?: string | null
        unitSubTotal?: number | null
        aisleLocation?: string | null
        logoUrl?: string | null
        promotions?: Array<{
          __typename?: 'PromotionType'
          description?: string | null
          promotionType: string
          promotionValue: number
          promotionName: string
          active: boolean
          businessId: string
          canBeCombined: boolean
          startDate: any
          endDate: any
          priority: number
          subscriptionType: boolean
        }> | null
      }> | null
    } | null
  }
}

export type FetchCustomerQueryVariables = Exact<{[key: string]: never}>

export type FetchCustomerQuery = {
  __typename?: 'Query'
  fetchCustomer: {
    __typename?: 'CustomerType'
    id: string
    firstName?: string | null
    lastName?: string | null
    avatar?: string | null
    barcode?: string | null
    email?: string | null
    phone?: string | null
    updatedAt?: any | null
    preferences: Array<{
      __typename?: 'PreferenceType'
      key?: string | null
      title?: string | null
      value?: Array<string> | null
    } | null>
    address?: {
      __typename?: 'CustomerAddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    } | null
  }
}

export type PredefinedChatQueryConsumerAgentQueryVariables = Exact<{
  queryId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
}>

export type PredefinedChatQueryConsumerAgentQuery = {
  __typename?: 'Query'
  predefinedChatQueryConsumerAgent: {
    __typename?: 'PredefinedQueryType'
    answer?: string | null
    query: string
    queryId: string
    products?: Array<{
      __typename?: 'ProductType'
      skuType?: string | null
      sku: string
      unitPrice?: number | null
      unitDiscount?: number | null
      unitTaxAmount?: number | null
      description?: string | null
      unitSubTotal?: number | null
      aisleLocation?: string | null
      logoUrl?: string | null
      promotions?: Array<{
        __typename?: 'PromotionType'
        description?: string | null
        promotionType: string
        promotionValue: number
        promotionName: string
        active: boolean
        businessId: string
        canBeCombined: boolean
        startDate: any
        endDate: any
        priority: number
        subscriptionType: boolean
      }> | null
    }> | null
  }
}

export type GetEmployeeQueryVariables = Exact<{[key: string]: never}>

export type GetEmployeeQuery = {
  __typename?: 'Query'
  getEmployee: {
    __typename?: 'EmployeeType'
    id: string
    name: string
    email?: string | null
    toteSuperAdmin?: boolean | null
    locations?: Array<{
      __typename?: 'LocationType'
      id: string
      name: string
      timeZone: string
      status: string
      storeNumber: string
      address: {
        __typename?: 'AddressType'
        street?: string | null
        city?: string | null
        state?: string | null
        zipCode?: string | null
        country?: string | null
      }
      locationConfig?: {
        __typename?: 'LocationConfigType'
        cashManagement?: {
          __typename?: 'CashManagementLocationConfigType'
          tillAmountUpperLimit?: number | null
          storeFloatAmount?: number | null
        } | null
      } | null
      storeGroups?: Array<{
        __typename?: 'StoreGroupType'
        id: string
        name: string
        status: string
        storeGroupCluster: {
          __typename?: 'StoreGroupClusterType'
          id: string
          name: string
          hierarchyLevel: number
        }
      }> | null
    }> | null
    business?: {
      __typename?: 'BusinessType'
      id: string
      name: string
      logo: string
      brand: string
      featureFlags: any
      configurations?: {
        __typename?: 'BusinessConfigurationsType'
        colors?: {
          __typename?: 'BusinessColorsType'
          primary?: string | null
          secondary?: string | null
        } | null
      } | null
      storeGroupClusters?: Array<{
        __typename?: 'StoreGroupClusterType'
        id: string
        name: string
        hierarchyLevel: number
      }> | null
    } | null
    role?: {
      __typename?: 'RoleType'
      id: string
      name: string
      permissions: Array<string>
      description: string
    } | null
    assignedStoreGroups?: Array<{
      __typename?: 'StoreGroupType'
      id: string
      name: string
      status: string
      storeGroupCluster: {
        __typename?: 'StoreGroupClusterType'
        id: string
        name: string
        hierarchyLevel: number
      }
      parentStoreGroup?: {
        __typename?: 'StoreGroupType'
        id: string
        name: string
        status: string
        storeGroupCluster: {
          __typename?: 'StoreGroupClusterType'
          id: string
          name: string
          hierarchyLevel: number
        }
      } | null
    }> | null
  }
}

export type GetAllEmployeesQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  employeeCode?: InputMaybe<Scalars['String']['input']>
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>
  storeGroupIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  locationIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetAllEmployeesQuery = {
  __typename?: 'Query'
  getAllEmployees: {
    __typename?: 'EmployeeListType'
    totalCount: number
    employees: Array<{
      __typename?: 'EmployeeType'
      id: string
      name: string
      email?: string | null
      phoneNumber?: string | null
      employeeCode: string
      status?: string | null
      fullAddress?: {
        __typename?: 'AddressType'
        street?: string | null
        city?: string | null
        state?: string | null
        zipCode?: string | null
        country?: string | null
      } | null
      role?: {__typename?: 'RoleType'; id: string; name: string} | null
      locations?: Array<{
        __typename?: 'LocationType'
        id: string
        name: string
      }> | null
      defaultLocation?: {
        __typename?: 'LocationType'
        id: string
        name: string
      } | null
      assignedStoreGroups?: Array<{
        __typename?: 'StoreGroupType'
        id: string
        name: string
        status: string
        storeGroupCluster: {
          __typename?: 'StoreGroupClusterType'
          id: string
          name: string
          hierarchyLevel: number
        }
        parentStoreGroup?: {
          __typename?: 'StoreGroupType'
          id: string
          name: string
          status: string
          storeGroupCluster: {
            __typename?: 'StoreGroupClusterType'
            id: string
            name: string
            hierarchyLevel: number
          }
        } | null
      }> | null
    }>
  }
}

export type GetAuthMethodQueryVariables = Exact<{
  emailId: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
  token?: InputMaybe<Scalars['String']['input']>
}>

export type GetAuthMethodQuery = {
  __typename?: 'Query'
  getAuthMethod: Array<{
    __typename?: 'AuthMethodType'
    businessId: string
    businessName: string
    authMethod: string
    okta?: {
      __typename?: 'OktaConfigType'
      clientId?: string | null
      issuer?: string | null
    } | null
  }>
}

export type GetOktaConfigFromIssuerQueryVariables = Exact<{
  issuer: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
}>

export type GetOktaConfigFromIssuerQuery = {
  __typename?: 'Query'
  getOktaConfigFromIssuer: Array<{
    __typename?: 'AuthMethodType'
    businessId: string
    businessName: string
    authMethod: string
    okta?: {
      __typename?: 'OktaConfigType'
      clientId?: string | null
      issuer?: string | null
    } | null
  }>
}

export type SendPasswordSetLinkMutationVariables = Exact<{
  email: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type SendPasswordSetLinkMutation = {
  __typename?: 'Mutation'
  sendPasswordSetLink?: any | null
}

export type ValidatePasswordSetTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type ValidatePasswordSetTokenQuery = {
  __typename?: 'Query'
  validatePasswordSetToken?: any | null
}

export type GetAdminUserQueryVariables = Exact<{[key: string]: never}>

export type GetAdminUserQuery = {
  __typename?: 'Query'
  getAdminUser: {
    __typename?: 'AdminUserType'
    id: string
    name: string
    email: string
    phoneNumber?: string | null
  }
}

export type GenieQueryDashboardQueryVariables = Exact<{
  query: Scalars['String']['input']
  chatSessionId: Scalars['String']['input']
  queryType?: InputMaybe<Scalars['String']['input']>
}>

export type GenieQueryDashboardQuery = {
  __typename?: 'Query'
  genieQueryDashboard: {
    __typename?: 'GenieQueryType'
    messageId: string
    queryType: string
    answer?: string | null
    query?: string | null
    status?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
    genieAnalyticsQuery?: {
      __typename?: 'GenieAnalyticsQueryType'
      data?: any | null
      html?: string | null
    } | null
  }
}

export type FetchGenieMessageDashboardQueryVariables = Exact<{
  messageId: Scalars['String']['input']
}>

export type FetchGenieMessageDashboardQuery = {
  __typename?: 'Query'
  fetchGenieMessageDashboard: {
    __typename?: 'GenieQueryType'
    messageId: string
    queryType: string
    answer?: string | null
    query?: string | null
    status?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
    genieAnalyticsQuery?: {
      __typename?: 'GenieAnalyticsQueryType'
      data?: any | null
      html?: string | null
    } | null
  }
}

export type GetInsightQueryVariables = Exact<{
  insightTypes: Scalars['String']['input']
  durationType: Scalars['String']['input']
  storeNumbers?: InputMaybe<Scalars['String']['input']>
  locationIds?: InputMaybe<Scalars['String']['input']>
  employeeCodes?: InputMaybe<Scalars['String']['input']>
  eventTypes?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
}>

export type GetInsightQuery = {
  __typename?: 'Query'
  getInsights: Array<{
    __typename?: 'InsightType'
    type: string
    dashboardId: string
    dashboardTitle: string
    dashboardSubtitle: string
    dashboardBaseUrl: string
    dashboardDurationType: string
    dashboardHeight: number
    dashboardWidth: number
    enableDashboard: boolean
    charts: Array<{
      __typename?: 'ChartConfigType'
      chartId: string
      chartBaseUrl?: string | null
      chartName: string
      category: string
      filter?: any | null
      enableChart: boolean
      xAxis: {
        __typename?: 'ChartAxisType'
        name?: string | null
        type?: string | null
        timeType?: string | null
        label?: string | null
      }
      yAxis: {
        __typename?: 'ChartAxisType'
        name?: string | null
        type?: string | null
        timeType?: string | null
        label?: string | null
      }
    }>
  }>
}

export type GetInsightChartsQueryVariables = Exact<{
  chartCategory: Scalars['String']['input']
  insightType: Scalars['String']['input']
  durationType: Scalars['String']['input']
  storeNumbers?: InputMaybe<Scalars['String']['input']>
  locationIds?: InputMaybe<Scalars['String']['input']>
  employeeCodes?: InputMaybe<Scalars['String']['input']>
  eventTypes?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
}>

export type GetInsightChartsQuery = {
  __typename?: 'Query'
  getInsightCharts: {
    __typename?: 'InsightChartsType'
    type: string
    category: string
    durationType: string
    charts: Array<{
      __typename?: 'ChartConfigType'
      category: string
      chartId: string
      chartBaseUrl?: string | null
      chartName: string
      enableChart: boolean
      filter?: any | null
      xAxis: {
        __typename?: 'ChartAxisType'
        label?: string | null
        name?: string | null
        timeType?: string | null
        type?: string | null
      }
      yAxis: {
        __typename?: 'ChartAxisType'
        label?: string | null
        name?: string | null
        timeType?: string | null
        type?: string | null
      }
    }>
  }
}

export type FetchLocationInventoryQueryVariables = Exact<{
  locationId: Scalars['String']['input']
  description: Scalars['String']['input']
  sku: Scalars['String']['input']
  unitPrice: Scalars['String']['input']
  unitCount: Scalars['String']['input']
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type FetchLocationInventoryQuery = {
  __typename?: 'Query'
  fetchLocationInventory: {
    __typename?: 'InventoryListType'
    totalPageCount: number
    pageIndex: number
    pageSize: number
    inventories: Array<{
      __typename?: 'InventoryDetailsType'
      id: string
      sku: string
      description?: string | null
      unit?: string | null
      unitCount?: string | null
      taxable?: string | null
      sendEmail?: boolean | null
      logoUrl?: string | null
      upc?: string | null
      categoryName?: string | null
      className?: string | null
      subclassName?: string | null
      skuType?: string | null
      ageVerificationRequired?: boolean | null
      requiredMinimumAge?: string | null
      taxDetails: {
        __typename?: 'InvetoryTaxesType'
        taxAmount?: string | null
        taxGroup?: string | null
        taxRate?: string | null
      }
      pricingDetails: {
        __typename?: 'InventoryPricingType'
        currency: string
        currencyMultiplier: string
        discount?: string | null
        total: string
        unitPrice: string
      }
      recommendedProducts?: Array<{
        __typename?: 'InventoryDetailsType'
        sku: string
        description?: string | null
      }> | null
    }>
  }
}

export type GetInventoryByCommandQueryVariables = Exact<{
  command: Scalars['String']['input']
  locationId: Scalars['String']['input']
  genieVersion: Scalars['String']['input']
  chatSessionId?: InputMaybe<Scalars['String']['input']>
}>

export type GetInventoryByCommandQuery = {
  __typename?: 'Query'
  getInventoryByCommand: {
    __typename?: 'GenieProductListType'
    answer?: string | null
    resultType?: string | null
    query?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
    products: Array<{
      __typename?: 'ProductType'
      skuType?: string | null
      sku: string
      unitPrice?: number | null
      unitDiscount?: number | null
      unitTaxAmount?: number | null
      description?: string | null
      unitSubTotal?: number | null
      aisleLocation?: string | null
      logoUrl?: string | null
      promotions?: Array<{
        __typename?: 'PromotionType'
        promotionType: string
        promotionValue: number
        promotionName: string
      }> | null
    }>
  }
}

export type GetLocationsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  storeGroupIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  locationIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetLocationsQuery = {
  __typename?: 'Query'
  getAllLocations: Array<{
    __typename?: 'LocationType'
    id: string
    name: string
    brand: string
    description: string
    storeNumber: string
    timeZone: string
    notificationEmails?: Array<string> | null
    status: string
    address: {
      __typename?: 'AddressType'
      street?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      country?: string | null
    }
    phoneNumbers: {
      __typename?: 'PhoneNumberType'
      contactPhone?: string | null
      supportPhone?: string | null
    }
    operatingHours: Array<{
      __typename?: 'OperatingHourType'
      day?: string | null
      fromHour?: string | null
      toHour?: string | null
    }>
    locationConfig?: {
      __typename?: 'LocationConfigType'
      stripe?: {
        __typename?: 'StripeLocationConfigType'
        displayName?: string | null
        stripeTerminalLocationId?: string | null
      } | null
    } | null
    storeGroups?: Array<{
      __typename?: 'StoreGroupType'
      id: string
      name: string
      status: string
      storeGroupCluster: {
        __typename?: 'StoreGroupClusterType'
        id: string
        name: string
        hierarchyLevel: number
      }
    }> | null
  }>
}

export type GetLocationsWithGroupsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
}>

export type GetLocationsWithGroupsQuery = {
  __typename?: 'Query'
  getAllLocations: Array<{
    __typename?: 'LocationType'
    id: string
    name: string
    storeGroups?: Array<{
      __typename?: 'StoreGroupType'
      id: string
      name: string
      status: string
      storeGroupCluster: {
        __typename?: 'StoreGroupClusterType'
        id: string
        name: string
        hierarchyLevel: number
      }
    }> | null
  }>
}

export type GetOauthApplicationsQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars['String']['input']>
}>

export type GetOauthApplicationsQuery = {
  __typename?: 'Query'
  getOauthApplications: {
    __typename?: 'OAuthApplicationsQueryResponseType'
    locationId?: string | null
    message: string
    success: boolean
    applications?: Array<{
      __typename?: 'OAuthApplicationsType'
      appName?: string | null
      clientId?: string | null
      clientSecret?: string | null
      status?: string | null
    }> | null
  }
}

export type ListOrdersQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  customerId?: InputMaybe<Scalars['String']['input']>
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  storeGroups?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  locationIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type ListOrdersQuery = {
  __typename?: 'Query'
  listOrders: {
    __typename?: 'OrdersType'
    totalPageCount: number
    orders: Array<{
      __typename?: 'OrderType'
      id: string
      orderNumber?: string | null
      amountPaid?: number | null
      createdAt?: any | null
      paymentStatus?: string | null
      totalAmount: number
      currency: string
      register?: string | null
      orderType?: string | null
      fulfillmentStatus?: string | null
      items?: Array<{
        __typename?: 'ItemType'
        sku: string
        description?: string | null
        quantity: number
        taxAmount?: number | null
        totalDiscount?: number | null
        unitPrice?: number | null
        unit?: string | null
        total?: number | null
      }> | null
      subscriptionItems?: Array<{
        __typename?: 'SubscriptionItemType'
        sku: string
        description?: string | null
        quantity: number
        taxAmount?: number | null
        totalDiscount?: number | null
        unitPrice?: number | null
        unit?: string | null
        total?: number | null
        frequency: string
        status?: string | null
      }> | null
      location?: {
        __typename?: 'LocationType'
        id: string
        name: string
        address: {
          __typename?: 'AddressType'
          street?: string | null
          city?: string | null
          state?: string | null
        }
        storeGroups?: Array<{
          __typename?: 'StoreGroupType'
          id: string
          name: string
          storeGroupCluster: {
            __typename?: 'StoreGroupClusterType'
            id: string
            name: string
            hierarchyLevel: number
          }
        }> | null
      } | null
      customer?: {
        __typename?: 'CustomerType'
        firstName?: string | null
        lastName?: string | null
      } | null
      employee?: {
        __typename?: 'EmployeeType'
        id: string
        name: string
        employeeCode: string
      } | null
    }>
  }
}

export type GetDeviceInfoQueryVariables = Exact<{
  deviceId: Scalars['String']['input']
}>

export type GetDeviceInfoQuery = {
  __typename?: 'Query'
  getDeviceInfo: {
    __typename?: 'DeviceInfoType'
    name: string
    id: string
    status: string
    deviceType: string
    numberOfErrors: any
    timeUsed: any
    metadata: any
  }
}

export type GetDeviceActivitiesQueryVariables = Exact<{
  deviceId: Scalars['String']['input']
}>

export type GetDeviceActivitiesQuery = {
  __typename?: 'Query'
  getDeviceActivities: Array<{
    __typename?: 'DeviceActivityType'
    id: string
    time: string
    user: string
    action: string
    description: string
  }>
}

export type GetErrorNotificationsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  live?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetErrorNotificationsQuery = {
  __typename?: 'Query'
  getErrorNotifications: Array<{
    __typename?: 'ErrorNotificationType'
    id: string
    activityId: string
    lastActive: string
    severity: string
    deviceName: string
    registerName: string
    zoneName?: string | null
    serialNumber: string
    issueDescription: string
    action: string
    timeNotified: string
    timeResolved: string
  }>
}

export type GetDeviceDataQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type GetDeviceDataQuery = {
  __typename?: 'Query'
  getDeviceData: Array<{
    __typename?: 'DeviceDataResponseType'
    id: string
    lastActive: string
    deviceName: string
    severity: string
    registerName: string
    zoneName?: string | null
    serialNumber: string
    issueDescription: string
    action: string
    deviceType: string
  }>
}

export type GetBusinessQueryVariables = Exact<{
  businessId: Scalars['String']['input']
}>

export type GetBusinessQuery = {
  __typename?: 'Query'
  getBusiness: {
    __typename?: 'BusinessType'
    id: string
    name: string
    brand: string
    logo: string
    authMethod: string
    featureFlags: any
  }
}

export type GetPeripheralHubNotificationConfigQueryVariables = Exact<{
  employeeId: Scalars['String']['input']
}>

export type GetPeripheralHubNotificationConfigQuery = {
  __typename?: 'Query'
  getPeripheralHubNotificationConfig: {
    __typename?: 'PeripheralHubNotificationsType'
    enabled?: boolean | null
    severity?: string | null
    issue?: string | null
    device?: string | null
    alert?: string | null
    methods: {
      __typename?: 'PeripheralHubNotificationMethodsType'
      email?: boolean | null
      text?: boolean | null
    }
  }
}

export type GetZoneData1QueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type GetZoneData1Query = {
  __typename?: 'Query'
  fetchZones: Array<{
    __typename?: 'ZoneExtended'
    id: string
    name: string
    description: string
    registers: Array<{__typename?: 'RegisterExtended'; id: string}>
  }>
  fetchDevices: Array<{
    __typename?: 'Device'
    id: string
    status: string
    deviceName: string
    metadata?: any | null
  }>
  fetchRegisters: Array<{
    __typename?: 'RegisterExtended'
    id: string
    name: string
    devices: Array<{__typename?: 'Device'; id: string}>
  }>
}

export type DeviceHubVersionMetadataQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  generateUrl: Scalars['Boolean']['input']
}>

export type DeviceHubVersionMetadataQuery = {
  __typename?: 'Query'
  deviceHubVersionMetadata: {
    __typename?: 'DeviceHubVersionInfoType'
    currentVersion?: string | null
    url?: string | null
    hash?: string | null
    versions?: Array<string> | null
    readme?: Array<{
      __typename?: 'DeviceHubReadmeType'
      version?: string | null
      text?: string | null
    }> | null
  }
}

export type AssignCurrentVersionMutationVariables = Exact<{
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
  version: Scalars['String']['input']
  current: Scalars['Boolean']['input']
}>

export type AssignCurrentVersionMutation = {
  __typename?: 'Mutation'
  assignCurrentVersion?: any | null
}

export type AllRulesQueryVariables = Exact<{
  businessId: Scalars['String']['input']
}>

export type AllRulesQuery = {
  __typename?: 'Query'
  allRules: Array<{
    __typename?: 'PromoRuleType'
    businessId: string
    ruleName: string
    ruleType: string
    ruleValue?: string | null
    applyTo?: Array<string> | null
    excludeFrom?: Array<string> | null
    applyToProductGroup?: Array<string> | null
    excludeFromProductGroup?: Array<string> | null
  }>
}

export type GetRuleTypesQueryVariables = Exact<{[key: string]: never}>

export type GetRuleTypesQuery = {
  __typename?: 'Query'
  getRuleTypes: Array<string>
}

export type GetPromotionTypesQueryVariables = Exact<{[key: string]: never}>

export type GetPromotionTypesQuery = {
  __typename?: 'Query'
  getPromotionTypes: Array<string>
}

export type GetProductSkuListQueryVariables = Exact<{[key: string]: never}>

export type GetProductSkuListQuery = {
  __typename?: 'Query'
  getProductSkuList: Array<string>
}

export type GetPromotionSchemaTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPromotionSchemaTypesQuery = {
  __typename?: 'Query'
  getPromotionSchemaTypes: Array<string>
}

export type GetAllPromotionsQueryVariables = Exact<{
  businessId: Scalars['String']['input']
}>

export type GetAllPromotionsQuery = {
  __typename?: 'Query'
  getAllPromotions: Array<{
    __typename?: 'PromotionType'
    id: string
    businessId: string
    active: boolean
    canBeCombined: boolean
    description?: string | null
    endDate: any
    promotionName: string
    promotionType: string
    startDate: any
    promotionValue: number
    priority: number
    promotionSchema: string
    subscriptionType: boolean
    rules?: Array<{
      __typename?: 'PromoRuleType'
      ruleName: string
      ruleType: string
      ruleValue?: string | null
    }> | null
  }>
}

export type GetTotalsForPromotionsQueryVariables = Exact<{
  sku: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  promotionNames: Array<Scalars['String']['input']> | Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type GetTotalsForPromotionsQuery = {
  __typename?: 'Query'
  getTotalsForPromotions: {
    __typename?: 'SampleItem'
    totalAmount: number
    promotionAmount: number
  }
}

export type GetInventoryBySkuAndDiscountQueryVariables = Exact<{
  sku: Scalars['String']['input']
}>

export type GetInventoryBySkuAndDiscountQuery = {
  __typename?: 'Query'
  getInventoryBySkuAndDiscount: {
    __typename?: 'ProductType'
    sku: string
    unitPrice?: number | null
    unitDiscount?: number | null
    unitTaxAmount?: number | null
    description?: string | null
    unitSubTotal?: number | null
    aisleLocation?: string | null
    logoUrl?: string | null
  }
}

export type CartGuestQueryVariables = Exact<{
  cartId: Scalars['String']['input']
}>

export type CartGuestQuery = {
  __typename?: 'Query'
  cartGuest: {
    __typename?: 'CartType'
    id: string
    totalDiscount?: number | null
    subTotal?: number | null
    totalAmount?: number | null
    totalTax?: number | null
    items: Array<{
      __typename?: 'ItemType'
      sku: string
      quantity: number
      unitPrice?: number | null
      unitDiscount?: number | null
      description?: string | null
      logoUrl?: string | null
      unitSubTotal?: number | null
      unitTaxAmount?: number | null
      totalTaxAmount?: number | null
      totalDiscount?: number | null
      total?: number | null
    }>
  }
}

export type GetAllRolesQueryVariables = Exact<{
  businessId: Scalars['String']['input']
}>

export type GetAllRolesQuery = {
  __typename?: 'Query'
  getAllRoles: Array<{
    __typename?: 'RoleType'
    id: string
    name: string
    description: string
    isActive: boolean
    permissions: Array<string>
    createdAt: string
    updatedAt: string
  }>
  getAllPermissions: Array<{
    __typename?: 'PermissionDataType'
    displayName?: string | null
    name: string
    description?: string | null
    category?: string | null
  }>
}

export type ListSecretsQueryVariables = Exact<{[key: string]: never}>

export type ListSecretsQuery = {
  __typename?: 'Query'
  listSecrets: Array<{
    __typename?: 'SecretType'
    secretName: string
    secretValue?: string | null
  }>
}

export type FetchServiceConfigByTypeQueryVariables = Exact<{
  serviceConfigType: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}>

export type FetchServiceConfigByTypeQuery = {
  __typename?: 'Query'
  serviceConfig: {
    __typename?: 'ServiceConfigType'
    type: string
    name?: string | null
    configs?: any | null
    updatedAt?: any | null
  }
}

export type FetchServiceConfigProvidersQueryVariables = Exact<{
  category: Scalars['String']['input']
}>

export type FetchServiceConfigProvidersQuery = {
  __typename?: 'Query'
  serviceConfigProviders: Array<{
    __typename?: 'ServiceConfigProvidersType'
    provider: string
    metadata?: {
      __typename?: 'ServiceConfigProviderMetadataType'
      shopifyMetadata?: {
        __typename?: 'ShopifyMetadataType'
        webhookTopicsToSubscribe?: Array<string> | null
        availableApiVersions?: Array<string> | null
      } | null
    } | null
  }>
}

export type FetchServiceConfigsByCategoryQueryVariables = Exact<{
  category: Scalars['String']['input']
  businessId?: InputMaybe<Scalars['String']['input']>
  locationId?: InputMaybe<Scalars['String']['input']>
}>

export type FetchServiceConfigsByCategoryQuery = {
  __typename?: 'Query'
  serviceConfigsByCategory: Array<{
    __typename?: 'ServiceConfigType'
    type: string
    name?: string | null
    configs?: any | null
    updatedAt?: any | null
  }>
}

export type StoreGroupClusterStatsQueryVariables = Exact<{[key: string]: never}>

export type StoreGroupClusterStatsQuery = {
  __typename?: 'Query'
  storeGroupClusterStats: Array<{
    __typename?: 'StoreGroupClusterStatsType'
    id?: string | null
    storeGroupClusterName: string
    activeCount?: number | null
    draftCount?: number | null
  }>
}

export type StoreGroupsByClusterIdQueryVariables = Exact<{
  storeGroupClusterId: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  fetchOrphanedStoreGroups?: InputMaybe<Scalars['Boolean']['input']>
}>

export type StoreGroupsByClusterIdQuery = {
  __typename?: 'Query'
  storeGroupsByClusterId: Array<{
    __typename?: 'StoreGroupType'
    id: string
    name: string
    status: string
    storeGroupCluster: {
      __typename?: 'StoreGroupClusterType'
      id: string
      name: string
      hierarchyLevel: number
    }
  }>
}

export type ChildStoreGroupsQueryVariables = Exact<{
  storeGroupId: Scalars['String']['input']
}>

export type ChildStoreGroupsQuery = {
  __typename?: 'Query'
  childStoreGroups: Array<{
    __typename?: 'StoreGroupType'
    id: string
    name: string
    status: string
    storeGroupCluster: {
      __typename?: 'StoreGroupClusterType'
      id: string
      name: string
      hierarchyLevel: number
    }
  }>
}

export type StoreGroupsQueryVariables = Exact<{[key: string]: never}>

export type StoreGroupsQuery = {
  __typename?: 'Query'
  storeGroups: Array<
    Array<{
      __typename?: 'StoreGroupType'
      id: string
      name: string
      status: string
      storeGroupCluster: {
        __typename?: 'StoreGroupClusterType'
        id: string
        name: string
        hierarchyLevel: number
      }
      parentStoreGroup?: {
        __typename?: 'StoreGroupType'
        id: string
        name: string
        status: string
        storeGroupCluster: {
          __typename?: 'StoreGroupClusterType'
          id: string
          name: string
          hierarchyLevel: number
        }
      } | null
    }>
  >
}

export type CashTransactionFailuresQueryVariables = Exact<{
  locationId: Scalars['String']['input']
  dateRange: Array<Scalars['String']['input']> | Scalars['String']['input']
  pageIndex?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type CashTransactionFailuresQuery = {
  __typename?: 'Query'
  cashTransactionFailures: {
    __typename?: 'TransactionFailureType'
    totalPageCount: number
    nodes: Array<{
      __typename?: 'CashTransactionFailureType'
      createdAt?: string | null
      error?: string | null
      eventId?: string | null
      eventType?: string | null
      id: string
      payload?: any | null
    }>
  }
}

export type CashEventSubscriptionSubscriptionVariables = Exact<{
  locationId: Scalars['String']['input']
}>

export type CashEventSubscriptionSubscription = {
  __typename?: 'Subscription'
  webCashEvent: {__typename?: 'CashEventResponse'; cashEvent?: string | null}
}

export type TransactionEventSubscriptionSubscriptionVariables = Exact<{
  locationId: Scalars['String']['input']
  businessId: Scalars['String']['input']
}>

export type TransactionEventSubscriptionSubscription = {
  __typename?: 'Subscription'
  webCashEvent: {__typename?: 'CashEventResponse'; cashEvent?: string | null}
}

export type CustomerCartSubscriptionEventsSubscriptionVariables = Exact<{
  token: Scalars['String']['input']
}>

export type CustomerCartSubscriptionEventsSubscription = {
  __typename?: 'Subscription'
  customerCartSubscriptionEvents: {
    __typename?: 'ValidateCustomerSignupTokenType'
    flow: string
    cart?: {
      __typename?: 'CartType'
      id: string
      subscriptionItems: Array<{
        __typename?: 'SubscriptionItemType'
        frequency: string
        status?: string | null
        description?: string | null
        sku: string
        total?: number | null
        quantity: number
      }>
      location: {
        __typename?: 'LocationType'
        id: string
        name: string
        business: {__typename?: 'BusinessType'; id: string; name: string}
      }
    } | null
  }
}

export type GenieQueryEventConsumerAgentSubscriptionVariables = Exact<{
  locationId?: InputMaybe<Scalars['String']['input']>
  chatSessionId?: InputMaybe<Scalars['String']['input']>
  lastReadTimestamp?: InputMaybe<Scalars['DateTime']['input']>
}>

export type GenieQueryEventConsumerAgentSubscription = {
  __typename?: 'Subscription'
  genieQueryEventConsumerAgent: {
    __typename?: 'GenieQueryType'
    messageId: string
    queryType: string
    answer?: string | null
    query?: string | null
    status?: string | null
    chatSessionId?: string | null
    followups?: Array<string> | null
  }
}

export type GenieQueryEventDashboardSubscriptionVariables = Exact<{
  chatSessionId: Scalars['String']['input']
}>

export type GenieQueryEventDashboardSubscription = {
  __typename?: 'Subscription'
  genieQueryEventDashboard: {
    __typename?: 'GenieQueryType'
    answer?: string | null
    chatSessionId?: string | null
    messageId: string
    query?: string | null
    status?: string | null
    queryType: string
    genieAnalyticsQuery?: {
      __typename?: 'GenieAnalyticsQueryType'
      data?: any | null
      html?: string | null
    } | null
  }
}

export type OrderUpdateEventsConsumerAgentSubscriptionVariables = Exact<{
  cartId: Scalars['String']['input']
  businessId: Scalars['String']['input']
  locationId: Scalars['String']['input']
}>

export type OrderUpdateEventsConsumerAgentSubscription = {
  __typename?: 'Subscription'
  orderUpdateEventsConsumerAgent: {
    __typename?: 'OrderUpdateEventType'
    order?: {
      __typename?: 'OrderType'
      id: string
      paymentStatus?: string | null
      refundStatus?: string | null
      totalAmount: number
      currency: string
      amountPaid?: number | null
      amountRefunded?: number | null
    } | null
  }
}

export type OrderUpdateEventsDashboardSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type OrderUpdateEventsDashboardSubscription = {
  __typename?: 'Subscription'
  orderUpdateEventsDashboard: {
    __typename?: 'OrderUpdateEventType'
    order?: {
      __typename?: 'OrderType'
      id: string
      orderNumber?: string | null
      amountPaid?: number | null
      createdAt?: any | null
      paymentStatus?: string | null
      totalAmount: number
      currency: string
      register?: string | null
      orderType?: string | null
      fulfillmentStatus?: string | null
      items?: Array<{
        __typename?: 'ItemType'
        sku: string
        description?: string | null
        quantity: number
        taxAmount?: number | null
        totalDiscount?: number | null
        unitPrice?: number | null
        unit?: string | null
        total?: number | null
      }> | null
      subscriptionItems?: Array<{
        __typename?: 'SubscriptionItemType'
        sku: string
        description?: string | null
        quantity: number
        taxAmount?: number | null
        totalDiscount?: number | null
        unitPrice?: number | null
        unit?: string | null
        total?: number | null
        frequency: string
        status?: string | null
      }> | null
      location?: {
        __typename?: 'LocationType'
        id: string
        name: string
        address: {
          __typename?: 'AddressType'
          street?: string | null
          city?: string | null
          state?: string | null
        }
        storeGroups?: Array<{
          __typename?: 'StoreGroupType'
          id: string
          name: string
          storeGroupCluster: {
            __typename?: 'StoreGroupClusterType'
            id: string
            name: string
            hierarchyLevel: number
          }
        }> | null
      } | null
      customer?: {
        __typename?: 'CustomerType'
        firstName?: string | null
        lastName?: string | null
      } | null
      employee?: {
        __typename?: 'EmployeeType'
        id: string
        name: string
        employeeCode: string
      } | null
    } | null
  }
}

export const CreateBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createBusiness'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'business'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'BusinessDetailsInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createBusiness'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'business'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'business'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'okta'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'oktaClientId'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'oktaIssuer'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'contactDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactEmail'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportEmail'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'featureFlags'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lat'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'long'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateBusinessMutation,
  CreateBusinessMutationVariables
>
export const UpdateBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateBusiness'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'business'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'BusinessDetailsUpdateInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateBusiness'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'business'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'business'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'okta'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'oktaClientId'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'oktaIssuer'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'contactDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactEmail'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportEmail'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'featureFlags'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lat'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'long'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>
export const UpdateBusinessColorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateBusinessColors'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'primary'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'secondary'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateBusinessColors'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'primary'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'primary'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'secondary'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'secondary'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'primary'}},
                {kind: 'Field', name: {kind: 'Name', value: 'secondary'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateBusinessColorsMutation,
  UpdateBusinessColorsMutationVariables
>
export const UpdateOrderEmailReceiptTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateOrderEmailReceiptTemplate'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'orderReceipt'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateOrderEmailReceiptTemplate'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'orderReceipt'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'orderReceipt'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateOrderEmailReceiptTemplateMutation,
  UpdateOrderEmailReceiptTemplateMutationVariables
>
export const ToggleEmailReceiptEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'toggleEmailReceiptEnabled'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'emailReceiptEnabled'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'toggleEmailReceiptEnabled'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'emailReceiptEnabled'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'emailReceiptEnabled'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ToggleEmailReceiptEnabledMutation,
  ToggleEmailReceiptEnabledMutationVariables
>
export const ResetOrderEmailReceiptTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'resetOrderEmailReceiptTemplate'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'resetOrderEmailReceiptTemplate'}
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResetOrderEmailReceiptTemplateMutation,
  ResetOrderEmailReceiptTemplateMutationVariables
>
export const CreateCartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createCart'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createCart'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateCartMutation, CreateCartMutationVariables>
export const CreateCartGuestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createCartGuest'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createCartGuest'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateCartGuestMutation,
  CreateCartGuestMutationVariables
>
export const UpdateCartItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCartItems'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'items'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {kind: 'Name', value: 'ItemInput'}
                }
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'subscriptionItems'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {kind: 'Name', value: 'SubscriptionItemInput'}
                }
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'returnedItems'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {kind: 'Name', value: 'ReturnItemInput'}
                }
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'deliveryAddress'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'AddressInput'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCartItems'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'items'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'items'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'subscriptionItems'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'subscriptionItems'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'returnedItems'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'returnedItems'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'deliveryAddress'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'deliveryAddress'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'quantity'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitSubTotal'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalDiscount'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'total'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalDiscount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subTotal'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalTax'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateCartItemsMutation,
  UpdateCartItemsMutationVariables
>
export const CopyNfcCartToMposDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'copyNfcCartToMpos'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'mposCartId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'nfcCartId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'copyNfcCartToMpos'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'mposCartId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'mposCartId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'nfcCartId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'nfcCartId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CopyNfcCartToMposMutation,
  CopyNfcCartToMposMutationVariables
>
export const VerifyCartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'verifyCart'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'verifyCart'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VerifyCartMutation, VerifyCartMutationVariables>
export const CashPickupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cashPickup'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'eventId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionType'},
                value: {kind: 'StringValue', value: 'debit', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {kind: 'StringValue', value: 'cash_pickup', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'StringValue', value: 'Cash Pickup', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CashPickupMutation, CashPickupMutationVariables>
export const CashDropDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cashDrop'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'eventId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionType'},
                value: {kind: 'StringValue', value: 'credit', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {kind: 'StringValue', value: 'cash_drop', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'StringValue', value: 'Cash Drop', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CashDropMutation, CashDropMutationVariables>
export const CreateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCode'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessDate'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactionType'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactionId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'eventId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCode'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCode'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessDate'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessDate'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactionType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactionId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateTransactionMutation,
  CreateTransactionMutationVariables
>
export const CreateSafeTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createSafeTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fromSafe'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'toSafe'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'eventId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addSafeCashTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fromSafe'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'fromSafe'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'toSafe'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'toSafe'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateSafeTransactionMutation,
  CreateSafeTransactionMutationVariables
>
export const CashTillCloseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cashTillClose'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'cashVariance'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessDate'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'reasonCode'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'denominationBreakdown'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {kind: 'Name', value: 'DenominationInput'}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'reasonDescription'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'otherDenominationCurrency'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'eventId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessDate'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessDate'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cashVariance'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'cashVariance'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reasonCode'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reasonCode'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionType'},
                value: {kind: 'StringValue', value: 'debit', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {kind: 'StringValue', value: 'reconcile', block: false}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'StringValue',
                  value: 'Reconcile Till',
                  block: false
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'denominationBreakdown'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'denominationBreakdown'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'otherDenominationCurrency'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'otherDenominationCurrency'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reasonDescription'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reasonDescription'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CashTillCloseMutation,
  CashTillCloseMutationVariables
>
export const CashDepositDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cashDeposit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createBankDeposit'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CashDepositMutation, CashDepositMutationVariables>
export const SafeReconcileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'safeReconcile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'safeType'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'reasonCode'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'denominationBreakdown'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {kind: 'Name', value: 'DenominationInput'}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'reasonDescription'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'otherDenominationCurrency'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createStoreTransaction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'safeType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'safeType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reasonCode'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reasonCode'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'denominationBreakdown'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'denominationBreakdown'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reasonDescription'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reasonDescription'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'otherDenominationCurrency'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'otherDenominationCurrency'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dateCreated'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dateModified'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SafeReconcileMutation,
  SafeReconcileMutationVariables
>
export const CashDepositUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cashDepositUpdate'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'depositNumber'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'depositReceipt'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Upload'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateBankDeposit'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'depositNumber'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'depositNumber'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'depositReceipt'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'depositReceipt'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'depositReceipt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CashDepositUpdateMutation,
  CashDepositUpdateMutationVariables
>
export const DownloadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DownloadFile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'downloadDepositReceipt'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'fileContent'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fileName'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DownloadFileMutation,
  DownloadFileMutationVariables
>
export const GenerateAtlasAuthTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'generateAtlasAuthToken'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'generateAtlasAuthToken'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenerateAtlasAuthTokenMutation,
  GenerateAtlasAuthTokenMutationVariables
>
export const BulkCashTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'bulkCashTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactions'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {kind: 'Name', value: 'CashTransactionInputType'}
                }
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bulkTransactionEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactions'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactions'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BulkCashTransactionMutation,
  BulkCashTransactionMutationVariables
>
export const AddCustomerForConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'addCustomerForConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customer'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CustomerInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addCustomerForConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customer'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddCustomerForConsumerAgentMutation,
  AddCustomerForConsumerAgentMutationVariables
>
export const LoginCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'loginCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loginCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'password'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'customer'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastName'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'token'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  LoginCustomerMutation,
  LoginCustomerMutationVariables
>
export const AddCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'addCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customer'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CustomerInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customer'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddCustomerMutation, AddCustomerMutationVariables>
export const UpdateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customer'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CustomerInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customer'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>
export const ValidateCustomerSignupTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'validateCustomerSignupToken'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'checkUsage'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'validateCustomerSignupToken'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'checkUsage'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'checkUsage'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'flow'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cart'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customer'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'firstName'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'lastName'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'email'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'addresses'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'street'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'city'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'state'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'zipCode'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'country'}
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'deliveryAddress'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'street'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'city'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'state'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'zipCode'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'country'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'subscriptionItems'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'frequency'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'location'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'business'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ValidateCustomerSignupTokenMutation,
  ValidateCustomerSignupTokenMutationVariables
>
export const SignupCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signupCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customer'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CustomerInput'}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signupCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customer'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'customer'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastName'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'token'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SignupCustomerMutation,
  SignupCustomerMutationVariables
>
export const CustomerSigninDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'customerSignin'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerSignin'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'password'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'customer'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastName'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'token'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CustomerSigninMutation,
  CustomerSigninMutationVariables
>
export const ApproveItemSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'approveItemSubscription'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'approveItemSubscription'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sku'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ApproveItemSubscriptionMutation,
  ApproveItemSubscriptionMutationVariables
>
export const GenerateGenieChatSessionIdConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'generateGenieChatSessionIdConsumerAgent'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'generateGenieChatSessionIdConsumerAgent'
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenerateGenieChatSessionIdConsumerAgentMutation,
  GenerateGenieChatSessionIdConsumerAgentMutationVariables
>
export const DeleteChatHistoryForConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteChatHistoryForConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteChatHistoryForConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteChatHistoryForConsumerAgentMutation,
  DeleteChatHistoryForConsumerAgentMutationVariables
>
export const AddCustomerAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'addCustomerAddress'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CustomerAddressInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addCustomerAddress'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'address'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'address'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'addresses'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'id'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddCustomerAddressMutation,
  AddCustomerAddressMutationVariables
>
export const UpdateCartAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCartAddress'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'AddressInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCartAddress'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'address'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'address'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateCartAddressMutation,
  UpdateCartAddressMutationVariables
>
export const CreateEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createEmployee'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'employee'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'EmployeeInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createEmployee'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employee'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employee'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phoneNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'employeeCode'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'fullAddress'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'role'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locations'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'defaultLocation'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>
export const UpdateEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateEmployee'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'employee'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'EmployeeInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateEmployee'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employee'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employee'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phoneNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'employeeCode'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'fullAddress'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'role'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locations'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'defaultLocation'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>
export const SendOtpForPasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'sendOtpForPasswordReset'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'sendOtpForPasswordReset'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SendOtpForPasswordResetMutation,
  SendOtpForPasswordResetMutationVariables
>
export const ResetPasswordWithOtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'resetPasswordWithOtp'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'otp'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pin'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'resetPasswordWithOtp'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'otp'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'otp'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'password'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pin'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'pin'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResetPasswordWithOtpMutation,
  ResetPasswordWithOtpMutationVariables
>
export const ResendOtpForPasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'resendOtpForPasswordReset'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'resendOtpForPasswordReset'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResendOtpForPasswordResetMutation,
  ResendOtpForPasswordResetMutationVariables
>
export const GenerateGenieChatSessionIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'GenerateGenieChatSessionId'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'generateGenieChatSessionId'}
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenerateGenieChatSessionIdMutation,
  GenerateGenieChatSessionIdMutationVariables
>
export const CreateInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createInventory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'inventory'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'InventoryInput'}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createInventory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'inventory'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'inventory'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pricingDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'currencyMultiplier'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'discount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'taxDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'taxAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taxGroup'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taxRate'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'unit'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'upc'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'locationId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'taxable'}},
                {kind: 'Field', name: {kind: 'Name', value: 'skuType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'className'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subclassName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'categoryName'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateInventoryMutation,
  CreateInventoryMutationVariables
>
export const UpdateInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateInventory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'inventory'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'InventoryInput'}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateInventory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'inventory'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'inventory'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pricingDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'currencyMultiplier'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'discount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'taxDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'taxAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taxGroup'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taxRate'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'unit'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'upc'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'locationId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'taxable'}},
                {kind: 'Field', name: {kind: 'Name', value: 'skuType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'className'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subclassName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'categoryName'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateInventoryMutation,
  UpdateInventoryMutationVariables
>
export const DeleteInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteInventory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteInventory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteInventoryMutation,
  DeleteInventoryMutationVariables
>
export const AddLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'addLocation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'LocationInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addLocation'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'location'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phoneNumbers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'operatingHours'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'day'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fromHour'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'toHour'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locationConfig'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripe'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'displayName'}
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stripeTerminalLocationId'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'notificationEmails'}
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddLocationMutation, AddLocationMutationVariables>
export const UpdateLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateLocation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'location'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'LocationInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateLocation'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'location'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'location'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phoneNumbers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'operatingHours'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'day'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fromHour'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'toHour'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locationConfig'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripe'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'displayName'}
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stripeTerminalLocationId'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'notificationEmails'}
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>
export const LocationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'locationConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'LocationConfigInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'locationConfig'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  LocationConfigMutation,
  LocationConfigMutationVariables
>
export const DeactivateLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deactivateLocation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deactivateLocation'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phoneNumbers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'operatingHours'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'day'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fromHour'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'toHour'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locationConfig'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripe'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'displayName'}
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stripeTerminalLocationId'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeactivateLocationMutation,
  DeactivateLocationMutationVariables
>
export const ActivateLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'activateLocation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'activateLocation'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phoneNumbers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'operatingHours'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'day'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fromHour'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'toHour'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locationConfig'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripe'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'displayName'}
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stripeTerminalLocationId'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ActivateLocationMutation,
  ActivateLocationMutationVariables
>
export const VerifyOktaTokenAndGenerateSessionTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'VerifyOktaTokenAndGenerateSessionToken'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          defaultValue: {kind: 'NullValue'}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'accessToken'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'idToken'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'oktaClientId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'oktaIssuer'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'verifyOktaTokenAndGenerateSessionToken'
            },
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'accessToken'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'accessToken'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'idToken'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'idToken'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'oktaClientId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'oktaClientId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'oktaIssuer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'oktaIssuer'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  VerifyOktaTokenAndGenerateSessionTokenMutation,
  VerifyOktaTokenAndGenerateSessionTokenMutationVariables
>
export const LoginUserForDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'loginUserForDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loginUserForDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'password'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'toteAccessToken'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mfaEnabled'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  LoginUserForDashboardMutation,
  LoginUserForDashboardMutationVariables
>
export const LoginGuestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'loginGuest'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loginGuest'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'businessConfigs'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripePublishableKey'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'location'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'business'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'configurations'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'colors'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'primary'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'secondary'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LoginGuestMutation, LoginGuestMutationVariables>
export const VerifyOtpAndGenerateSessionTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'verifyOtpAndGenerateSessionToken'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'otp'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'verifyOtpAndGenerateSessionToken'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'otp'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'otp'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'toteAccessToken'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  VerifyOtpAndGenerateSessionTokenMutation,
  VerifyOtpAndGenerateSessionTokenMutationVariables
>
export const ResendOtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'resendOtp'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'resendOtp'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ResendOtpMutation, ResendOtpMutationVariables>
export const VerifyAdminOktaTokenAndGenerateSessionTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'VerifyAdminOktaTokenAndGenerateSessionToken'
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'accessToken'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'idToken'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'oktaClientId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'oktaIssuer'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'verifyAdminOktaTokenAndGenerateSessionToken'
            },
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'accessToken'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'accessToken'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'idToken'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'idToken'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'oktaClientId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'oktaClientId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'oktaIssuer'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'oktaIssuer'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  VerifyAdminOktaTokenAndGenerateSessionTokenMutation,
  VerifyAdminOktaTokenAndGenerateSessionTokenMutationVariables
>
export const GenerateOauthApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'generateOauthApplications'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'appName'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'generateOauthApplications'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'appName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'appName'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'appName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'clientId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'clientSecret'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenerateOauthApplicationsMutation,
  GenerateOauthApplicationsMutationVariables
>
export const CreatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPaymentIntent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPaymentIntent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
>
export const PlaceOrderConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'placeOrderConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'currency'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'paymentMethod'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'paymentGateway'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'paymentGatewayReferenceId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'nonce'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'placeOrderConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cartId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'cartId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'currency'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'currency'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'amount'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'amount'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'paymentMethod'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'paymentMethod'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'paymentGateway'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'paymentGateway'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'paymentGatewayReferenceId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'paymentGatewayReferenceId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'nonce'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'nonce'}
                      }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'requestContext'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'businessId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'businessId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'locationId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'locationId'}
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amountPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountRefunded'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'refundStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalAmount'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'orderId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentMethod'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentGatewayReferenceId'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentMethodDetails'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'type'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'card'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'last4'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'brand'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'fingerprint'}
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  PlaceOrderConsumerAgentMutation,
  PlaceOrderConsumerAgentMutationVariables
>
export const OrderPaymentStatusConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'orderPaymentStatusConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'orderId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'paymentId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'orderPaymentStatusConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'orderId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'orderId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'paymentId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'paymentId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'requestContext'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'businessId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'businessId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'locationId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'locationId'}
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'refundStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountRefunded'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  OrderPaymentStatusConsumerAgentMutation,
  OrderPaymentStatusConsumerAgentMutationVariables
>
export const AddZoneDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AddZoneData'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'devices'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'registers'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addZoneData'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'devices'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'devices'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'registers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'registers'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddZoneDataMutation, AddZoneDataMutationVariables>
export const UpdateZoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdateZone'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'zoneId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'devices'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'registers'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateZone'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'zoneId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'zoneId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'devices'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'devices'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'registers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'registers'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateZoneMutation, UpdateZoneMutationVariables>
export const ResolveErrorNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'ResolveErrorNotification'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'activityId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'resolveErrorNotification'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'activityId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'activityId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResolveErrorNotificationMutation,
  ResolveErrorNotificationMutationVariables
>
export const DeleteZoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DeleteZone'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'zoneId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteZone'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'zoneId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'zoneId'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteZoneMutation, DeleteZoneMutationVariables>
export const UpdatePeripheralHubNotificationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePeripheralHubNotificationConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'notificationConfiguration'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'PeripheralHubNotificationsInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updatePeripheralHubNotificationConfig'
            },
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'notificationConfiguration'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'notificationConfiguration'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdatePeripheralHubNotificationConfigMutation,
  UpdatePeripheralHubNotificationConfigMutationVariables
>
export const Create_RuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CREATE_RULE'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ruleName'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ruleType'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'ruleValue'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'applyTo'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'excludeFrom'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'applyToProductGroup'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'excludeFromProductGroup'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createRule'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ruleName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'ruleName'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ruleType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'ruleType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ruleValue'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'ruleValue'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'applyTo'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'applyTo'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'excludeFrom'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'excludeFrom'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'applyToProductGroup'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'applyToProductGroup'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'excludeFromProductGroup'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'excludeFromProductGroup'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleValue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'applyTo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'excludeFrom'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'applyToProductGroup'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'excludeFromProductGroup'}
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<Create_RuleMutation, Create_RuleMutationVariables>
export const CreatePromotionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPromotion'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'active'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'canBeCombined'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endDate'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'priority'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionName'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'rules'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'startDate'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionValue'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionSchema'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'subscriptionType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPromotion'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'active'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'active'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'canBeCombined'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'canBeCombined'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endDate'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'endDate'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'priority'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'priority'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionName'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'rules'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'rules'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startDate'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'startDate'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionValue'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionValue'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionSchema'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionSchema'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'subscriptionType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'subscriptionType'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'active'}},
                {kind: 'Field', name: {kind: 'Name', value: 'canBeCombined'}},
                {kind: 'Field', name: {kind: 'Name', value: 'endDate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'priority'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'startDate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionValue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionSchema'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'subscriptionType'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'rules'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleValue'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreatePromotionMutation,
  CreatePromotionMutationVariables
>
export const AddPromotionToSkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'addPromotionToSku'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionName'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addPromotionToSku'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionName'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sku'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
                {kind: 'Field', name: {kind: 'Name', value: 'message'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddPromotionToSkuMutation,
  AddPromotionToSkuMutationVariables
>
export const CreateRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'permissions'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'permissions'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'permissions'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateRoleMutation, CreateRoleMutationVariables>
export const UpdateRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'isActive'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'permissions'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'isActive'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'isActive'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'permissions'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'permissions'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>
export const DeleteRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>
export const AssignRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'assignRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'roleId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'assignRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'roleId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'roleId'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AssignRoleMutation, AssignRoleMutationVariables>
export const AddSecretDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AddSecret'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'secretName'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'secretValue'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'addSecret'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'secretName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'secretName'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'secretValue'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'secretValue'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddSecretMutation, AddSecretMutationVariables>
export const DeleteSecretDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DeleteSecret'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'secretName'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteSecret'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'secretName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'secretName'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteSecretMutation,
  DeleteSecretMutationVariables
>
export const AddUpdateServiceConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'AddUpdateServiceConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ServiceConfigInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'serviceConfig'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddUpdateServiceConfigMutation,
  AddUpdateServiceConfigMutationVariables
>
export const VerifyServiceConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'VerifyServiceConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ServiceConfigInput'}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'verifyServiceConfig'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  VerifyServiceConfigMutation,
  VerifyServiceConfigMutationVariables
>
export const RenameStoreGroupClusterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'RenameStoreGroupCluster'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupClusterId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'newClusterName'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'renameStoreGroupCluster'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroupClusterId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroupClusterId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'newClusterName'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'newClusterName'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'hierarchyLevel'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RenameStoreGroupClusterMutation,
  RenameStoreGroupClusterMutationVariables
>
export const CreateStoreGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CreateStoreGroup'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupClusterId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'childStoreGroupIds'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'childLocationIds'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createStoreGroup'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'name'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'name'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'status'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'status'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'storeGroupClusterId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'storeGroupClusterId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'childStoreGroupIds'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'childStoreGroupIds'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'childLocationIds'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'childLocationIds'}
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupCluster'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateStoreGroupMutation,
  CreateStoreGroupMutationVariables
>
export const UpdateStoreGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdateStoreGroup'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'childStoreGroupIds'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'childLocationIds'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateStoreGroup'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'id'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'id'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'name'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'name'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'status'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'status'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'childStoreGroupIds'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'childStoreGroupIds'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'childLocationIds'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'childLocationIds'}
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupCluster'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateStoreGroupMutation,
  UpdateStoreGroupMutationVariables
>
export const GetBusinessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getBusinesses'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getBusinesses'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'okta'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'oktaClientId'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'oktaIssuer'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'contactDetails'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactEmail'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportEmail'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'featureFlags'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lat'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'long'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupClusters'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBusinessesQuery, GetBusinessesQueryVariables>
export const GetAllRolesEmployeesLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAllRolesEmployeesLocations'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'fetchAllEmployees'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllRoles'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
                {kind: 'Field', name: {kind: 'Name', value: 'permissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}}
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllEmployees'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fetchAll'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'fetchAllEmployees'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'employees'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'phoneNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employeeCode'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fullAddress'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'street'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'city'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'state'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'zipCode'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'country'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'role'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'permissions'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'toteSuperAdmin'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalCount'}}
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllLocations'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetAllRolesEmployeesLocationsQuery,
  GetAllRolesEmployeesLocationsQueryVariables
>
export const GetOrderReceiptTemplateConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getOrderReceiptTemplateConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getBusiness'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'configurations'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'emailReceiptEnabled'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'templates'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'orderReceipt'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetOrderReceiptTemplateConfigQuery,
  GetOrderReceiptTemplateConfigQueryVariables
>
export const Till_TransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'TILL_TRANSACTIONS'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'date'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'tillTransactions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'date'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'date'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'nodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'date'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'openTime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'closeTime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'netCash'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'till'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalCash'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'register'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employeeCode'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employee'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'defaultStartingFloat'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'tillAmountUpperLimit'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'registerTotalCash'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'registerNetCash'}},
                {kind: 'Field', name: {kind: 'Name', value: 'activeTill'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  Till_TransactionsQuery,
  Till_TransactionsQueryVariables
>
export const Transaction_SummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'TRANSACTION_SUMMARY'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'date'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'tillTransactionsSummary'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'date'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'date'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cash'}},
                {kind: 'Field', name: {kind: 'Name', value: 'sale'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cashAdjustment'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cashPickup'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paidIn'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paidOut'}},
                {kind: 'Field', name: {kind: 'Name', value: 'tillFloat'}},
                {kind: 'Field', name: {kind: 'Name', value: 'open'}},
                {kind: 'Field', name: {kind: 'Name', value: 'tillClose'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reconcile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'transfer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'refund'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cashDrop'}},
                {kind: 'Field', name: {kind: 'Name', value: 'startingDate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'endingDate'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'registerTotalCash'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'safeTotalCash'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeTotalCash'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reserveTotalCash'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  Transaction_SummaryQuery,
  Transaction_SummaryQueryVariables
>
export const TransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'TRANSACTIONS'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'register'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'till'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dateRange'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCode'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactionType'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cashTransactions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'till'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'till'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'register'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'register'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dateRange'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCode'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCode'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactionType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'totalPageCount'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'nodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'closeTime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'eventTime'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'dateCreated'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employeeCode'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'eventType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'openTime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'register'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeNumber'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'till'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactionType'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactionId'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transferTo'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transferToTill'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactionTime'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'eventId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'safeType'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'isSafeTransaction'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'source'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employee'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>
export const Get_StatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GET_STATUS'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getTillStatus'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<Get_StatusQuery, Get_StatusQueryVariables>
export const Bank_DepositsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'BANK_DEPOSITS'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dateRange'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getBankDeposits'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dateRange'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'totalPageCount'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'nodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'depositNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'depositReceipt'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employee'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<Bank_DepositsQuery, Bank_DepositsQueryVariables>
export const Aggregrate_Cash_TransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AGGREGRATE_CASH_TRANSACTIONS'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeNumbers'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactionTimeRange'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventType'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'aggregrateCashTransactions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeNumbers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeNumbers'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionTimeRange'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactionTimeRange'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventType'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cashSalesStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'cashSales'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'averageTransactionValueStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'averageTransactionValue'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cashVarianceStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cashVariance'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  Aggregrate_Cash_TransactionsQuery,
  Aggregrate_Cash_TransactionsQueryVariables
>
export const Variance_ReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'VARIANCE_REPORT'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeNumbers'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'transactionTimeRange'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCodes'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventType'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'aggregrateCashTransactions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeNumbers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeNumbers'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'transactionTimeRange'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'transactionTimeRange'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCodes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCodes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventType'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'totalExpectedCashStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalExpectedCash'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'totalActualCashStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalActualCash'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'totalVarianceStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalVariance'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'averageVariancePercentageStats'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'averageVariancePercentage'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'percentageChange'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  Variance_ReportQuery,
  Variance_ReportQueryVariables
>
export const GetRegisterTillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getRegisterTills'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchRegisterList'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}}
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchTills'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetRegisterTillsQuery,
  GetRegisterTillsQueryVariables
>
export const ListCustomersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'listCustomers'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'listCustomers'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'avatar'}},
                {kind: 'Field', name: {kind: 'Name', value: 'barcode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'preferences'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ListCustomersQuery, ListCustomersQueryVariables>
export const GenieQueryConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GenieQueryConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'query'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'queryType'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'genieQueryConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'query'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'query'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'queryType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'queryType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenieQueryConsumerAgentQuery,
  GenieQueryConsumerAgentQueryVariables
>
export const FetchGenieMessageConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'FetchGenieMessageConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'messageId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchGenieMessageConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'messageId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'messageId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'genieChatQuery'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'products'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'skuType'}
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitDiscount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitTaxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitSubTotal'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'aisleLocation'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'logoUrl'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotions'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'description'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'promotionType'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'promotionValue'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'promotionName'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'active'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'businessId'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'canBeCombined'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'startDate'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'endDate'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'priority'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionType'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchGenieMessageConsumerAgentQuery,
  FetchGenieMessageConsumerAgentQueryVariables
>
export const FetchCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'fetchCustomer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchCustomer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'avatar'}},
                {kind: 'Field', name: {kind: 'Name', value: 'barcode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'preferences'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<FetchCustomerQuery, FetchCustomerQueryVariables>
export const PredefinedChatQueryConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'PredefinedChatQueryConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'queryId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'predefinedChatQueryConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'queryId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'queryId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'skuType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitSubTotal'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'aisleLocation'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'promotions'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionType'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionValue'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionName'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'active'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'businessId'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'canBeCombined'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'startDate'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'endDate'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'priority'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'subscriptionType'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryId'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  PredefinedChatQueryConsumerAgentQuery,
  PredefinedChatQueryConsumerAgentQueryVariables
>
export const GetEmployeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getEmployee'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getEmployee'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locations'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'address'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'street'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'city'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'state'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'zipCode'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'country'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'locationConfig'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'cashManagement'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'tillAmountUpperLimit'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storeFloatAmount'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroups'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'storeGroupCluster'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hierarchyLevel'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'business'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'logo'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'featureFlags'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'configurations'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'colors'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'primary'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'secondary'}
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroupClusters'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'hierarchyLevel'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'role'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'permissions'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'toteSuperAdmin'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'assignedStoreGroups'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroupCluster'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'hierarchyLevel'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'parentStoreGroup'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'storeGroupCluster'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hierarchyLevel'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetEmployeeQuery, GetEmployeeQueryVariables>
export const GetAllEmployeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAllEmployees'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCode'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fetchAll'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllEmployees'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'role'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'role'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCode'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCode'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fetchAll'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'fetchAll'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroupIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroupIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'employees'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'phoneNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employeeCode'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fullAddress'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'street'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'city'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'state'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'zipCode'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'country'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'role'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'locations'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'defaultLocation'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'assignedStoreGroups'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'storeGroupCluster'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hierarchyLevel'
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'parentStoreGroup'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'status'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storeGroupCluster'
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'id'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'name'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hierarchyLevel'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalCount'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetAllEmployeesQuery,
  GetAllEmployeesQueryVariables
>
export const GetAuthMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAuthMethod'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'emailId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAuthMethod'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'emailId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'emailId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'okta'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'clientId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'issuer'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAuthMethodQuery, GetAuthMethodQueryVariables>
export const GetOktaConfigFromIssuerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getOktaConfigFromIssuer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'issuer'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getOktaConfigFromIssuer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'issuer'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'issuer'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'okta'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'clientId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'issuer'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetOktaConfigFromIssuerQuery,
  GetOktaConfigFromIssuerQueryVariables
>
export const SendPasswordSetLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'sendPasswordSetLink'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'sendPasswordSetLink'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SendPasswordSetLinkMutation,
  SendPasswordSetLinkMutationVariables
>
export const ValidatePasswordSetTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'validatePasswordSetToken'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'validatePasswordSetToken'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ValidatePasswordSetTokenQuery,
  ValidatePasswordSetTokenQueryVariables
>
export const GetAdminUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAdminUser'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAdminUser'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phoneNumber'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAdminUserQuery, GetAdminUserQueryVariables>
export const GenieQueryDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GenieQueryDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'query'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'queryType'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'genieQueryDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'query'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'query'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'queryType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'queryType'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'genieAnalyticsQuery'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'data'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'html'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenieQueryDashboardQuery,
  GenieQueryDashboardQueryVariables
>
export const FetchGenieMessageDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'FetchGenieMessageDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'messageId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchGenieMessageDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'messageId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'messageId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'genieAnalyticsQuery'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'data'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'html'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchGenieMessageDashboardQuery,
  FetchGenieMessageDashboardQueryVariables
>
export const GetInsightDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getInsight'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'insightTypes'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'durationType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeNumbers'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCodes'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventTypes'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'duration'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'insightTypes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'insightTypes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'durationType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'durationType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeNumbers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeNumbers'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCodes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCodes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventTypes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventTypes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'duration'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'duration'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardTitle'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardSubtitle'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardBaseUrl'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardDurationType'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardHeight'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardWidth'}},
                {kind: 'Field', name: {kind: 'Name', value: 'enableDashboard'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'charts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'chartId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'chartBaseUrl'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'chartName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'xAxis'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'type'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'timeType'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'label'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'yAxis'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'type'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'timeType'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'label'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'filter'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'enableChart'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetInsightQuery, GetInsightQueryVariables>
export const GetInsightChartsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getInsightCharts'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chartCategory'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'insightType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'durationType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeNumbers'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeCodes'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'eventTypes'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'duration'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getInsightCharts'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chartCategory'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chartCategory'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'insightType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'insightType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'durationType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'durationType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeNumbers'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeNumbers'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeCodes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeCodes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'eventTypes'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'eventTypes'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'duration'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'duration'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'charts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'chartId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'chartBaseUrl'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'chartName'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'enableChart'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'xAxis'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'label'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'timeType'}
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}}
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'yAxis'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'label'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'timeType'}
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}}
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'filter'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                {kind: 'Field', name: {kind: 'Name', value: 'durationType'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetInsightChartsQuery,
  GetInsightChartsQueryVariables
>
export const FetchLocationInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'fetchLocationInventory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'description'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'unitPrice'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'unitCount'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchLocationInventory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'description'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sku'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitPrice'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'unitPrice'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitCount'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'unitCount'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'inventories'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'unit'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitCount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taxable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'sendEmail'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'taxDetails'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxGroup'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxRate'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pricingDetails'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'currency'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'currencyMultiplier'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'discount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'upc'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'categoryName'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'className'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'subclassName'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'skuType'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'ageVerificationRequired'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requiredMinimumAge'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'recommendedProducts'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPageCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pageIndex'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pageSize'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchLocationInventoryQuery,
  FetchLocationInventoryQueryVariables
>
export const GetInventoryByCommandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getInventoryByCommand'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'command'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'genieVersion'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getInventoryByCommand'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'command'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'command'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'genieVersion'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'genieVersion'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'skuType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitSubTotal'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'aisleLocation'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'promotions'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionType'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionValue'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'promotionName'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'resultType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetInventoryByCommandQuery,
  GetInventoryByCommandQueryVariables
>
export const GetLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getLocations'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllLocations'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroupIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroupIds'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'address'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'street'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'zipCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'country'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phoneNumbers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'contactPhone'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'supportPhone'}
                      }
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'storeNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'operatingHours'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'day'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fromHour'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'toHour'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'timeZone'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'locationConfig'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'stripe'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'displayName'}
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stripeTerminalLocationId'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'notificationEmails'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroups'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroupCluster'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'hierarchyLevel'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLocationsQuery, GetLocationsQueryVariables>
export const GetLocationsWithGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getLocationsWithGroups'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllLocations'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroups'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroupCluster'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'hierarchyLevel'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetLocationsWithGroupsQuery,
  GetLocationsWithGroupsQueryVariables
>
export const GetOauthApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getOauthApplications'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getOauthApplications'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'applications'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'appName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'clientId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'clientSecret'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'locationId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                {kind: 'Field', name: {kind: 'Name', value: 'success'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetOauthApplicationsQuery,
  GetOauthApplicationsQueryVariables
>
export const ListOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'listOrders'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'customerId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroups'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationIds'}
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'listOrders'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'customerId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroups'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroups'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationIds'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationIds'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'totalPageCount'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'orders'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'orderNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'amountPaid'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentStatus'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalAmount'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'items'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'totalDiscount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unit'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'subscriptionItems'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'totalDiscount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unit'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'frequency'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'location'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'street'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'city'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'state'}
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'storeGroups'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storeGroupCluster'
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'id'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'name'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hierarchyLevel'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customer'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'firstName'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'lastName'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employee'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'employeeCode'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'register'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'orderType'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fulfillmentStatus'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ListOrdersQuery, ListOrdersQueryVariables>
export const GetDeviceInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getDeviceInfo'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'deviceId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getDeviceInfo'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'deviceId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'deviceId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'deviceType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfErrors'}},
                {kind: 'Field', name: {kind: 'Name', value: 'timeUsed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'metadata'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>
export const GetDeviceActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getDeviceActivities'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'deviceId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getDeviceActivities'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'deviceId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'deviceId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'user'}},
                {kind: 'Field', name: {kind: 'Name', value: 'action'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetDeviceActivitiesQuery,
  GetDeviceActivitiesQueryVariables
>
export const GetErrorNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getErrorNotifications'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'live'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getErrorNotifications'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'live'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'live'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'activityId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastActive'}},
                {kind: 'Field', name: {kind: 'Name', value: 'severity'}},
                {kind: 'Field', name: {kind: 'Name', value: 'deviceName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'registerName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'zoneName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'serialNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'issueDescription'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'action'}},
                {kind: 'Field', name: {kind: 'Name', value: 'timeNotified'}},
                {kind: 'Field', name: {kind: 'Name', value: 'timeResolved'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetErrorNotificationsQuery,
  GetErrorNotificationsQueryVariables
>
export const GetDeviceDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getDeviceData'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getDeviceData'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastActive'}},
                {kind: 'Field', name: {kind: 'Name', value: 'deviceName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'severity'}},
                {kind: 'Field', name: {kind: 'Name', value: 'registerName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'zoneName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'serialNumber'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'issueDescription'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'action'}},
                {kind: 'Field', name: {kind: 'Name', value: 'deviceType'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetDeviceDataQuery, GetDeviceDataQueryVariables>
export const GetBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getBusiness'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getBusiness'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'authMethod'}},
                {kind: 'Field', name: {kind: 'Name', value: 'featureFlags'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBusinessQuery, GetBusinessQueryVariables>
export const GetPeripheralHubNotificationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getPeripheralHubNotificationConfig'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'employeeId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPeripheralHubNotificationConfig'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'employeeId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'employeeId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
                {kind: 'Field', name: {kind: 'Name', value: 'severity'}},
                {kind: 'Field', name: {kind: 'Name', value: 'issue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'device'}},
                {kind: 'Field', name: {kind: 'Name', value: 'alert'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'methods'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'text'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPeripheralHubNotificationConfigQuery,
  GetPeripheralHubNotificationConfigQueryVariables
>
export const GetZoneData1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getZoneData1'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchZones'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'registers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}}
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchDevices'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'deviceName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'metadata'}}
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'fetchRegisters'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'devices'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetZoneData1Query, GetZoneData1QueryVariables>
export const DeviceHubVersionMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'deviceHubVersionMetadata'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'generateUrl'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deviceHubVersionMetadata'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'generateUrl'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'generateUrl'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'currentVersion'}},
                {kind: 'Field', name: {kind: 'Name', value: 'url'}},
                {kind: 'Field', name: {kind: 'Name', value: 'hash'}},
                {kind: 'Field', name: {kind: 'Name', value: 'versions'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'readme'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'version'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'text'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeviceHubVersionMetadataQuery,
  DeviceHubVersionMetadataQueryVariables
>
export const AssignCurrentVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'assignCurrentVersion'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'version'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'current'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'assignCurrentVersion'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'version'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'version'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'current'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'current'}
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AssignCurrentVersionMutation,
  AssignCurrentVersionMutationVariables
>
export const AllRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'allRules'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'allRules'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ruleValue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'applyTo'}},
                {kind: 'Field', name: {kind: 'Name', value: 'excludeFrom'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'applyToProductGroup'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'excludeFromProductGroup'}
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllRulesQuery, AllRulesQueryVariables>
export const GetRuleTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getRuleTypes'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'getRuleTypes'}}
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRuleTypesQuery, GetRuleTypesQueryVariables>
export const GetPromotionTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getPromotionTypes'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'getPromotionTypes'}}
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPromotionTypesQuery,
  GetPromotionTypesQueryVariables
>
export const GetProductSkuListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getProductSkuList'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'getProductSkuList'}}
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProductSkuListQuery,
  GetProductSkuListQueryVariables
>
export const GetPromotionSchemaTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getPromotionSchemaTypes'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPromotionSchemaTypes'}
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPromotionSchemaTypesQuery,
  GetPromotionSchemaTypesQueryVariables
>
export const GetAllPromotionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAllPromotions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllPromotions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'businessId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'active'}},
                {kind: 'Field', name: {kind: 'Name', value: 'canBeCombined'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'endDate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'startDate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionValue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'priority'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionSchema'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'subscriptionType'}
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'rules'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ruleValue'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetAllPromotionsQuery,
  GetAllPromotionsQueryVariables
>
export const GetTotalsForPromotionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetTotalsForPromotions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'quantity'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'promotionNames'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getTotalsForPromotions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sku'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'quantity'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'quantity'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'promotionNames'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'promotionNames'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'totalAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'promotionAmount'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetTotalsForPromotionsQuery,
  GetTotalsForPromotionsQueryVariables
>
export const GetInventoryBySkuAndDiscountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getInventoryBySkuAndDiscount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getInventoryBySkuAndDiscount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sku'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sku'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitDiscount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitTaxAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unitSubTotal'}},
                {kind: 'Field', name: {kind: 'Name', value: 'aisleLocation'}},
                {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetInventoryBySkuAndDiscountQuery,
  GetInventoryBySkuAndDiscountQueryVariables
>
export const CartGuestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'cartGuest'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cartGuest'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'quantity'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'unitPrice'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'description'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'logoUrl'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitSubTotal'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitDiscount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalTaxAmount'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalDiscount'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'total'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalDiscount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subTotal'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalTax'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CartGuestQuery, CartGuestQueryVariables>
export const GetAllRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getAllRoles'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllRoles'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
                {kind: 'Field', name: {kind: 'Name', value: 'permissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}}
              ]
            }
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAllPermissions'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'displayName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'category'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAllRolesQuery, GetAllRolesQueryVariables>
export const ListSecretsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'listSecrets'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'listSecrets'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'secretName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'secretValue'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ListSecretsQuery, ListSecretsQueryVariables>
export const FetchServiceConfigByTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'FetchServiceConfigByType'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'serviceConfigType'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'serviceConfig'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'serviceConfigType'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'serviceConfigType'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'configs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchServiceConfigByTypeQuery,
  FetchServiceConfigByTypeQueryVariables
>
export const FetchServiceConfigProvidersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'FetchServiceConfigProviders'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'category'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'serviceConfigProviders'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'category'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'category'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'provider'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'metadata'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'shopifyMetadata'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'webhookTopicsToSubscribe'
                              }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'availableApiVersions'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchServiceConfigProvidersQuery,
  FetchServiceConfigProvidersQueryVariables
>
export const FetchServiceConfigsByCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'FetchServiceConfigsByCategory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'category'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'serviceConfigsByCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'category'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'category'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'configs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FetchServiceConfigsByCategoryQuery,
  FetchServiceConfigsByCategoryQueryVariables
>
export const StoreGroupClusterStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'StoreGroupClusterStats'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'storeGroupClusterStats'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupClusterName'}
                },
                {kind: 'Field', name: {kind: 'Name', value: 'activeCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'draftCount'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StoreGroupClusterStatsQuery,
  StoreGroupClusterStatsQueryVariables
>
export const StoreGroupsByClusterIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'StoreGroupsByClusterId'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupClusterId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'fetchOrphanedStoreGroups'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'storeGroupsByClusterId'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroupClusterId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroupClusterId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fetchOrphanedStoreGroups'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'fetchOrphanedStoreGroups'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupCluster'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StoreGroupsByClusterIdQuery,
  StoreGroupsByClusterIdQueryVariables
>
export const ChildStoreGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ChildStoreGroups'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'storeGroupId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'childStoreGroups'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'storeGroupId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'storeGroupId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupCluster'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChildStoreGroupsQuery,
  ChildStoreGroupsQueryVariables
>
export const StoreGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'StoreGroups'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'storeGroups'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'storeGroupCluster'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'hierarchyLevel'}
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'parentStoreGroup'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'storeGroupCluster'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'hierarchyLevel'}
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<StoreGroupsQuery, StoreGroupsQueryVariables>
export const CashTransactionFailuresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'cashTransactionFailures'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dateRange'}
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'pageIndex'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageSize'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cashTransactionFailures'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dateRange'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageIndex'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageIndex'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSize'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'pageSize'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'nodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'error'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'eventId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'eventType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'payload'}}
                    ]
                  }
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPageCount'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CashTransactionFailuresQuery,
  CashTransactionFailuresQueryVariables
>
export const CashEventSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'cashEventSubscription'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'webCashEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CashEventSubscriptionSubscription,
  CashEventSubscriptionSubscriptionVariables
>
export const TransactionEventSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'transactionEventSubscription'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'webCashEvent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'businessId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'businessId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashEvent'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  TransactionEventSubscriptionSubscription,
  TransactionEventSubscriptionSubscriptionVariables
>
export const CustomerCartSubscriptionEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'customerCartSubscriptionEvents'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerCartSubscriptionEvents'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'flow'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cart'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'subscriptionItems'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'frequency'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'location'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'business'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CustomerCartSubscriptionEventsSubscription,
  CustomerCartSubscriptionEventsSubscriptionVariables
>
export const GenieQueryEventConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'genieQueryEventConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'lastReadTimestamp'}
          },
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'genieQueryEventConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'locationId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'locationId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lastReadTimestamp'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'lastReadTimestamp'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'followups'}}
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenieQueryEventConsumerAgentSubscription,
  GenieQueryEventConsumerAgentSubscriptionVariables
>
export const GenieQueryEventDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'GenieQueryEventDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'chatSessionId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'genieQueryEventDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'chatSessionId'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'chatSessionId'}
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'answer'}},
                {kind: 'Field', name: {kind: 'Name', value: 'chatSessionId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'messageId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'query'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'queryType'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'genieAnalyticsQuery'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'data'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'html'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GenieQueryEventDashboardSubscription,
  GenieQueryEventDashboardSubscriptionVariables
>
export const OrderUpdateEventsConsumerAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'orderUpdateEventsConsumerAgent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'businessId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'locationId'}
          },
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'orderUpdateEventsConsumerAgent'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cartId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cartId'}}
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'requestContext'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'businessId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'businessId'}
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'locationId'},
                      value: {
                        kind: 'Variable',
                        name: {kind: 'Name', value: 'locationId'}
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'order'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentStatus'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'refundStatus'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalAmount'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'amountPaid'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'amountRefunded'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  OrderUpdateEventsConsumerAgentSubscription,
  OrderUpdateEventsConsumerAgentSubscriptionVariables
>
export const OrderUpdateEventsDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {kind: 'Name', value: 'orderUpdateEventsDashboard'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'orderUpdateEventsDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'order'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'orderNumber'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'amountPaid'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentStatus'}
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'totalAmount'}
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'items'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'totalDiscount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unit'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'subscriptionItems'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'sku'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'description'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'quantity'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'taxAmount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'totalDiscount'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitPrice'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unit'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'frequency'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'location'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'street'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'city'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'state'}
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'storeGroups'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'id'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'name'}
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'storeGroupCluster'
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'id'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'name'}
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hierarchyLevel'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customer'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'firstName'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'lastName'}
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'employee'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'name'}
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'employeeCode'}
                            }
                          ]
                        }
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'register'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'orderType'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fulfillmentStatus'}
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  OrderUpdateEventsDashboardSubscription,
  OrderUpdateEventsDashboardSubscriptionVariables
>
