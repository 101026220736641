import {createTheme} from '@mui/material/styles'

export const space = {
  XXXXS: '1px',
  XXXS: '2px',
  XXS: '4px',
  XS: '8px',
  SMALL: '12px',
  MEDIUM: '16px',
  LARGE: '24px',
  L: '32px',
  XL: '64px',
  XXL: '128px'
}

export const customPadding = {
  SMALL: '12px var(--none, 0px)',
  LARGE: '24px var(--none, 0px)'
}

export const fontSize = {
  XS: '10px',
  SMALL: '12px',
  MEDIUM: '14px',
  LARGE: '16px',
  XL: '18px',
  XXL: '20px'
}

const rootElement = document.getElementById('root');

/*
* Explore default MUI theme:
* https://mui.com/material-ui/customization/default-theme/
* */
const theme = createTheme({
  spacing: 4,

  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none'
    },
    h1: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '140%',
      fontStyle: 'normal'
    },
    h2: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '140%',
      fontStyle: 'normal'
    },
    h3: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '140%',
      fontStyle: 'normal'
    },
    h4: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '140%',
      fontStyle: 'normal'
    },
    h5: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '140%',
      fontStyle: 'normal'
    },
    // No dedicated h6 version as it's used by subtitles
    subtitle1: {
      fontWeight: 400,
      fontSize: '16px',
      fontStyle: 'normal'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px',
      fontStyle: 'normal'
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      fontStyle: 'normal'
    },
    body2: {
      fontWeight: 400,
      fontSize: '12px',
      fontStyle: 'normal'
    }
  },
  palette: {
    primary: {
      main: '#0080F9',
      light: '#E8EEFF',
      dark: '#1E293B'
    },
    background: {
      default: '#FFFFFF',
      paper: '#F8F8F8'
    },
    text: {
      primary: '#000000',
      secondary: '#656565'
    },
    warning: {
      light: '#FFFDCD',
      main: '#FFE500',
      dark: '#5F5B00'
    },
    success: {
      light: '#E3FFD6',
      main: '#42D400',
      dark: '#1A5000'
    },
    error: {
      light: '#FFE5E5',
      main: '#ED2626',
      dark: '#490000'
    },
    divider: '#b9b9b9',
    info: {
      light: '#E8EEFF',
      main: '#0080F9',
      dark: '#1E293B'
    }
  },

  // https://mui.com/material-ui/integrations/interoperability/#tailwind-css
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
})

export const colors = {
  primary: {
    10: '#E8EEFF',
    20: '#DBE5FE',
    30: '#C1D2FE',
    40: '#8FAEFC',
    50: '#648EF9'
  },
  consumerAgentColor: '#8b0000',
  lightGreen: '#e9ffdf',
  officeDepotRed: '#CF0001',
  grayBorder: '#b9b9b9',
  loginInterfaceColor: '#AAD4E7',
  lightGray: '#EFEFEF',
  cardBorder: '#C9D4F4',
  cardLightBlue: '#F6F8FF',
  grayFont: '#8C8C8C',
  grey300: '#E0E0E0'
}

export default theme
