import {useMutation, useQuery} from '@apollo/client'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {Button, Grid, Link, styled, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import Loading from 'ui/molecules/Loading'
import StyledChip from 'ui/atoms/StyledChip'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'

import {DepositStatus} from 'constants/constants'
import {
  DOWNLOAD_FILE_MUTATION,
  UPDATE_CASH_DEPOSIT
} from 'graphql/mutations/cash-management.mutations'
import {GET_BANK_DEPOSITS} from 'graphql/queries/cash-management.queries'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import {MRT_ColumnDef} from 'material-react-table'
import moment from 'moment'
import React, {ChangeEvent, useEffect, useMemo} from 'react'
import {BankDepositType} from 'types/bankDepositHistory.types'
import {
  base64toBlob,
  centsToDollar,
  formatCurrency,
  localDateTimeString,
  titleize
} from 'utils/common'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const BankDepositHistory = () => {
  const {authInfo} = useAuth()
  const [openModel, setModelOpen] = React.useState(false)
  const notify = useNotify()

  const [selectedDepositId, setSelectedDepositId] = React.useState<any>(null)
  const [selectedDepositNumber, setSelectedDepositNumber] =
    React.useState<any>(null)
  const [selectedDepositReceipt, setSelectedDepositReceipt] =
    React.useState<File | null>(null)

  const [dateRange, setDateRange] = React.useState([
    moment().local().startOf('day'),
    moment().local().endOf('day')
  ])

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })

  const [columnFilters, setColumnFilters] = React.useState([])

  useEffect(() => {
    refetchBankDeposit()
  }, [dateRange, pagination, openModel])

  const {
    loading: loadingBankDeposit,
    error: bankDepositError,
    data: bankDepositData,
    refetch: refetchBankDeposit
  } = useQuery(GET_BANK_DEPOSITS, {
    variables: {
      dateRange: dateRange,
      locationId: authInfo.locationId as string,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  })

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      setSelectedDepositReceipt(file)
    }
  }

  const [updateBankDeposit] = useMutation(UPDATE_CASH_DEPOSIT)

  const handleUpload = async () => {
    try {
      updateBankDeposit({
        variables: {
          location: authInfo.locationId,
          depositNumber: selectedDepositNumber,
          depositReceipt: selectedDepositReceipt,
          id: selectedDepositId
        },
        onCompleted: (data) => {
          notify.show(t('cash-management.bank-deposit.success'), 'success')
          resetForm()
        },
        onError: (error) => {
          notify.show(
            (error as any)?.message || t('something-went-wrong'),
            'error'
          )
        }
      })
    } catch (error) {
      notify.show((error as any)?.message || t('something-went-wrong'), 'error')
    }
  }

  const [downloadFile] = useMutation(DOWNLOAD_FILE_MUTATION)

  const handleDownload = async (row: any) => {
    try {
      const response = await downloadFile({
        variables: {id: row.id, locationId: authInfo.locationId}
      })
      const file = response.data.downloadDepositReceipt
      if (file) {
        const blob = base64toBlob(file.fileContent)
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = file.fileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      } else {
        console.error('File download failed')
      }
    } catch (error) {
      console.error('Download failed:', error)
    }
  }

  const markComplete = (row: any) => {
    setModelOpen(true)
    setSelectedDepositId(row.id)
  }

  const resetForm = () => {
    setSelectedDepositReceipt(null)
    setSelectedDepositNumber(null)
    setSelectedDepositId(null)
    setModelOpen(false)
  }

  useEffect(() => {
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'updatedAt') {
        let startDate = moment(filter?.value[0]).startOf('day')
        let endDate = moment(filter?.value[1]).endOf('day')
        if (!endDate.isValid()) {
          endDate = dateRange[1]
        }
        if (!startDate.isValid()) {
          startDate = dateRange[0]
        }
        setDateRange([startDate, endDate])
      }
    })
  }, [columnFilters, openModel])

  const columns = useMemo<MRT_ColumnDef<BankDepositType>[]>(
    () => [
      {
        accessorKey: 'updatedAt',
        header: 'Date & Time',
        enableColumnFilter: true,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
            </Typography>
          )
        },
        muiFilterTextFieldProps: {
          defaultValue: moment(dateRange[1]),
          sx: {
            minWidth: '150px'
          }
        },
        filterVariant: 'date-range'
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          if (cell.getValue() === DepositStatus.PENDING) {
            return (
              <StyledChip
                label='Mark as Complete'
                color='error'
                onClick={() => markComplete(row.original)}
              />
            )
          } else {
            return (
              <StyledChip label={titleize(cell.getValue())} color='success' />
            )
          }
        }
      },
      {
        accessorKey: 'employee.name',
        header: 'Employee',
        enableColumnFilter: false,

        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableColumnFilter: false,
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(centsToDollar(Math.abs(cell.getValue())))}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'depositNumber',
        header: 'Deposit #',
        enableColumnFilter: false,
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? cell.getValue() : '-'}
            </Typography>
          )
        }
      },
      {
        accessorKey: 'depositReceipt',
        header: 'Deposit Slip',
        enableColumnFilter: false,
        Cell: ({cell, row}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? (
                <>
                  <Link
                    onClick={() => {
                      handleDownload(row.original)
                    }}
                  >
                    Download
                  </Link>
                </>
              ) : (
                '-'
              )}
            </Typography>
          )
        }
      }
    ],
    [dateRange]
  )

  if (loadingBankDeposit) {
    return <Loading />
  }

  if (bankDepositError) {
    return <p>Error fetching data</p>
  }

  const depositForm = () => {
    return (
      <>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant='subtitle2'>Deposit #</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              fullWidth
              required
              value={selectedDepositNumber}
              onChange={(event) =>
                setSelectedDepositNumber(event?.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant='subtitle2'>Deposit Slip </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component='label'
              variant='contained'
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type='file' onChange={handleFileChange} />
            </Button>
            <Typography variant='subtitle2' style={{padding: '6px'}}>
              Upload JPG, PNG, or PDF files up to 5MB in size.
            </Typography>
          </Grid>

          {selectedDepositReceipt && (
            <Grid item xs={12} sm={12}>
              {
                <Typography variant='subtitle2'>
                  Selected file : {selectedDepositReceipt.name}
                </Typography>
              }
            </Grid>
          )}
        </Grid>
      </>
    )
  }

  const updateBankDepositForm = () => {
    return (
      <StyledDialog
        open={true}
        title='Confirm Bank Deposit'
        body={<Grid>{depositForm()}</Grid>}
        successButtonName='Confirm'
        cancelButtonName='Dismiss'
        cancelCallback={() => {
          resetForm()
        }}
        onClose={() => {}}
        disableSuccessButton={!selectedDepositNumber}
        successCallback={(e: any) => {
          handleUpload()
        }}
      />
    )
  }

  return (
    <>
      <Typography variant='h2'>Bank Deposit History</Typography>

      <StyledTable
        columns={columns}
        data={bankDepositData?.getBankDeposits?.nodes || []}
        manualPagination={true}
        rowCount={bankDepositData?.getBankDeposits?.totalPageCount || 0}
        enableTableFooter={true}
        manualFiltering={true}
        enableSorting={false}
        enableStickyHeader={true}
        initialState={{
          showColumnFilters: true
        }}
        onPaginationChange={setPagination}
        onColumnFiltersChange={setColumnFilters as any}
        state={{
          columnFilters,
          pagination,
          showSkeletons: loadingBankDeposit
        }}
      />

      {openModel && updateBankDepositForm()}
    </>
  )
}

export default BankDepositHistory
