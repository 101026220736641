import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import {useMemo} from 'react'
interface InsightsLocationPickerProps {
  label: string
  store: {
    name: string
    id: string
    storeNumber: string
  } | null
  setStore: (store: any) => void
}

const InsightsLocationPicker: React.FC<InsightsLocationPickerProps> = ({
  label,
  store,
  setStore
}) => {
  const {authInfo} = useAuth()
  const allStores = useMemo(
    () =>
      authInfo?.storeNumbersMap
        ? Object.keys(authInfo?.storeNumbersMap as object).map(
            (storeNumber) => {
              const store = authInfo?.storeNumbersMap?.[storeNumber]
              return {
                name: store?.name,
                id: store?.id,
                storeNumber: storeNumber
              }
            }
          )
        : [],
    [authInfo?.storeNumbersMap]
  )
  return (
    <FormControl
      fullWidth
      style={{
        backgroundColor: '#FFFFFF'
      }}
    >
      <InputLabel id='location_id_1'>{label}</InputLabel>

      <Select
        size='medium'
        label={'Location'}
        labelId={'location_id_1'}
        value={store?.storeNumber || ''}
        onChange={(e: SelectChangeEvent<string>) => {
          setStore(
            authInfo?.storeNumbersMap?.[e.target.value] as {
              name: string
              id: string
              storeNumber: string
            }
          )
        }}
      >
        {(allStores || []).map(
          ({
            name,
            id,
            storeNumber
          }: {
            name: string
            id: string
            storeNumber: string
          }) => (
            <MenuItem key={id} value={storeNumber}>
              {name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}

export default InsightsLocationPicker
