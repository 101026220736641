import {graphql} from 'graphql/generatedTypes'

export const FETCH_ORDERS = graphql(`
  query listOrders(
    $businessId: String!
    $locationId: String!
    $customerId: String
    $pageIndex: Int
    $pageSize: Int
    $storeGroups: [String!]
    $locationIds: [String!]
  ) {
    listOrders(
      businessId: $businessId
      locationId: $locationId
      customerId: $customerId
      pageIndex: $pageIndex
      pageSize: $pageSize
      storeGroups: $storeGroups
      locationIds: $locationIds
    ) {
      totalPageCount
      orders {
        id
        orderNumber
        amountPaid
        createdAt
        paymentStatus
        totalAmount
        currency
        items {
          sku
          description
          quantity
          taxAmount
          totalDiscount
          unitPrice
          unit
          total
        }
        subscriptionItems {
          sku
          description
          quantity
          taxAmount
          totalDiscount
          unitPrice
          unit
          total
          frequency
          status
        }
        location {
          id
          name
          address {
            street
            city
            state
          }
          storeGroups {
            id
            name
            storeGroupCluster {
              id
              name
              hierarchyLevel
            }
          }
        }
        customer {
          firstName
          lastName
        }
        employee {
          id
          name
          employeeCode
        }
        register
        orderType
        fulfillmentStatus
      }
    }
  }
`)
