import 'react-notifications-component/dist/theme.css'
import {useEffect} from 'react'

import {LocalStorageKeys} from 'constants/constants'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import useEmployee from 'hooks/useEmployee'
import LocationNotFound from 'clientDashboard/LocationNotFound'
import Loading from 'ui/molecules/Loading'
import NotFound from 'ui/organisms/NotFound'
import {getFromLocalStorage} from 'libs/localStorage.helpers'

type ExternalRedirectProps = {}

const ExternalRedirect = ({}: ExternalRedirectProps) => {
  const toteAccessToken = getFromLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN
  )
  const {getEmployee, errorInGetEmployee, loadingEmployee} = useEmployee()
  const selectedBusinessId = useAppSelector(selectBusinessId)

  // Fetch the employee info, if the user is authenticated and the locationId is not set
  useEffect(() => {
    if (toteAccessToken) {
      getEmployee()
    }
  }, [toteAccessToken])

  useEffect(() => {
    if (selectedBusinessId) {
      const redirectUrl = new URLSearchParams(window.location.search).get(
        'redirectUrl'
      )
      window.location.href = `${window.location.origin}/${selectedBusinessId}${redirectUrl}`
    }
  }, [selectedBusinessId])

  if (!toteAccessToken) {
    return <NotFound />
  }

  if (errorInGetEmployee) {
    return <LocationNotFound />
  }
  return <Loading />
}

export default ExternalRedirect
