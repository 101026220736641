import {Grid} from '@mui/material'
import {useEffect} from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import {PlainObject} from '@mongodb-js/charts-embed-dom/dist/declarations/src/types'

// insight chart interface
interface AtlasDashboardProps {
  data: {
    dashboardId: string
    dashboardBaseUrl: string
    dashboardTitle: string
    dashboardSubtitle: string
    dashboardHeight: string
    dashboardWidth: string
    category: string
    type: string
    charts: {
      chartId: string
      filter: PlainObject
    }[]
  }
  sdk: ChartsEmbedSDK
}

const AtlasDashboard = ({data, sdk}: AtlasDashboardProps) => {
  async function renderDashboard() {
    const dashboard = sdk.createDashboard({
      dashboardId: data.dashboardId,
      showAttribution: false,
      charts: data.charts,
      autoRefresh: true,
      maxDataAge: 1,
      width: data.dashboardWidth,
      height: data.dashboardHeight,
      widthMode: 'scale',
      heightMode: 'scale',
      background: '#F8F8F8'
    })

    // render the chart into a container
    await dashboard.render(document.getElementById(data.dashboardId)!)
  }
  useEffect(() => {
    renderDashboard()
  }, [data])

  return (
    <Grid
      item
      id={data.dashboardId}
      style={{
        width: data.dashboardWidth,
        height: data.dashboardHeight
      }}
    ></Grid>
  )
}

export default AtlasDashboard
