import {useLazyQuery, useMutation} from '@apollo/client'
import {Grid, Typography, Box, FormControlLabel} from '@mui/material'
import {LocalStorageKeys} from 'constants/constants'
import {
  SIGNIN_CUSTOMER,
  SIGNUP_CUSTOMER,
  VALIDATE_CUSTOMER_SIGNUP_TOKEN
} from 'graphql/mutations/customer.mutation'
import {FETCH_CUSTOMER} from 'graphql/queries/customers.queries'
import useLocalStorage from 'hooks/useLocalStorage'
import useNotify from 'hooks/useNotify'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import StyledButton from 'ui/atoms/StyledButton'
import StyledCheckBox from 'ui/atoms/StyledCheckbox'
import StyledInput from 'ui/atoms/StyledInput'
import Loading from 'ui/molecules/Loading'
import StyledDialog from 'ui/molecules/StyledDialog'
import {isValidEmail} from 'utils/common'

interface CustomerFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

interface Business {
  id: string
  name: string
}

const defaultFormValues: CustomerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
}

const CustomerLoyaltySignup = () => {
  const {t} = useTranslation()
  const notify = useNotify()

  const history = useHistory()
  const [token, setToken] = useState<string>('')
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false)
  const [business, setBusiness] = useState<Business | null>(null)
  const [isSignUpFlow, setIsSignUpFlow] = useState(true)

  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  const [flow, setFlow] = useState<string>('')
  const [formValues, setFormValues] =
    useState<CustomerFormValues>(defaultFormValues)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [customerData, setCustomerData] = useState<any>(null)
  const [showExistingCustomer, setShowExistingCustomer] = useState(false)

  const [
    sessionTokenInStore,
    setSessionTokenInStore,
    clearSessionTokenInStore
  ] = useLocalStorage(
    LocalStorageKeys.CONSUMER_AGENT.CUSTOMER_SESSION_TOKEN,
    ''
  )

  const [fetchCustomer] = useLazyQuery(FETCH_CUSTOMER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setCustomerData(data.fetchCustomer)
        setShowExistingCustomer(true)
      }
    },
    onError: (error) => {
      console.error('Error fetching customer', error.message)
      notify.show(error.message, 'error')
      clearSessionTokenInStore()
    }
  })

  useEffect(() => {
    if (sessionTokenInStore) {
      fetchCustomer()
    }
  }, [])

  const toggleSignInSignUp = () => {
    setFormValues(defaultFormValues)
    setIsSignUpFlow(!isSignUpFlow)
  }

  const handleCustomerAddError = (error: Error) => {
    console.error('Error adding customer', error.message)
    notify.show(error.message, 'error')
  }

  const redirectToSubscription = () => {
    if (flow !== 'subscription') return
    setTimeout(() => {
      history.push('/customer/subscriptions?token=' + token)
    }, 2000)
  }

  const redirectToDelivery = () => {
    if (flow !== 'delivery') return
    setTimeout(() => history.push('/customer/delivery?token=' + token), 2000)
  }

  const [addCustomerMutation, {error: addCustomerError}] = useMutation(
    SIGNUP_CUSTOMER,
    {
      onCompleted: (data) => {
        const sessionToken = data.signupCustomer.token
        setSessionTokenInStore(sessionToken)
        setCustomerData(data.signupCustomer.customer)

        notify.show(
          t('customer-management.loyalty-signup.customer-added-successfully'),
          'success'
        )
        setIsLoggedIn(true)
        redirectToSubscription()
        redirectToDelivery()
      },
      onError: handleCustomerAddError
    }
  )

  const [customerLoginMutation] = useMutation(SIGNIN_CUSTOMER, {
    onCompleted: (data) => {
      const sessionToken = data.customerSignin.token
      setSessionTokenInStore(sessionToken)
      setCustomerData(data.customerSignin.customer)

      notify.show('Logged in successfully', 'success')
      setIsLoggedIn(true)
      redirectToSubscription()
      redirectToDelivery()
    },
    onError: (error) => {
      notify.show('Invalid login credentials. Please try again.', 'error')
    }
  })

  const loginCustomer = () => {
    if (!isTokenValid || !business) return
    customerLoginMutation({
      variables: {
        email: formValues.email,
        password: formValues.password,
        token
      }
    })
  }

  const addCustomer = () => {
    if (!isTokenValid || !business) return
    addCustomerMutation({
      variables: {
        token,
        customer: {
          business: business.id,
          ...formValues,
          signupToken: token
        }
      }
    })
  }

  const handleTokenValidationError = (error: Error) => {
    notify.show(error.message, 'error')
    setIsTokenValid(false)
  }

  const [validateTokenMutation, {loading: validatingToken, data}] = useMutation(
    VALIDATE_CUSTOMER_SIGNUP_TOKEN,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setBusiness(
          data.validateCustomerSignupToken.cart?.location.business as Business
        )
        setFlow(data.validateCustomerSignupToken.flow)
        setIsTokenValid(true)
      },
      onError: handleTokenValidationError
    }
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenParam = urlParams.get('token') || ''
    setToken(tokenParam)
    validateTokenMutation({variables: {token: tokenParam}})
  }, [])

  const handleChange = (key: keyof CustomerFormValues, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value
    }))
  }

  if (!isTokenValid) {
    if (validatingToken) return <Loading />
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Typography variant='h2'>
          {t('customer-management.loyalty-signup.invalid-token')}
        </Typography>
      </Grid>
    )
  }

  const renderSignUpForm = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledInput
              fullWidth
              disabled={isLoggedIn}
              label={t('customer-management.loyalty-signup.form.first-name')}
              variant='outlined'
              value={formValues.firstName}
              onChange={(e: any) => handleChange('firstName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              fullWidth
              disabled={isLoggedIn}
              label={t('customer-management.loyalty-signup.form.last-name')}
              variant='outlined'
              value={formValues.lastName}
              onChange={(e: any) => handleChange('lastName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              required
              disabled={isLoggedIn}
              fullWidth
              label={t('customer-management.loyalty-signup.form.email')}
              variant='outlined'
              value={formValues.email}
              onChange={(e: any) => handleChange('email', e.target.value)}
              error={!!formValues.email && !isValidEmail(formValues.email)}
              helperText={
                formValues.email && !isValidEmail(formValues.email)
                  ? 'Please enter a valid email address'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              type='password'
              fullWidth
              disabled={isLoggedIn}
              label={t('password')}
              variant='outlined'
              value={formValues.password}
              onChange={(e: any) => handleChange('password', e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{marginTop: '20px'}}>
          <FormControlLabel
            control={
              <StyledCheckBox
                disabled={isLoggedIn}
                checked={isTermsAccepted}
                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
              />
            }
            label='Yes, I agree to the Terms of Service.'
          />
        </Grid>

        <Grid item xs={12} sx={{minHeight: '24px'}}>
          {addCustomerError && (
            <Typography variant='subtitle2' color='error' align='center'>
              {addCustomerError.message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sx={{marginTop: '20px'}}>
          {!isLoggedIn && (
            <StyledButton
              onClick={addCustomer}
              disabled={
                !isValidEmail(formValues.email) ||
                !isTokenValid ||
                !isTermsAccepted
              }
              fullWidth
            >
              {t('customer-management.loyalty-signup.form.button.signup')}
            </StyledButton>
          )}
        </Grid>

        <Grid item xs={12} sx={{marginTop: '20px'}}>
          {isLoggedIn && (
            <Typography variant='h6' color='success' align='center'>
              Customer added successfully, Please check with associate...
            </Typography>
          )}
        </Grid>
      </>
    )
  }

  const renderSignInForm = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledInput
              fullWidth
              required
              disabled={isLoggedIn}
              label={t('customer-management.loyalty-signup.form.email')}
              variant='outlined'
              value={formValues.email}
              onChange={(e: any) => handleChange('email', e.target.value)}
              error={!!formValues.email && !isValidEmail(formValues.email)}
              helperText={
                formValues.email && !isValidEmail(formValues.email)
                  ? 'Please enter a valid email address'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              type='password'
              required
              fullWidth
              disabled={isLoggedIn}
              label={t('password')}
              variant='outlined'
              value={formValues.password}
              onChange={(e: any) => handleChange('password', e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{marginTop: '20px', minHeight: '24px'}}>
          {addCustomerError && (
            <Typography variant='subtitle2' color='error' align='center'>
              {addCustomerError.message}
            </Typography>
          )}

          {isLoggedIn && (
            <Typography variant='h6' color='success' align='center'>
              Logged in successfully, Please check with associate...
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sx={{marginTop: '20px'}}>
          {!isLoggedIn && (
            <StyledButton
              onClick={loginCustomer}
              disabled={
                !isValidEmail(formValues.email) ||
                !isTokenValid ||
                !formValues.password
              }
              fullWidth
            >
              {t('customer-management.loyalty-signup.form.button.signin')}
            </StyledButton>
          )}
        </Grid>
      </>
    )
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '10px',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)'
      }}
    >
      <Box
        sx={{
          padding: {xs: '20px', sm: '40px'},
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          width: {xs: '300px', sm: '500px'}
        }}
      >
        <Grid item xs={12}>
          <Typography variant='h2' align='center' gutterBottom>
            {isSignUpFlow
              ? t('customer-management.loyalty-signup.customer-signup')
              : t('customer-management.loyalty-signup.customer-signin')}
          </Typography>

          <Typography variant='subtitle2' align='center' gutterBottom>
            {data?.validateCustomerSignupToken?.cart?.location?.name}
          </Typography>

          <Typography variant='body1' align='center' paragraph>
            Type:{' '}
            <Typography component='span' style={{textTransform: 'capitalize'}}>
              {data?.validateCustomerSignupToken?.flow} Flow
            </Typography>
          </Typography>
        </Grid>

        {isSignUpFlow ? renderSignUpForm() : renderSignInForm()}

        {flow !== 'loyalty' && (
          <Grid item xs={12} sx={{marginTop: '10px'}}>
            <Typography
              variant='body2'
              color={'primary'}
              onClick={toggleSignInSignUp}
              style={{cursor: 'pointer', textDecoration: 'underline'}}
            >
              {isSignUpFlow
                ? 'Sign in to existing account'
                : 'Create a new account'}
            </Typography>
          </Grid>
        )}
      </Box>

      <StyledDialog
        open={showExistingCustomer}
        title='Continue as Existing Customer'
        body={
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Grid item>Email: {customerData?.email}</Grid>
            <Grid item>
              Name: {customerData?.firstName} {customerData?.lastName}
            </Grid>
          </Grid>
        }
        successCallback={() => {
          setIsLoggedIn(true)
          setShowExistingCustomer(false)

          redirectToDelivery()
          redirectToSubscription()
        }}
        successButtonName='Yes'
        cancelButtonName='No'
        closeCallback={() => {
          setShowExistingCustomer(false)
        }}
        cancelCallback={() => {
          setShowExistingCustomer(false)
        }}
      ></StyledDialog>
    </Grid>
  )
}

export default CustomerLoyaltySignup
