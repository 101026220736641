import {graphql} from 'graphql/generatedTypes'

export const TRANSACTION_EVENT_SUBSCRIPTION = graphql(`
  subscription orderUpdateEventsDashboard {
    orderUpdateEventsDashboard {
      order {
        id
        orderNumber
        amountPaid
        createdAt
        paymentStatus
        totalAmount
        currency
        items {
          sku
          description
          quantity
          taxAmount
          totalDiscount
          unitPrice
          unit
          total
        }
        subscriptionItems {
          sku
          description
          quantity
          taxAmount
          totalDiscount
          unitPrice
          unit
          total
          frequency
          status
        }
        location {
          id
          name
          address {
            street
            city
            state
          }
          storeGroups {
            id
            name
            storeGroupCluster {
              id
              name
              hierarchyLevel
            }
          }
        }
        customer {
          firstName
          lastName
        }
        employee {
          id
          name
          employeeCode
        }
        register
        orderType
        fulfillmentStatus
      }
    }
  }
`)
