import {graphql} from 'graphql/generatedTypes'

export const VERIFY_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN = graphql(`
  mutation VerifyOktaTokenAndGenerateSessionToken(
    $businessId: String = null
    $accessToken: String!
    $idToken: String!
    $oktaClientId: String!
    $oktaIssuer: String!
  ) {
    verifyOktaTokenAndGenerateSessionToken(
      businessId: $businessId
      accessToken: $accessToken
      idToken: $idToken
      oktaClientId: $oktaClientId
      oktaIssuer: $oktaIssuer
    )
  }
`)

export const LOGIN_USER = graphql(`
  mutation loginUserForDashboard(
    $email: String!
    $password: String!
    $businessId: String!
  ) {
    loginUserForDashboard(
      email: $email
      password: $password
      businessId: $businessId
    ) {
      toteAccessToken
      businessId
      mfaEnabled
    }
  }
`)

export const LOGIN_GUEST_FOR_CONSUMER_AGENT = graphql(`
  mutation loginGuest($locationId: ID!) {
    loginGuest(locationId: $locationId) {
      success
      token
      businessConfigs {
        stripePublishableKey
      }
      location {
        id
        name
        business {
          id
          name
          configurations {
            colors {
              primary
              secondary
            }
          }
        }
      }
    }
  }
`)

export const VERIFY_OTP_AND_GENERATE_SESSION_TOKEN = graphql(`
  mutation verifyOtpAndGenerateSessionToken(
    $email: String!
    $otp: String!
    $businessId: String!
  ) {
    verifyOtpAndGenerateSessionToken(
      email: $email
      otp: $otp
      businessId: $businessId
    ) {
      toteAccessToken
      businessId
    }
  }
`)

export const RESEND_OTP = graphql(`
  mutation resendOtp($email: String!, $businessId: String!) {
    resendOtp(email: $email, businessId: $businessId)
  }
`)

export const VERIFY_ADMIN_OKTA_TOKEN_AND_GENERATE_SESSION_TOKEN = graphql(`
  mutation VerifyAdminOktaTokenAndGenerateSessionToken(
    $accessToken: String!
    $idToken: String!
    $oktaClientId: String!
    $oktaIssuer: String!
  ) {
    verifyAdminOktaTokenAndGenerateSessionToken(
      accessToken: $accessToken
      idToken: $idToken
      oktaClientId: $oktaClientId
      oktaIssuer: $oktaIssuer
    )
  }
`)
