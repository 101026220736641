import {Grid, Typography} from '@mui/material'
import BouncingDotsLoader from 'ui/atoms/BouncingLoader/BouncingLoader'
import ConsumerAgentHotkeys from 'consumerAgent/ConsumerAgentHotkeys'
import ConsumerAgentMessageCard, {
  ConsumerAgentMessageType
} from 'consumerAgent/ConsumerAgentMessageCard'
import ConsumerAgentItemCard from 'consumerAgent/ConsumerAgentItemCard'
import {space} from 'config/theme'
import {marked} from 'marked'
import {useEffect} from 'react'
import useConsumerAgent from 'consumerAgent/ConsumerAgentContext'

interface ConsumerAgentChatProps {
  index: number
  setText: (text: string) => void
  focusOnInput: () => void
}

const ConsumerAgentChat = ({
  index,
  setText,
  focusOnInput
}: ConsumerAgentChatProps) => {
  const {chatMessages, submitGenieQuery} = useConsumerAgent()

  const showLastMessageHotkeys = () => {
    if (!chatMessages.length || index !== chatMessages.length - 1) return ''
    const lastMessageResponse =
      chatMessages?.[chatMessages.length - 1]?.response
    if (!lastMessageResponse?.followups?.length) return ''

    return (
      <ConsumerAgentHotkeys
        isFollowUp
        setText={(text) => {
          setText(text)
          focusOnInput()
        }}
        hotkeys={lastMessageResponse.followups}
      />
    )
  }

  const showLoader = () => {
    if (chatMessages[index].hasFetched) return ''
    return (
      <Grid container paddingTop={space.SMALL}>
        <BouncingDotsLoader />
      </Grid>
    )
  }

  const showGenieResponse = () => {
    const firstProduct = chatMessages[index].response?.products?.[0]
    return (
      <>
        {firstProduct?.aisleLocation && (
          <Typography variant='subtitle1'>
            {firstProduct.description}: Aisle {firstProduct.aisleLocation}
          </Typography>
        )}

        {chatMessages[index].response?.answer && (
          <Grid>
            <Typography
              variant='subtitle2'
              fontSize={'16px'}
              dangerouslySetInnerHTML={{
                __html: marked(chatMessages[index].response?.answer || '')
              }}
            />
          </Grid>
        )}
      </>
    )
  }

  useEffect(() => {
    if (!chatMessages[index].isFetching && !chatMessages[index].hasFetched) {
      submitGenieQuery(chatMessages[index].query)
    }
  }, [])

  return (
    <Grid container>
      {chatMessages[index].query && !chatMessages[index].hideQuery && (
        <ConsumerAgentMessageCard type={ConsumerAgentMessageType.CONSUMER}>
          <Typography variant='subtitle1'>
            {chatMessages[index].query}
          </Typography>
        </ConsumerAgentMessageCard>
      )}

      <ConsumerAgentMessageCard type={ConsumerAgentMessageType.AGENT}>
        {showLoader()}
        {showGenieResponse()}
      </ConsumerAgentMessageCard>

      {chatMessages[index]?.response?.products?.length > 0 && (
        <ConsumerAgentMessageCard type={ConsumerAgentMessageType.AGENT}>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'visible',
              marginTop: 'auto'
            }}
          >
            {(chatMessages[index]?.response?.products || []).map(
              (item: any, key: any) => (
                <Grid item key={key} width={'100%'}>
                  <ConsumerAgentItemCard productData={item} />
                </Grid>
              )
            )}
          </Grid>
        </ConsumerAgentMessageCard>
      )}

      {showLastMessageHotkeys()}
    </Grid>
  )
}

export default ConsumerAgentChat
