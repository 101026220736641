import 'react-notifications-component/dist/theme.css'

import {ApolloProvider} from '@apollo/client'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {StyledEngineProvider} from '@mui/material/styles'
import {NotificationProvider} from 'app/NotificationContext'
import React from 'react'
import {ReactNotifications} from 'react-notifications-component'

import {AuthProvider} from 'auth/AuthContext'
import client from 'config/apollo.config'
import theme from 'config/theme'

type ProvidersProps = {
  children: React.ReactNode
}

const Providers = ({children}: ProvidersProps) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReactNotifications />
            <NotificationProvider>{children}</NotificationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default Providers
